import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { buildQueryString } from 'src/lib/utilities/api/http';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import { ApprovalRequestsTrackerResponseModel } from '../salesforce/approval-request.model';
import { ACTrackerItemModel } from './ac-tracker.model';
import {
	AdminTrackerModel,
	AdminTrackerNoteArgument,
} from './admin-tracker.model';
import {
	BackgroundCheckSearchArgument,
	BackgroundCheckTrackerResponseModel,
} from './background-check-tracker.model';
import {
	CareerTrackerModel,
	CareerTrackerUserModel,
} from './career-tracker.model';
import {
	CredentialedResponderTrackerArgument,
	CredentialedResponderTrackerResponseModel,
} from './credentialed-responder-tracker.model';
import { ECTrackerModel } from './ec-tracker.model';
import {
	GradCoordinatorSearchArgument,
	GradCoordinatorTrackerResponseModel,
} from './grad-coordinator-tracker.model';
import { GraderTrackerItemModel } from './grader-tracker.model';
import { LATrackerItemModel } from './la-tracker.model';
import {
	MissingDocumentTrackerResponseModel,
	MissingDocumentsTrackerSearchArgument,
} from './missing-document-tracker.model';
import { ReentryCoachTrackerItemModel } from './reentry-coach-tracker.model';
import { ReentryCoordinatorTrackerModel } from './reentry-coordinator-tracker.model';
import {
	RegistrarTrackerPagingArgument,
	RegistrarTrackerResponseModel,
} from './registrar-tracker.model';
import { StudentProgressTrackerModel } from './student-progress-tracker.model';
import { TeacherTrackerItemModel } from './teacher-tracker.model';
import {
	TestingCoordinatorPagingArgument,
	TestingCoordinatorTrackerResponseModel,
} from './testing-coordinator.model';
import { TrackerUserModel } from './tracker-user.model';
import {
	TranscriptTrackerPagingArgument,
	TranscriptTrackerResponseModel,
	TranscriptTrackerSearchArgument,
} from './transcript-tracker.model';

@Injectable({
	providedIn: 'root',
})
export class TrackersService {
	constructor(private httpClient: HttpClient) {}

	// AC Tracker
	public getACTracker = (
		uid?: number,
		include_deactivated?: boolean,
	): Observable<ACTrackerItemModel[]> => {
		const userUrl = uid != null ? `/user/${uid}` : '';
		const queryString = buildQueryString({
			include_deactivated: include_deactivated ? true : null,
		});
		return this.httpClient
			.get<ACTrackerItemModel[]>(`/api/v1/tracker/8${userUrl}${queryString}`)
			.pipe(
				mapResponse((r) => plainToInstance(ACTrackerItemModel, r), {
					errorCode: 'A943D639',
				}),
			);
	};

	public getACTrackerUsers = (): Observable<TrackerUserModel[]> => {
		return this.httpClient
			.get<TrackerUserModel[]>(`/api/v1/tracker/8/users?show_deactive=true`)
			.pipe(
				mapResponse((r) => plainToInstance(TrackerUserModel, r), {
					errorCode: 'F9063DA8',
				}),
			);
	};

	// Admin Tracker
	public getAdminTracker = (
		paging: PagingArgument,
		uid?: number,
		include_deactivated?: boolean,
	): Observable<AdminTrackerModel> => {
		const userUrl = uid != null ? `/user/${uid}` : '';
		const queryString = buildQueryString({
			include_deactivated: include_deactivated ? true : null,
		});

		return this.httpClient
			.post<unknown>(
				`/api/v1/tracker/program_admin${userUrl}${queryString}`,
				paging,
			)
			.pipe(
				mapResponse((r) => plainToInstance(AdminTrackerModel, r), {
					errorCode: 'DFBCF94B',
				}),
			);
	};

	public getAdminTrackerUsers = (): Observable<TrackerUserModel[]> => {
		return this.httpClient
			.get<
				TrackerUserModel[]
			>(`/api/v1/tracker/program_admin/users?show_deactive=true`)
			.pipe(
				mapResponse((r) => plainToInstance(TrackerUserModel, r), {
					errorCode: '6DBDC2CC',
				}),
			);
	};

	public setAdminTrackerNote = (
		args: AdminTrackerNoteArgument,
	): Observable<unknown[]> => {
		return this.httpClient
			.post<unknown[]>(`/api/v1/tracker/program_admin/notes`, [args])
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'C26A4DA8',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	// LA Tracker
	public getLATracker = (
		uid?: number,
		include_deactivated?: boolean,
	): Observable<LATrackerItemModel[]> => {
		const userUrl = uid != null ? `/user/${uid}` : '';
		const queryString = buildQueryString({
			include_deactivated: include_deactivated ? true : null,
		});

		return this.httpClient
			.get<LATrackerItemModel[]>(`/api/v1/tracker/7${userUrl}${queryString}`)
			.pipe(
				mapResponse((r) => plainToInstance(LATrackerItemModel, r), {
					errorCode: '0E423F02',
				}),
			);
	};

	public getLATrackerUsers = (): Observable<TrackerUserModel[]> => {
		return this.httpClient
			.get<TrackerUserModel[]>(`/api/v1/tracker/7/users?show_deactive=true`)
			.pipe(
				mapResponse((r) => plainToInstance(TrackerUserModel, r), {
					errorCode: 'C5605429',
				}),
			);
	};

	// Career Counselor Tracker
	public getCareerCounselorList = (): Observable<CareerTrackerUserModel[]> => {
		return this.httpClient
			.get<
				CareerTrackerUserModel[]
			>(`/api/v1/tracker/25/users?show_deactive=true`)
			.pipe(
				mapResponse((r) => plainToInstance(CareerTrackerUserModel, r), {
					errorCode: 'C154EC9A',
				}),
			);
	};

	public getCareerCounselorTracker = (
		uid?: number,
	): Observable<CareerTrackerModel[]> => {
		const userUrl = uid != null ? `/user/${uid}` : '';

		return this.httpClient
			.get<CareerTrackerModel[]>(`/api/v1/tracker/25${userUrl}`)
			.pipe(
				mapResponse((r) => plainToInstance(CareerTrackerModel, r), {
					errorCode: '0FAF9B9C',
				}),
			);
	};

	// Grader Tracker
	public getGraderTracker = (
		uid?: number,
	): Observable<GraderTrackerItemModel[]> => {
		const userUrl = uid != null ? `/${uid}` : '';

		return this.httpClient
			.get<{
				results: unknown[];
			}>(`/api/v1/tracker/grader${userUrl}`)
			.pipe(
				mapResponse((r) => plainToInstance(GraderTrackerItemModel, r.results), {
					errorCode: '469B4EBF',
				}),
			);
	};

	public getGraderList = (): Observable<TrackerUserModel[]> => {
		return this.httpClient
			.get<{
				results: unknown[];
			}>(`/api/v1/tracker/grader/list?show_deactive=true`)
			.pipe(
				mapResponse((r) => plainToInstance(TrackerUserModel, r.results), {
					errorCode: 'C052C992',
				}),
			);
	};

	// EC Tracker
	public getECTracker = (
		paging: PagingArgument,
		uid?: number,
		include_enrolled: boolean = null,
		include_deactivated: boolean = null,
	): Observable<ECTrackerModel> => {
		const query = buildQueryString({
			include_enrolled: include_enrolled || null,
			include_deactivated: include_deactivated || null,
		});
		const userUrl = uid != null ? `/user/${uid}` : '';

		return this.httpClient
			.post<unknown>(`/api/v1/tracker/15${userUrl}${query}`, paging)
			.pipe(
				mapResponse((r) => plainToInstance(ECTrackerModel, r), {
					errorCode: '51D398A0',
				}),
			);
	};

	public getECList = (): Observable<TrackerUserModel[]> => {
		return this.httpClient
			.get<unknown[]>(`/api/v1/tracker/15/users?show_deactive=true`)
			.pipe(
				mapResponse((r) => plainToInstance(TrackerUserModel, r), {
					errorCode: 'D207E58A',
				}),
			);
	};

	// Teacher Tracker
	public getTeacherTracker = (
		uid?: number,
	): Observable<TeacherTrackerItemModel[]> => {
		const userUrl = uid != null ? `/${uid}` : '';

		return this.httpClient
			.get<{
				results: unknown[];
			}>(`/api/v1/tracker/teacher${userUrl}`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(TeacherTrackerItemModel, r.results),
					{ errorCode: 'F17027DF' },
				),
			);
	};

	public setTeacherTrackerNote = (
		studentId: number,
		registrationId: number,
		note: string,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(
				`/api/v1/students/${studentId}/classes/${registrationId}/teacher_notes`,
				{ teacher_notes: note },
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'BF6A4B91',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	// Transcript Tracker
	public getTranscriptTracker = (
		args: TranscriptTrackerPagingArgument,
	): Observable<TranscriptTrackerResponseModel> => {
		return this.httpClient
			.post<TranscriptTrackerResponseModel>(`/api/v1/tracker/transcript`, args)
			.pipe(
				mapResponse((r) => plainToInstance(TranscriptTrackerResponseModel, r), {
					errorCode: '766F4A4A',
					checkPreMap: false,
					validateSuccess: false,
				}),
			);
	};

	public getFilteredTranscriptStudentIds = (
		args?: PagingArgument<TranscriptTrackerSearchArgument>,
	): Observable<number[]> => {
		return this.httpClient
			.post<number[]>(`/api/v1/tracker/transcript?idsOnly=true`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '7AC62749',
					checkPreMap: false,
					validateSuccess: false,
				}),
			);
	};

	// Reentry Coordinator Tracker
	public getReentryCoordinatorTracker = (
		uid: number,
	): Observable<ReentryCoordinatorTrackerModel[]> => {
		const userUrl = uid != null ? `/${uid}` : '';
		return this.httpClient
			.get<{
				results: unknown[];
			}>(`/api/v1/tracker/reentry_coordinator${userUrl}`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(ReentryCoordinatorTrackerModel, r.results),
					{
						errorCode: '766F4A4A',
					},
				),
			);
	};

	public getReentryCoordinatorTrackerUsers = (): Observable<
		TrackerUserModel[]
	> => {
		return this.httpClient
			.get<{
				reentry_coordinators: unknown[];
			}>(`/api/v1/tracker/reentry_coordinator/coordinator_list`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(TrackerUserModel, r.reentry_coordinators),
					{
						errorCode: '864330D5',
					},
				),
			);
	};

	// Reentry Coach Tracker
	public getReentryCoachTracker = (
		uid?: number,
	): Observable<ReentryCoachTrackerItemModel[]> => {
		const userUrl = uid != null ? `/${uid}` : '';
		const queryString = buildQueryString({});
		return this.httpClient
			.get<{
				results: unknown[];
			}>(`/api/v1/tracker/reentry_coach${userUrl}${queryString}`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(ReentryCoachTrackerItemModel, r.results),
					{
						errorCode: '57CB1D26',
					},
				),
			);
	};

	public getReentryCoachTrackerUsers = (): Observable<TrackerUserModel[]> => {
		return this.httpClient
			.get<{
				reentry_coaches: unknown[];
			}>(`/api/v1/tracker/reentry_coach/coach_list?show_deactive=true`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(TrackerUserModel, r.reentry_coaches),
					{
						errorCode: 'F94DE471',
					},
				),
			);
	};

	// Registrar Tracker
	public getRegistrarTracker = (
		args: RegistrarTrackerPagingArgument,
	): Observable<RegistrarTrackerResponseModel> => {
		return this.httpClient
			.post<unknown>(`/api/v1/tracker/registrar`, args)
			.pipe(
				mapResponse((r) => plainToInstance(RegistrarTrackerResponseModel, r), {
					errorCode: '99C47F45',
					checkPreMap: false,
					validateSuccess: false,
				}),
			);
	};

	public registrarReportToManager = (args: {
		uid: number;
		body: string;
	}): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(`/api/v1/tracker/registrar/manager_email`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '9D45062A',
				}),
			);
	};

	public getBackgroundCheckTracker = (
		args: PagingArgument<BackgroundCheckSearchArgument>,
	) => {
		return this.httpClient
			.post<unknown>(`/api/v1/tracker/staff_background_check`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(BackgroundCheckTrackerResponseModel, r),
					{
						errorCode: '6A505461',
					},
				),
			);
	};

	// Grad Coordinator Tracker
	public getGradCoordinatorTracker = (
		uid: number,
		args: PagingArgument<GradCoordinatorSearchArgument>,
	): Observable<GradCoordinatorTrackerResponseModel> => {
		const userUrl = uid != null ? `${uid}` : '';

		return this.httpClient
			.post<unknown>(`/api/v1/tracker/grad_coordinator/${userUrl}`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(GradCoordinatorTrackerResponseModel, r),
					{
						errorCode: '99C47F45',
						checkPreMap: false,
						validateSuccess: false,
					},
				),
			);
	};

	public getGradCoordinatorTrackerUsers = (): Observable<
		TrackerUserModel[]
	> => {
		return this.httpClient
			.get<{
				grad_coordinators: unknown[];
			}>(`/api/v1/tracker/grad_coordinator/coordinator_list`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(TrackerUserModel, r.grad_coordinators),
					{
						errorCode: '0EBEE0BF',
					},
				),
			);
	};

	// Credentialed Responder Tracker
	public getCredentialedResponderTracker = (
		uid: number,
		viewAll: boolean,
		args: PagingArgument<CredentialedResponderTrackerArgument>,
	): Observable<CredentialedResponderTrackerResponseModel> => {
		const value = viewAll ? '' : `${uid}`;

		return this.httpClient
			.post<unknown>(`/api/v1/tracker/credentialed_responder/${value}`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(CredentialedResponderTrackerResponseModel, r),
					{
						errorCode: '12435443',
						checkPreMap: false,
						validateSuccess: false,
					},
				),
			);
	};

	public getCredentialedResponderTrackerUsers = (): Observable<
		TrackerUserModel[]
	> => {
		return this.httpClient
			.get<{
				users: unknown[];
			}>(`/api/v1/tracker/credentialed_responder/users`)
			.pipe(
				mapResponse((r) => plainToInstance(TrackerUserModel, r.users), {
					errorCode: '906EA0E8',
				}),
			);
	};

	// Testing Coordinator Tracker
	public getTestingCoordinatorTracker = (
		uid?: number,
		args?: TestingCoordinatorPagingArgument,
	): Observable<TestingCoordinatorTrackerResponseModel> => {
		const qs = buildQueryString({
			validate_groups: true,
		});

		const userUrl = uid != null ? `/${uid}` : '';
		return this.httpClient
			.post<unknown>(`/api/v1/tracker/testing_coordinator${userUrl}${qs}`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(TestingCoordinatorTrackerResponseModel, r),
					{
						errorCode: 'D259A8AD',
					},
				),
			);
	};

	public getTestingCoordinatorTrackerUsers = (): Observable<
		TrackerUserModel[]
	> => {
		return this.httpClient
			.get<{
				users: unknown[];
			}>(`/api/v1/tracker/testing_coordinator/users`)
			.pipe(
				mapResponse((r) => plainToInstance(TrackerUserModel, r.users), {
					errorCode: 'E6D3C4d6',
				}),
			);
	};

	// Student Progress Tracker
	public getStudentProgressTracker = (
		paging: PagingArgument,
		uid?: number,
	): Observable<StudentProgressTrackerModel> => {
		return this.httpClient
			.post<unknown>(
				`/api/v1/program_admin/user/${uid}/student_progress`,
				paging,
			)
			.pipe(
				mapResponse((r) => plainToInstance(StudentProgressTrackerModel, r), {
					errorCode: '8AFEF09B',
				}),
			);
	};

	public getMissingDocumentsTracker = (
		args: PagingArgument<MissingDocumentsTrackerSearchArgument>,
	) => {
		return this.httpClient
			.post<MissingDocumentTrackerResponseModel>(
				`/api/v1/tracker/documents`,
				args,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(MissingDocumentTrackerResponseModel, r),
					{
						errorCode: 'A68D0326',
					},
				),
			);
	};

	public getEnrollmentRequestsTracker = (
		paging: PagingArgument,
		// pending_approval?: boolean,
	): Observable<ApprovalRequestsTrackerResponseModel> => {
		// const queryString = buildQueryString({
		// 	pending_approval: pending_approval ? true : null,
		// });
		return this.httpClient
			.post<ApprovalRequestsTrackerResponseModel>(
				`/api/v1/enrollment_approval/list`,
				paging,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(ApprovalRequestsTrackerResponseModel, r),
					{
						errorCode: 'A943D639',
					},
				),
			);
	};
}
