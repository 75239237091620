import { Type } from 'class-transformer';

export class SchoolCourseModel {
	course_id: number;
	course_code: string;
	course_title: string;
	primary_subject_code: string;
	primary_subject_title: string;
}

export function SchoolCourseModel_ClassTransform_Type() {
	return SchoolCourseModel;
}

export class SchoolSubjectModel {
	subject_id: number;
	subject_code: string;
	subject_title: string;
}

export function SchoolSubjectModel_ClassTransform_Type() {
	return SchoolSubjectModel;
}

export class SchoolCoursesSubjectsModel {
	@Type(SchoolCourseModel_ClassTransform_Type)
	courses: SchoolCourseModel[];
	@Type(SchoolSubjectModel_ClassTransform_Type)
	subject_areas: SchoolSubjectModel[];
}
