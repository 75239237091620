import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { CommunicationSenderModel } from '../communication-sender.model';
import { CommunicationArgument } from '../communication.argument';

@Injectable({
	providedIn: 'root',
})
export class CommunicationSmsService {
	constructor(private httpClient: HttpClient) {}

	public getSenders = (
		groups: string[],
	): Observable<CommunicationSenderModel[]> => {
		return this.httpClient
			.post<unknown[]>(`/api/v1/communication/senders/sms`, {
				groups: groups,
			})
			.pipe(
				mapResponse((r) => plainToInstance(CommunicationSenderModel, r), {
					errorCode: 'd5f112e6',
				}),
			);
	};

	public createSMS = (args: CommunicationArgument): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(`/api/v1/communication/sms/create`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '5c9d4740',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};
}
