import { Expose, Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import { UserAddressModel } from '../../utility/utility-models/address.model';
import { StudentContactAttributes } from '../students/contacts/student-contact-options.model';

export class UserDataProfileModel {
	profile_employee_id: string;
	profile_first_name: string;
	profile_last_name: string;
	profile_preferred_name: string;
	profile_maiden_name: string;
	profile_student_gender: string;
	@Expose({ name: 'profile_student_ethnicity' }) // API still refers to race as ethnicity 14E31FFD
	profile_student_race: string;
	profile_middle_name: string;
	profile_suffix: string;
	profile_city: string;
	profile_user_title: string;
	profile_about: string;
	profile_state: string;
	profile_intro_video: string;
}

export function UserDataProfileModel_ClassTransform_Type() {
	return UserDataProfileModel;
}

export class UserDataContactPhoneModel {
	value: string;
	type: string;
	prim: boolean;
	active: boolean;
	weight: number;
	sms: boolean;
}
export class UserDataContactAddressModel extends UserAddressModel {
	type: string;
	prim: boolean;
	active: boolean;
	weight: number;
}

export class UserDataContactEmailModel {
	value: string;
	type: string;
	prim: boolean;
	active: boolean;
	weight: number;
}

export class UserDataContactMessengerModel {
	value: string;
	type: string;
	prim: boolean;
	active: boolean;
	weight: number;
}

export class UserDataContactModel {
	phone?: UserDataContactPhoneModel[];
	physical_address?: UserDataContactAddressModel;
	mailing_address?: UserDataContactAddressModel;
	email?: UserDataContactEmailModel[];
	ssoEmail?: UserDataContactEmailModel[];
	messenger?: UserDataContactMessengerModel[];
}

export function UserDataContactModel_ClassTransform_Type() {
	return UserDataContactModel;
}

export class UserDataGAModel {
	programs: string[];
}

export function UserDataGAModel_ClassTransform_Type() {
	return UserDataGAModel;
}

export class UserDataStudentModel {
	@Transform(dateTransformer)
	dob: Date;
	program: string;
}

export function UserDataStudentModel_ClassTransform_Type() {
	return UserDataStudentModel;
}

export class UserDataPersonalContactPhoneModel {
	phone: number;
	is_primary: boolean;
	sms_blocked: boolean;
	type: string;
	sms: boolean;
}

export function UserDataPersonalContactPhoneModel_ClassTransform_Type() {
	return UserDataPersonalContactPhoneModel;
}

export class UserDataPersonalContactModel {
	link_id: number;
	name: string;
	@Type(UserDataPersonalContactPhoneModel_ClassTransform_Type)
	phones: UserDataPersonalContactPhoneModel[];
	emails: string[];
	url: string;
	account_uid: number;
	attributes: StudentContactAttributes[];
	relationship: string;
}

export function UserDataPersonalContactModel_ClassTransform_Type() {
	return UserDataPersonalContactModel;
}

export class UserDataUserModel {
	theme: boolean | string;
	student_theme: boolean | string;
	mobile_theme: boolean | string;
	@Transform(dateTransformer)
	created: Date;
	roles: string[];
	password: boolean;
	@Transform(dateTransformer)
	last_online: Date;
	status: boolean;
	profile_picture: string;
	profile_picture_high_res: string;
	admin_type: string[];
	timezone: string;
}

export function UserDataUserModel_ClassTransform_Type() {
	return UserDataUserModel;
}

export class UserDataContactsModel {
	@Type(UserDataPersonalContactModel_ClassTransform_Type)
	personal: UserDataPersonalContactModel[];
}

export function UserDataContactsModel_ClassTransform_Type() {
	return UserDataContactsModel;
}

export class UserDataModel {
	@Type(UserDataUserModel_ClassTransform_Type)
	user: UserDataUserModel;
	@Type(UserDataProfileModel_ClassTransform_Type)
	profile: UserDataProfileModel;
	@Type(UserDataContactModel_ClassTransform_Type)
	contact_field: UserDataContactModel;
	@Type(UserDataStudentModel_ClassTransform_Type)
	student_data: UserDataStudentModel;
	@Type(UserDataContactsModel_ClassTransform_Type)
	contacts: UserDataContactsModel;
	@Type(UserDataGAModel_ClassTransform_Type)
	ga: UserDataGAModel;
}

export class UserTimezoneModel {
	institute_timezone: string;
	timezone: string;
}
