/* eslint-disable no-restricted-globals */
import { Injectable, Optional } from '@angular/core';
import { BehaviorSubject, filter } from 'rxjs';
import {
	GetFunction,
	ObserveFunction,
	SetFunction,
	TypedStorage,
	TypedStoreKey,
	TypedStoreType,
} from 'src/lib/types/typed-storage';
import {
	hasValue,
	isArray,
	isNumber,
	isObject,
	isString,
} from 'src/lib/utilities/compare';
import engine from 'store/dist/store.everything';

/* Documentation found here. https://github.com/marcuswestin/store.js */
const systemStore = engine;

@Injectable({
	providedIn: 'root',
})
export class LocalStoreService implements TypedStorage {
	private storage: Storage;

	private watchlist = new Map<TypedStoreKey, BehaviorSubject<unknown>>();

	constructor(@Optional() storage?: Storage) {
		this.storage = storage ?? localStorage;
	}

	testType = (type: TypedStoreType, value: unknown): boolean => {
		switch (type) {
			case TypedStoreType.ARRAY: {
				if (isArray(value)) {
					return true;
				} else return false;
			}
			case TypedStoreType.OBJECT: {
				if (isObject(value)) {
					return true;
				} else return false;
			}
			case TypedStoreType.STRING:
				if (isString(value)) {
					return true;
				} else return false;
			case TypedStoreType.NUMBER: {
				if (isNumber(value)) {
					return true;
				} else return false;
			}
			case TypedStoreType.BOOLEAN: {
				if (value === true) {
					return true;
				} else if (value === false) {
					return true;
				} else return false;
			}
			default:
				return false;
		}
	};

	remove = (key: TypedStoreKey) => {
		this.storage.removeItem(key as string);
	};

	legacyFlush = <T>(key: TypedStoreKey, type: TypedStoreType) => {
		const value = systemStore.get(key as string);

		if (this.testType(type, value)) {
			return value as T;
		} else return null;
	};

	get: GetFunction = <T>(
		key: TypedStoreKey,
		type: TypedStoreType,
		defaultVal?: T,
	) => {
		const rawValue = this.storage.getItem(key as string);
		if (!hasValue(rawValue)) {
			return defaultVal;
		}

		let parsedValue: unknown;
		try {
			parsedValue = JSON.parse(rawValue);

			if (this.testType(type, parsedValue)) {
				return parsedValue as T;
			} else {
				console.error('LocalStoreService: Invalid type in get', key, type);
			}
		} catch {
			console.error('LocalStoreService: Invalid JSON in get', key, rawValue);
		}

		const legacyVal = this.legacyFlush<T>(key, type);
		if (legacyVal !== null) {
			this.set(key, legacyVal);
			return legacyVal;
		} else return defaultVal;
	};

	set: SetFunction = (key: TypedStoreKey, value: unknown) => {
		this.storage.setItem(key as string, JSON.stringify(value));

		if (this.watchlist.has(key)) {
			this.watchlist.get(key).next(value);
		}
	};

	observe$: ObserveFunction = <T>(
		key: TypedStoreKey,
		type: TypedStoreType,
		defaultVal?: T,
	) => {
		const value = this.get(key, type, defaultVal);

		if (!this.watchlist.has(key)) {
			this.watchlist.set(key, new BehaviorSubject(value));
		}

		return (this.watchlist.get(key) as BehaviorSubject<T>).pipe(
			filter((v) => {
				if (!hasValue(v)) return true;

				const rightType = this.testType(type, v);

				if (rightType === false) {
					console.error(
						'LocalStoreService: Invalid type in observe',
						key,
						type,
					);
				}

				return rightType;
			}),
		);
	};
}
