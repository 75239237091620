import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { ParentStudentModel } from './parent-students.model';

@Injectable({
	providedIn: 'root',
})
export class ParentsService {
	constructor(private httpClient: HttpClient) {}

	public getStudents = (
		parentUid: number,
	): Observable<ParentStudentModel[]> => {
		return this.httpClient
			.get<unknown[]>(`/api/v1/parents/${parentUid}/students`)
			.pipe(
				mapResponse((r) => plainToInstance(ParentStudentModel, r), {
					errorCode: '8667AE07',
				}),
			);
	};
}
