import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { CommunicationSenderModel } from '../communication-sender.model';
import { CommunicationArgument } from '../communication.argument';

@Injectable({
	providedIn: 'root',
})
export class CommunicationEmailsService {
	constructor(private httpClient: HttpClient) {}

	public getSenders = (): Observable<CommunicationSenderModel[]> => {
		return this.httpClient
			.get<unknown[]>(`/api/v1/communication/senders/email`)
			.pipe(
				mapResponse((r) => plainToInstance(CommunicationSenderModel, r), {
					errorCode: 'F1425425',
				}),
			);
	};

	public createEmail = (args: CommunicationArgument): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(`/api/v1/communication/emails/create`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'AA02EBE0',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};
}
