import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import {
	PaperworkApprovalModel,
	PaperworkApproveRejectArgument,
} from './paperwork-approval.model';

@Injectable({
	providedIn: 'root',
})
export class PaperworkApprovalService {
	constructor(private httpClient: HttpClient) {}

	// Unapproved Paperwork
	public getPaperworkApprovalList = (): Observable<
		PaperworkApprovalModel[]
	> => {
		return this.httpClient
			.get<{
				paperwork_requests: unknown[];
			}>(`/api/v1/paperwork/unapproved`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(PaperworkApprovalModel, r.paperwork_requests),
					{
						errorCode: 'B4A8D029',
					},
				),
			);
	};

	//Approve and Reject Paperwork
	public approvePaperwork = (
		studentId: number,
		args: PaperworkApproveRejectArgument,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(`/api/v1/students/${studentId}/paperwork/approve`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'FLAF1622',
				}),
			);
	};

	public refreshPaperwork = () => {
		return this.httpClient.post<unknown>(`/api/v1/paperwork/refresh`, {}).pipe(
			mapResponse((r) => r, {
				errorCode: '0AD73E2A',
			}),
		);
	};
}
