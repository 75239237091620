import { Expose, Transform, Type } from 'class-transformer';
import { integerTransformer } from 'src/lib/utilities/api/class-transformer-types';
import {
	UserAddressModel,
	UserAddressModel_ClassTransform_Type,
} from '../../utility/utility-models/address.model';

// Sub Model Objects
export class StudentInformationPointModel {
	uses_points: boolean;
	total_points: number;
}

export class StudentInformationGraduationPlanModel {
	plan_id: number;
	title: string;
}

export class StudentInformationTrackModel {
	id: number;
	title: string;
}

export class StudentInformationTierModel {
	category: string;
	title: string;
}

export class StudentInformationEmailModel {
	value: string;
	type: string;
	prim: boolean;
	active: boolean;
	weight: number;
}

export class StudentInformationPhoneModel {
	value: string;
	type: string;
	prim: boolean;
	active: boolean;
	weight: number;
	sms: boolean;
}

export class StudentInformationAddressModel {
	@Type(UserAddressModel_ClassTransform_Type)
	value: UserAddressModel;

	type: string;
	prim: boolean;
	active: boolean;
	weight: number;
}

// Main Model Object
export class StudentInformationModel {
	preferred_name?: string;
	attendance_class?: string;
	attendance_class_name: string;
	birthdate?: Date;
	first_name?: string;
	middle_name?: string;
	last_name?: string;
	maiden_name?: string;
	suffix?: string;
	gender?: string;
	@Expose({ name: 'ethnicity' }) // API still refers to race as ethnicity 14E31FFD
	race?: string;
	grade?: string;
	student_id?: string;
	state_id?: string;
	cohort_year?: string;
	@Transform(integerTransformer)
	courses_concurrent?: number;
	district_id?: string;
	calculated_grade?: number;
	email?: string;
	sr_pay_level?: number;
	district_residence?: string;
	custom_district?: string;
	throttle_status?: string;
	student_location?: string;
	timezone: string;
	institute_timezone: string;

	@Expose({ name: 'more_other' })
	student_memo?: string;

	@Type(StudentInformationTrackModel_ClassTransform_Type)
	track?: StudentInformationTrackModel;

	@Type(StudentInformationGraduationPlanModel_ClassTransform_Type)
	graduation_plan?: StudentInformationGraduationPlanModel;

	@Type(StudentInformationTierModel_ClassTransform_Type)
	tier?: StudentInformationTierModel;

	@Type(StudentInformationEmailModel_ClassTransform_Type)
	contact_emails?: StudentInformationEmailModel[];

	@Type(StudentInformationPhoneModel_ClassTransform_Type)
	phone_numbers?: StudentInformationPhoneModel[];

	@Type(UserAddressModel_ClassTransform_Type)
	addresses?: {
		mailing_address: UserAddressModel;
		physical_address: UserAddressModel;
	};

	@Type(StudentInformationPointModel_ClassTransform_Type)
	user_points?: StudentInformationPointModel;
}

// TRANSFORMERS
export function StudentInformationPointModel_ClassTransform_Type() {
	return StudentInformationPointModel;
}

export function StudentInformationModel_ClassTransform_Type() {
	return StudentInformationModel;
}

export function StudentInformationAddressModel_ClassTransform_Type() {
	return StudentInformationAddressModel;
}

export function StudentInformationPhoneModel_ClassTransform_Type() {
	return StudentInformationPhoneModel;
}

export function StudentInformationEmailModel_ClassTransform_Type() {
	return StudentInformationEmailModel;
}

export function StudentInformationTierModel_ClassTransform_Type() {
	return StudentInformationTierModel;
}

export function StudentInformationGraduationPlanModel_ClassTransform_Type() {
	return StudentInformationGraduationPlanModel;
}

export function StudentInformationTrackModel_ClassTransform_Type() {
	return StudentInformationTrackModel;
}
