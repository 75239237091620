import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'upperFirstLetter',
})
export class UpperFirstLetterPipe implements PipeTransform {
	transform(value: string): string {
		if (!value) return null;
		const values = value.split(' ').map((word) => word[0]?.toUpperCase());

		return values.join('');
	}
}
