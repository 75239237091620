import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { buildQueryString } from 'src/lib/utilities/api/http';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import { CommunicationAnnouncementSearchResponseModel } from './communication-announcement-search-response.model';
import { CommunicationAnnouncementSearchArgument } from './communication-announcement-search.argument';
import { CommunicationAnnouncementArgument } from './communication-announcement.argument';
import { CommunicationAnnouncementModel } from './communication-announcement.model';

@Injectable({
	providedIn: 'root',
})
export class CommunicationAnnouncementsService {
	constructor(private httpClient: HttpClient) {}

	public getAnnouncement = (
		aid: number,
	): Observable<CommunicationAnnouncementModel> => {
		return this.httpClient.get<unknown>(`/api/v1/announcement/${aid}`).pipe(
			mapResponse((r) => plainToInstance(CommunicationAnnouncementModel, r), {
				errorCode: 'A7D01EEB',
			}),
		);
	};

	public searchAnnouncements = (
		showAll: boolean,
		pagingArg: PagingArgument<CommunicationAnnouncementSearchArgument>,
	): Observable<CommunicationAnnouncementSearchResponseModel> => {
		const qs = showAll ? buildQueryString({ show_all: showAll }) : '';
		return this.httpClient
			.post<unknown>(`/api/v1/announcement/list${qs}`, pagingArg)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(CommunicationAnnouncementSearchResponseModel, r),
					{ errorCode: '36162052' },
				),
			);
	};

	public createAnnouncement = (
		args: CommunicationAnnouncementArgument,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(`/api/v1/announcement/create`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'FE49FD7F',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public editAnnouncement = (
		aid: number,
		args: Partial<CommunicationAnnouncementArgument>,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(`/api/v1/announcement/${aid}/edit`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'FE49FD7F',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public deleteAnnouncement = (aid: number): Observable<unknown> => {
		return this.httpClient
			.get<unknown>(`/api/v1/announcement/${aid}/delete`)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '20294177',
					errorMessage: 'There was an issue deleting',
				}),
			);
	};
}
