import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { buildFormData, buildQueryString } from 'src/lib/utilities/api/http';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import {
	CreateStudentArgument,
	StudentFilesArgument,
} from '../../students/student.argument';
import { OrganizationStudentEditFieldsConfigurationModel } from './organization-student-edit-fields-configuration.model';
import { OrganizationStudentsSearchModel } from './organization-student-search.model';
import { OrganizationStudentUpdateModal } from './organization-student-update.model';
import {
	OrganizationStudentPagingArgument,
	OrganizationStudentsSearchArgument,
} from './organization-students-search.argument';

@Injectable({
	providedIn: 'root',
})
export class OrganizationsStudentsService {
	constructor(private httpClient: HttpClient) {}

	public getStudents = (
		orgId: number,
		args?: OrganizationStudentPagingArgument,
	): Observable<OrganizationStudentsSearchModel> => {
		return this.httpClient
			.post<OrganizationStudentsSearchModel>(
				`/api/v1/organizations/${orgId}/students`,
				args,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(OrganizationStudentsSearchModel, r),
					{
						errorCode: 'ADECAAC5',
					},
				),
			);
	};

	public getFilteredStudentIds = (
		orgId: number,
		args?: PagingArgument<OrganizationStudentsSearchArgument>,
	): Observable<number[]> => {
		return this.httpClient
			.post<
				number[]
			>(`/api/v1/organizations/${orgId}/students?idsOnly=true`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '9261C6F4',
					checkPreMap: false,
					validateSuccess: false,
				}),
			);
	};

	public createStudent = (
		orgId: number,
		args: CreateStudentArgument,
		files?: StudentFilesArgument,
	): Observable<OrganizationStudentUpdateModal> => {
		const bodyArgs = {};
		bodyArgs['jsonBody'] = args;
		if (files) {
			Object.keys(files).forEach((key) => {
				if (files[key] != null) {
					bodyArgs[key] = files[key]['file'] ?? files[key];
				}
				const isWaived = files[key] ? files[key]['waived'] : null;
				if (isWaived) {
					bodyArgs['jsonBody'][`${key}_comment`] = files[key]['comment'];
					bodyArgs['jsonBody'][`${key}_waived`] = isWaived;
				}
			});
		}

		const formData = buildFormData(bodyArgs);

		return this.httpClient
			.post<unknown>(`/api/v1/organizations/${orgId}/students/add`, formData, {
				headers: new HttpHeaders({ enctype: 'multipart/form-data' }),
			})
			.pipe(
				mapResponse((r) => plainToInstance(OrganizationStudentUpdateModal, r), {
					errorCode: '5D36E3A8',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public getStudentFields = (
		orgId: number,
		studentId?: number,
		tierId?: string,
	): Observable<OrganizationStudentEditFieldsConfigurationModel> => {
		const qs = buildQueryString({
			all_tiers: true,
			uid: studentId,
			tier_id: tierId,
		});

		return this.httpClient
			.get<OrganizationStudentEditFieldsConfigurationModel>(
				`/api/v1/organizations/${orgId}/students/required_fields${qs}`,
			)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(OrganizationStudentEditFieldsConfigurationModel, r),
					{
						errorCode: 'A51A65C9',
						checkPreMap: false,
						validateSuccess: false,
					},
				),
			);
	};
}
