import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { buildFormData } from 'src/lib/utilities/api/http';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import {
	PaperworkTypeModel,
	PaperworkTypeResponseModel,
	ProgramPaperworkEditArgument,
	ProgramPaperworkItemModel,
	ProgramPaperworkPagingArgument,
	ProgramPaperworkResponseModel,
} from './program-paperwork.model';

@Injectable({
	providedIn: 'root',
})
export class ProgramPaperworkService {
	constructor(private httpClient: HttpClient) {}

	public getPaperwork = (
		args: PagingArgument<ProgramPaperworkPagingArgument>,
	): Observable<ProgramPaperworkResponseModel> => {
		return this.httpClient.post<unknown>(`/api/v1/paperwork/search`, args).pipe(
			mapResponse((r) => plainToInstance(ProgramPaperworkResponseModel, r), {
				errorCode: 'D6ECDB1D',
			}),
		);
	};

	public getPaperworkTypes = (): Observable<PaperworkTypeModel[]> => {
		return this.httpClient
			.get<PaperworkTypeResponseModel>(`/api/v1/paperwork/types`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(PaperworkTypeModel, r.paperwork_types),
					{
						errorCode: '290702A6',
					},
				),
			);
	};

	public viewPaperwork = (
		id: number,
	): Observable<ProgramPaperworkItemModel> => {
		return this.httpClient
			.get<{
				paperwork: unknown;
			}>(`/api/v1/paperwork/${id}/view`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(ProgramPaperworkItemModel, r.paperwork),
					{
						errorCode: 'D99FF686',
					},
				),
			);
	};

	public createPaperwork = (
		args: ProgramPaperworkEditArgument,
		file: File,
	): Observable<ProgramPaperworkItemModel[]> => {
		const bodyArgs = {};
		if (file) {
			bodyArgs['file'] = file;
		}
		bodyArgs['jsonBody'] = args;
		const formData = buildFormData(bodyArgs);

		return this.httpClient
			.post<{
				paperwork: unknown[];
			}>(`/api/v1/paperwork/create`, formData)
			.pipe(
				mapResponse(
					(r) => plainToInstance(ProgramPaperworkItemModel, r.paperwork),
					{
						errorCode: '12DC773D',
					},
				),
			);
	};

	public editPaperwork = (
		id: number,
		args: ProgramPaperworkEditArgument,
		file: File,
	) => {
		const bodyArgs = {};
		if (file) {
			bodyArgs['file'] = file;
		}
		bodyArgs['jsonBody'] = args;
		const formData = buildFormData(bodyArgs);

		return this.httpClient
			.post<unknown>(`/api/v1/paperwork/${id}/edit`, formData)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '6141E571',
				}),
			);
	};

	public expirePaperwork = (id: number) => {
		return this.httpClient
			.post<unknown>(`/api/v1/paperwork/${id}/archive`, {})
			.pipe(
				mapResponse((r) => r, {
					errorCode: '9BA7AFA2',
				}),
			);
	};

	public createPaperworkType = (name: string) => {
		return this.httpClient
			.post<unknown>(`/api/v1/paperwork/types/create`, { title: name })
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'B2B0D7D9',
				}),
			);
	};
}
