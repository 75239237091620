import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
	AnnouncementGateType,
	StudentAnnouncementModel,
} from 'src/lib/services/api/students/announcements/student-announcement-model';
import { StudentAnnouncementsService } from 'src/lib/services/api/students/announcements/student-announcements.service';
import { InitializationService } from 'src/lib/services/utility/initialization/initialization.service';
import { TypedStoreType } from 'src/lib/types/typed-storage';
import { BehaviorCache, subscribeAndPromise } from 'src/lib/utilities/cache';
import { firstBy } from 'thenby';
import { LocalStoreService } from '../../local-store/local-store.service';

const gateSortMap = new Map<AnnouncementGateType, number>([
	[AnnouncementGateType.gate, 0],
	[AnnouncementGateType.speedbump, 1],
	[AnnouncementGateType.normal, 2],
]);

const buildLocalReadKey = (studentId) => {
	return `student.${studentId}.announcements.local-read`;
};

@Injectable({
	providedIn: 'root',
})
export class StudentAnnouncementStoreService {
	private _announcementsCache = new BehaviorCache<
		number,
		StudentAnnouncementModel[]
	>(
		(uid) =>
			this.announcementsService.getAnnouncements(uid).pipe(
				map((announcements) => {
					return announcements.sort(
						firstBy((a: StudentAnnouncementModel) =>
							gateSortMap.get(a.is_gate),
						).thenBy((a) => a.start_date, -1),
					);
				}),
			),
		'StudentAnnouncementStore AnnouncementsCache',
		() => [],
	);

	private _localReadCache = new BehaviorCache<number, Record<number, boolean>>(
		(uid) =>
			of(
				this.localStore.get(
					buildLocalReadKey(uid),
					TypedStoreType.OBJECT,
					new Object() as Record<number, boolean>,
				),
			),
		'StudentAnnouncementStore LocalReadCache',
		() => [],
	);

	constructor(
		private announcementsService: StudentAnnouncementsService,
		private initService: InitializationService,
		private localStore: LocalStoreService,
	) {}

	/**
	 * Announcements
	 */
	public announcements$ = (
		uid: number,
	): Observable<StudentAnnouncementModel[]> => {
		return this._announcementsCache.getCache(uid);
	};

	public refreshAnnouncements = (uid: number): Promise<boolean> => {
		return this._announcementsCache.fetchData(uid, true);
	};

	/**
	 * Read Announcements
	 */
	public markAnnouncementRead = (
		uid: number,
		announcementId: number,
	): Promise<unknown> => {
		return subscribeAndPromise(
			this.announcementsService.readAnnouncement(uid, announcementId),
			{
				next: () => {
					this.refreshAnnouncements(uid);
				},
			},
		);
	};

	/**
	 * Local Read Announcements
	 */
	public localRead$ = (uid: number): Observable<Record<number, boolean>> => {
		return this._localReadCache.getCache(uid);
	};

	public setLocalRead = (uid: number, state: Record<number, boolean>): void => {
		if (this.hasLocalRead()) {
			this.localStore.set(buildLocalReadKey(uid), state);
			this._localReadCache.fetchData(uid, true);
		}
	};

	public hasLocalRead = (): boolean => {
		return this.initService.hasAnyRole('parent');
	};
}
