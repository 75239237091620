@if (controlFG) {
	<div
		class="input-group flex-nowrap overflow-hidden"
		container="body"
		ngbDropdown
		placement="bottom-right"
		[autoClose]="'outside'"
		(openChange)="onDropdownOpenChange()"
	>
		<label class="btn btn-secondary m-0">
			<div>
				@switch (controlFG.controls.or.value) {
					@case (true) {
						<span class="screen-reader-text">Value Is</span>
						<i aria-hidden="true" class="fas fa-equals"></i>
					}
					@case (false) {
						<span class="screen-reader-text">Value Is Not</span>
						<i aria-hidden="true" class="fas fa-not-equal"></i>
					}
				}
			</div>
			@if (controlFG.controls.selectedItems?.value.length > 1) {
				<div class="or-nor-counter text-white px-1">
					{{ controlFG.controls.selectedItems?.value.length || 0 }}
				</div>
			}
			<input
				class="screen-reader-text"
				type="checkbox"
				[formControl]="controlFG.controls.or"
				[id]="id + '.or.nor.select.component.or.checkbox'"
			/>
		</label>
		<button
			class="btn btn-block btn-white border-default d-flex squared-start"
			ngbDropdownToggle
			type="button"
			[disabled]="controlFG.controls.selectedItems.disabled"
			[id]="id + '.or.nor.select.component.or.toggle'"
		>
			<div class="flex-grow-1 selection-preview">
				&nbsp;
				@if (controlFG.controls.selectedItems.value.length > 0) {
					<div>
						{{
							bindValue
								? controlFG.controls.selectedItems.value[0][bindLabel]
								: controlFG.controls.selectedItems.value[0]
						}}
					</div>
				}
			</div>
		</button>
		<div ngbDropdownMenu>
			@if (items.length > 10) {
				<div class="dropdown-search">
					<input
						class="form-control"
						placeholder="&#xF002; Filter Items..."
						type="search"
						[formControl]="filterControl"
						[id]="id + '.or.nor.select.component.search'"
					/>
				</div>
			}
			<div class="dropdown-container position-relative" role="list">
				@for (item of $filteredItems | async; track item; let i = $index) {
					<input
						class="dropdown-item-checkbox"
						type="checkbox"
						[checked]="isItemSelected(item)"
						[disabled]="controlFG.controls.selectedItems.disabled"
						[id]="id + '.or.nor.select.component.item.' + i"
						(click)="toggleItem(item)"
						(keydown.enter)="toggleItem(item)"
						(keydown.space)="toggleItem(item); $event.preventDefault()"
					/>
					<label
						class="m-0 dropdown-item"
						role="listitem"
						[for]="id + '.or.nor.select.component.item.' + i"
						[ngClass]="{ active: isItemSelected(item) }"
					>
						{{ bindLabel ? item[bindLabel] : item }}
					</label>
				}
				@if (items.length === 0) {
					<span class="dropdown-item-text text-muted">No Items Available</span>
				}
			</div>
			<div class="dropdown-divider"></div>
			<button class="dropdown-item" type="button" (click)="clearSelection()">
				Clear Selections
			</button>
		</div>
	</div>
}
