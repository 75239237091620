import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import {
	StudentResponseListArgument,
	StudentResponseListModel,
} from './student-response-list.model';

@Injectable({
	providedIn: 'root',
})
export class StudentResponseListService {
	constructor(private httpClient: HttpClient) {}

	public getAllResponses = (
		uid: number,
	): Observable<StudentResponseListModel> => {
		return this.httpClient
			.get<unknown>(`/api/v1/students/${uid}/response_feedback`)
			.pipe(
				mapResponse((r) => plainToInstance(StudentResponseListModel, r), {
					errorCode: 'F392F874',
				}),
			);
	};

	public getFilteredResponses = (
		uid: number,
		args: PagingArgument<StudentResponseListArgument>,
	): Observable<StudentResponseListModel> => {
		return this.httpClient
			.post<unknown>(`/api/v1/students/${uid}/response_feedback`, args)
			.pipe(
				mapResponse((r) => plainToInstance(StudentResponseListModel, r), {
					errorCode: '1AFCB39D',
				}),
			);
	};
}
