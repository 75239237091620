import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import { StudentMeetupGateExemptionArgument } from './student-meetup-gate-exemption.argument';
import { StudentMeetupGateExemptionResponseModel } from './student-meetup-gate-exemption.model';
import { StudentMeetupInfoModel } from './student-meetup.model';
import { StudentMeetupsResponseModel } from './student-meetups-response.model';

@Injectable({
	providedIn: 'root',
})
export class StudentsMeetupsService {
	constructor(private httpClient: HttpClient) {}

	public getStudentMeetups = (
		uid: number,
		args: PagingArgument,
	): Observable<StudentMeetupsResponseModel> => {
		return this.httpClient
			.post<unknown>(`/api/v1/students/${uid}/meetups`, args)
			.pipe(
				mapResponse((r) => plainToInstance(StudentMeetupsResponseModel, r), {
					errorCode: '7D0359C0',
				}),
			);
	};

	// Personal Student Meetup info
	public getMeetupInfo = (uid: number): Observable<StudentMeetupInfoModel> => {
		return this.httpClient
			.get<unknown>(`/api/v1/students/${uid}/meetups/info`)
			.pipe(
				mapResponse((r) => plainToInstance(StudentMeetupInfoModel, r), {
					errorCode: 'B6CDB6DC',
				}),
			);
	};

	public setMeetupInfo = (
		uid: number,
		msg: string,
	): Observable<{ body: string }> => {
		return this.httpClient
			.post<{
				body: string;
			}>(`/api/v1/students/${uid}/meetups/info/update`, {
				body: msg,
			})
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'B39B4C51',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	// meetup gate exemption stuff
	public getMeetupExemptions = (
		studentId: number,
	): Observable<StudentMeetupGateExemptionResponseModel> => {
		return this.httpClient
			.get<unknown>(`/api/v1/students/${studentId}/meetups/gate-exemptions`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentMeetupGateExemptionResponseModel, r),
					{ errorCode: '98E3F78F' },
				),
			);
	};

	public addExemption = (
		studentId: number,
		args: StudentMeetupGateExemptionArgument,
	): Observable<string> => {
		return this.httpClient
			.post<string>(
				`/api/v1/students/${studentId}/meetups/gate-exemptions`,
				args,
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '9E815D6D',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public endExemption = (
		studentId: number,
		exemptionId: number,
	): Observable<string> => {
		return this.httpClient
			.post<string>(
				`/api/v1/students/${studentId}/meetups/gate-exemptions/${exemptionId}`,
				{},
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '3BAFB7BA',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};
}
