<form (ngSubmit)="save()">
	<section #rootElement class="billboard" tabindex="-1">
		<div class="billboard-header">
			<h2 class="billboard-title">Contact</h2>
			<div class="billboard-actions">
				<div class="btn-toolbar">
					<button
						class="btn btn-secondary"
						id="profile.contact.edit.cancel"
						type="button"
						(click)="contactSaveListener(false)"
					>
						Cancel
					</button>
					<button
						class="btn btn-primary"
						id="profile.contact.edit.enable"
						type="submit"
						[disabled]="
							savingInfo || !profileContactFormGroup.valid || !hasChanges
						"
					>
						Save Changes
					</button>
				</div>
			</div>
		</div>
		<div class="billboard-body" [formGroup]="profileContactFormGroup">
			<!-- Phone Numbers -->
			@if (profileContactFormGroup.controls.phone.enabled) {
				<div class="mb-3">
					<div class="d-flex align-items-end">
						<h3 class="flex-grow-1 fw-bold fs-5">Phone Numbers</h3>
						<div class="text-end">
							<button
								class="btn btn-default"
								id="profile.contact.edit.phone.add"
								title="Add Phone Number"
								type="button"
								(click)="addPhone()"
							>
								Add Phone
							</button>
						</div>
					</div>
					<div class="form-group">
						@if (profileContactFormGroup.controls.phone.length > 0) {
							@for (
								item of profileContactFormGroup.controls.phone.controls;
								track item;
								let i = $index;
								let last = $last
							) {
								<div class="mb-3" formArrayName="phone">
									<div class="row" [formGroupName]="i">
										<div aeGroupValidation class="form-group col-md-6 col-xl-4">
											<label for="profile.contact.edit.phone.{{ i }}.phone">
												Number*
											</label>
											<input
												autocomplete="tel"
												class="form-control"
												formControlName="phone"
												type="text"
												id="profile.contact.edit.phone.{{ i }}.phone"
											/>
											<ae-group-validation-display [maxDisplay]="1">
												<ng-template aeValidationError="notsevendigitphone">
													Must be a 10 digit phone number
												</ng-template>
												<ng-template aeValidationError="phone">
													Must be a phone number
												</ng-template>
												<ng-template aeValidationError="required">
													No empty phone inputs
												</ng-template>
											</ae-group-validation-display>
										</div>
										<div class="form-group col-6 col-md-3 col-xl-2">
											<label for="profile.contact.edit.phone.{{ i }}.type">
												Type
											</label>
											<select
												class="form-select"
												formControlName="type"
												id="profile.contact.edit.phone.{{ i }}.type"
											>
												<option selected="true" value=""></option>
												@for (type of phoneOptions | keyvalue; track type) {
													<option [value]="type.key">
														{{ type.value }}
													</option>
												}
											</select>
										</div>
										<div class="form-group col-6 col-md-3 col-xl-2">
											<div class="d-block label-spacer">&nbsp;</div>
											<ae-input-checkbox
												formControlName="sms"
												id="profile.contact.edit.phone.{{ i }}.sms"
											>
												SMS Capable
											</ae-input-checkbox>
										</div>
									</div>
									<div class="row">
										<div class="col-xl-8 d-flex">
											<div class="flex-grow-1">
												<label>
													<input
														class="screen-reader-text"
														type="checkbox"
														[disabled]="savingInfo"
														title="{{
															i === primaryPhoneIndex
																? 'Primary Phone'
																: 'Set as Primary Phone'
														}}"
														(click)="setPrimaryPhone(i)"
													/>
													@switch (i === primaryPhoneIndex) {
														@case (true) {
															<i
																aria-hidden="true"
																class="fas fa-check-square pointer"
															></i>
														}
														@default {
															<i
																aria-hidden="true"
																class="far fa-square pointer"
															></i>
														}
													}
													<span class="unselectable pointer"> Primary </span>
												</label>
											</div>
											<button
												class="btn btn-default"
												title="Remove Phone Number"
												type="button"
												[attr.aria-label]="'Remove Phone Number' + i"
												[disabled]="savingInfo"
												id="profile.contact.edit.phone.{{ i }}.delete"
												(click)="deletePhone(i)"
											>
												Remove
											</button>
										</div>
									</div>
								</div>
							}
						} @else {
							<div class="text-muted">No Phones</div>
						}
					</div>
				</div>
			}

			<!-- Emails -->
			<div class="mb-3">
				<h3 class="fw-bold fs-5">Email Address</h3>
				<div class="text-muted">Edited in Security Tab</div>
			</div>

			<!-- SSO Emails -->
			@if (profileContactFormGroup.controls.ssoEmail.length > 0) {
				<div class="mb-3">
					<h3 class="fw-bold fs-5">SSO Email Address</h3>
					@for (
						fg of profileContactFormGroup.controls.ssoEmail.controls;
						track fg;
						let i = $index;
						let last = $last
					) {
						<div class="d-flex flex-nowrap mb-1">
							<div aeGroupValidation class="flex-grow-1 me-1">
								<input
									autocomplete="off"
									class="form-control"
									placeholder="Street"
									type="text"
									[formControl]="fg.controls.newValue"
									id="profile.contact.edit.ssoEmail.{{ i }}"
								/>
								<ae-group-validation-display [maxDisplay]="1">
									<ng-template aeValidationError="email" let-error>
										{{ error }}
									</ng-template>
									<ng-template aeValidationError="gradAllyRequired" let-error>
										{{ error }}
									</ng-template>
								</ae-group-validation-display>
							</div>
							@if (canEditSSOEmail) {
								<ng-template #ssoEmailDelete>
									{{ getSsoEmailDeleteTitle(fg) }}
								</ng-template>
								<div triggers="hover" [ngbTooltip]="ssoEmailDelete">
									<button
										class="btn btn-icon"
										type="button"
										[disabled]="fg.controls.delete.disabled"
										id="profile.contact.edit.ssoEmail.{{ i }}.delete"
										(click)="
											fg.controls.delete.setValue(!fg.controls.delete.value)
										"
									>
										<i
											aria-hidden="true"
											class="far fa-fw"
											[ngClass]="
												fg.controls.delete.value
													? 'fa-trash-can-undo'
													: ' fa-trash-can'
											"
										></i>
									</button>
								</div>
							}
						</div>
					}
				</div>
			}

			<!-- Physical Address -->
			@if (profileContactFormGroup.controls.physical_address.enabled) {
				<div class="mb-3">
					<div class="d-flex align-items-end">
						<h3 class="flex-grow-1 fw-bold fs-5">Physical Address</h3>
					</div>
					<div class="form-group">
						<div
							class="force-display-feedback mb-3"
							[formGroup]="profileContactFormGroup.controls.physical_address"
						>
							<div class="row">
								<div class="col-xl-8 form-group">
									<label
										for="profile.contact.edit.address.physical_address.street"
									>
										Street*
									</label>
									<input
										autocomplete="address-line1"
										class="form-control"
										formControlName="street"
										id="profile.contact.edit.address.physical_address.street"
										placeholder="Street"
										type="text"
									/>
								</div>
							</div>
							<div class="row">
								<div class="col-xl-8 form-group">
									<label
										for="profile.contact.edit.address.physical_address.street"
									>
										Street 2
									</label>
									<input
										autocomplete="address-line2"
										class="form-control"
										formControlName="street2"
										id="profile.contact.edit.address.physical_address.street2"
										placeholder="Street 2"
										type="text"
									/>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6 col-xl-4 form-group">
									<label
										for="profile.contact.edit.address.physical_address.city"
									>
										City*
									</label>
									<input
										autocomplete="address-level2"
										class="form-control"
										formControlName="city"
										id="profile.contact.edit.address.physical_address.city"
										placeholder="City"
										type="text"
									/>
								</div>
								<div class="col-md-3 col-xl-2 form-group">
									<label
										for="profile.contact.edit.address.physical_address.state"
									>
										State*
									</label>
									<select
										autocomplete="address-level1"
										class="form-select"
										formControlName="state"
										id="profile.contact.edit.address.physical_address.state"
										placeholder="State"
									>
										<option selected value=""></option>
										@for (
											type of contactOptions?.contact_field?.physical_address
												?.subfields?.address?.subfields.state.options;
											track type
										) {
											<option [value]="type.key">
												{{ type.key }}
											</option>
										}
									</select>
								</div>
								<div class="col-md-3 col-xl-2 form-group">
									<label
										for="profile.contact.edit.address.physical_address.zip"
									>
										Zip*
									</label>
									<input
										autocomplete="postal-code"
										class="form-control"
										formControlName="zip"
										id="profile.contact.edit.address.physical_address.zip"
										placeholder="Zip"
										type="text"
										[pattern]="zipRegex"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			}

			<!-- Mailing Address -->
			<div class="mb-3">
				<div class="d-flex align-items-end">
					<h3 class="flex-grow-1 fw-bold fs-5">Mailing Address</h3>
					<ae-input-checkbox
						id="lunchbox.pages.studentinfo.studentinfoform.mailing_address"
						type="toggle"
						[formControl]="toggleAddressCtrl"
					>
						Same As Physical
					</ae-input-checkbox>
				</div>
				<div class="form-group">
					<div
						class="force-display-feedback mb-3"
						[formGroup]="profileContactFormGroup.controls.mailing_address"
					>
						<div class="row">
							<div class="col-xl-8 form-group">
								<label
									for="profile.contact.edit.address.mailing_address.street"
								>
									Street*
								</label>
								<input
									autocomplete="address-line1"
									class="form-control"
									formControlName="street"
									id="profile.contact.edit.address.mailing_address.street"
									placeholder="Street"
									type="text"
								/>
							</div>
						</div>
						<div class="row">
							<div class="col-xl-8 form-group">
								<label
									for="profile.contact.edit.address.mailing_address.street"
								>
									Street 2
								</label>
								<input
									autocomplete="address-line2"
									class="form-control"
									formControlName="street2"
									id="profile.contact.edit.address.mailing_address.street2"
									placeholder="Street 2"
									type="text"
								/>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6 col-xl-4 form-group">
								<label for="profile.contact.edit.address.mailing_address.city">
									City*
								</label>
								<input
									autocomplete="address-level2"
									class="form-control"
									formControlName="city"
									id="profile.contact.edit.address.mailing_address.city"
									placeholder="City"
									type="text"
								/>
							</div>
							<div class="col-md-3 col-xl-2 form-group">
								<label for="profile.contact.edit.address.mailing_address.state">
									State*
								</label>
								<select
									autocomplete="address-level1"
									class="form-select"
									formControlName="state"
									id="profile.contact.edit.address.mailing_address.state"
									placeholder="State"
								>
									<option selected value=""></option>
									@for (
										type of contactOptions?.contact_field?.mailing_address
											?.subfields?.address?.subfields.state.options;
										track type
									) {
										<option [value]="type.key">
											{{ type.key }}
										</option>
									}
								</select>
							</div>
							<div class="col-md-3 col-xl-2 form-group">
								<label for="profile.contact.edit.address.physical_address.zip">
									Zip*
								</label>
								<input
									autocomplete="postal-code"
									class="form-control"
									formControlName="zip"
									id="profile.contact.edit.address.physical_address.zip"
									placeholder="Zip"
									type="text"
									[pattern]="zipRegex"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Calendar -->
			@if (profileContactFormGroup.controls.calendar.enabled) {
				<div>
					<div class="d-flex align-items-end">
						<h3 class="flex-grow-1 fw-bold fs-5">
							Calendar Link
							<ae-icon-tooltip
								class="fs-normal"
								message="Full URL link used for Calendar apps like Calendly"
								type="help"
							/>
						</h3>
						<button
							class="btn btn-default"
							id="profile.contact.edit.calendar.add"
							title="Add Calendar Link"
							type="button"
							[disabled]="
								savingInfo ||
								profileContactFormGroup.controls.calendar.length > 0
							"
							(click)="addCalendar()"
						>
							Add Link
						</button>
					</div>
					<div>
						@if (profileContactFormGroup.controls.calendar.length > 0) {
							@for (
								item of profileContactFormGroup.controls.calendar.controls;
								track item;
								let i = $index;
								let first = $first
							) {
								<div formArrayName="calendar">
									<div class="force-display-feedback mb-3" [formGroupName]="i">
										<div class="row">
											<div aeGroupValidation class="col-xl-8 form-group">
												<label for="profile.contact.edit.calendar.link.{{ i }}"
													>Link</label
												>
												<input
													autocomplete="off"
													class="form-control"
													formControlName="link"
													placeholder="https://calendly.com/janedoe"
													type="text"
													id="profile.contact.edit.calendar.link.{{ i }}"
												/>
												<ae-group-validation-display [maxDisplay]="1">
													<ng-template aeValidationError="required">
														Cannot have an empty link
													</ng-template>
													<ng-template aeValidationError="url">
														Must have a full and proper URL
													</ng-template>
												</ae-group-validation-display>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-xl-8 text-end">
											<button
												class="btn btn-default"
												title="Remove Calendar Link"
												type="button"
												[attr.aria-label]="'Remove Calendar' + i"
												[disabled]="savingInfo"
												(click)="deleteCalendar(i)"
											>
												Remove
											</button>
										</div>
									</div>
								</div>
							}
						} @else {
							<div class="text-muted">No Calendar Link</div>
						}
					</div>
				</div>
			}
		</div>
	</section>
</form>
