import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { buildFormData } from 'src/lib/utilities/api/http';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import {
	MilestoneOptionModel,
	MilestoneStudentModel,
	MilestoneUpdateModelArgs,
} from './milestone.model';

@Injectable({
	providedIn: 'root',
})
export class MilestonesService {
	constructor(private httpClient: HttpClient) {}

	public getMilestoneOptions = (): Observable<MilestoneOptionModel[]> => {
		return this.httpClient
			.get<unknown[]>(`api/v1/user/milestones/options`)
			.pipe(
				mapResponse((r) => plainToInstance(MilestoneOptionModel, r), {
					errorCode: '6EB1E3E1',
				}),
			);
	};

	public viewStudentMilestone = (
		studentId: number,
	): Observable<MilestoneStudentModel[]> => {
		return this.httpClient
			.get<unknown[]>(`api/v1/user/milestones/${studentId}/view`)
			.pipe(
				mapResponse((r) => plainToInstance(MilestoneStudentModel, r), {
					errorCode: 'DE56A8F3',
				}),
			);
	};

	public addStudentMilestone = (
		studentId: number,
		args: MilestoneUpdateModelArgs,
	): Observable<unknown> => {
		const bodyArgs = {};
		if (args.proof) {
			bodyArgs['proof'] = args.proof;
			delete args.proof;
		}
		bodyArgs['jsonBody'] = args;

		return this.httpClient
			.post<unknown>(
				`api/v1/user/milestones/${studentId}/add`,
				buildFormData(bodyArgs),
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '188C4AB0',
				}),
			);
	};

	public editStudentMilestone = (
		studentId: number,
		args: MilestoneUpdateModelArgs,
	): Observable<unknown> => {
		const bodyArgs = {};
		if (args.proof) {
			bodyArgs['proof'] = args.proof;
			delete args.proof;
		}
		bodyArgs['jsonBody'] = args;

		return this.httpClient
			.post<unknown>(
				`api/v1/user/milestones/${studentId}/edit`,
				buildFormData(bodyArgs),
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '754E1BB0',
				}),
			);
	};

	public deleteStudentMilestone = (
		studentId: number,
		assignmentId: number,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(`api/v1/user/milestones/${studentId}/delete`, {
				assignment_id: assignmentId,
			})
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'F4F3EAE4',
				}),
			);
	};
}
