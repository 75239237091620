import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import { UserAddressModel } from '../../utility/utility-models/address.model';

export class ApprovalModel {
	approval_request: ApprovalRequestModel;
	approval_details: ApprovalDetailsModel;
	approval_options: ApprovalOptionsModel;
}

export class ApprovalRequestModel {
	status: number; // 0: "Waiting Approval", 1: "Accepted", -1: "Rejected", -2: "Canceled"
	student_first_name: string;
	student_last_name: string;
	student_uid: number;
	salesforce_id: string;
	address: UserAddressModel;
	birthdate: Date;
	phone: number;
	program_id: number;
	program_name: string;
	referral_source: string;
	opportunity_name: string;
	previous_school: string;
	currently_enrolled_prev: boolean;
	dropout_reason: string;
	dropout_date: Date;
	dropout_code: string;
	iep: boolean;
	504: boolean;
	iep_504_notes: string;
	district_id: string;
	state_id: string;
	@Transform(dateTransformer)
	submitted_on_timestamp: Date;
	@Transform(dateTransformer)
	approved_on_timestamp: Date;
}

export function ApprovalRequestModel_ClassTransform_Type() {
	return ApprovalRequestModel;
}

export class ApprovalDetailsModel {
	// requires the 'ga_salesforce|check approval' permission
	approval_reason: string;
	submitted_by: string; // user name
	approved_by: string; // user name
	suggested_approvers: [
		{
			approver_type: string;
			approvers: string[]; // user names
		},
	];
}

export class ApprovalOptionsModel {
	dropout_code: {
		required: boolean;
	};
	approval_eligibility: {
		ineligible: boolean;
		reason: string;
		institutes: [
			{
				id: number;
				title: string;
			},
		];
	};
}

export class ApprovalRequestsTrackerResponseModel {
	count: number;
	skip: number;
	take: number;
	@Type(ApprovalRequestModel_ClassTransform_Type)
	results: ApprovalRequestModel[];
}
