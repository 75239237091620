export class StaffDataModel {
	profile_picture: string;
	administrator_title: string;
	administrator_type: string;
	account_age: string;
	name: string;
	city_state: string;
	about_me: string;
	profile_link: string;
}
