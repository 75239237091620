import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrService } from 'ngx-toastr';
import { AsyncSubject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import {
	StudentContactAttributes,
	StudentContactOptionsModel,
} from 'src/lib/services/api/students/contacts/student-contact-options.model';
import {
	UserDataPersonalContactPhoneArgument,
	UserUpdateRequestArgument,
} from 'src/lib/services/api/users/update-request/users-update-request.argument';
import { UserUpdateRequestOptions } from 'src/lib/services/api/users/update-request/users-update-request.model';
import { UsersUpdateRequestsService } from 'src/lib/services/api/users/update-request/users-update-requests.service';
import { UserDataPersonalContactModel } from 'src/lib/services/api/users/user-data.model';
import { FormControlWrapper } from 'src/lib/types/forms.def';
import {
	isEquivalentObject,
	isNonEmptyString,
	isNullOrEmptyString,
} from 'src/lib/utilities/compare';
import { convertMapToArray } from 'src/lib/utilities/convert';
import { commonValidators } from 'src/lib/utilities/forms';
import { InputCheckboxComponent } from '../../../../../templates/controls/input-checkbox/input-checkbox.component';
import { GroupValidationDisplayComponent } from '../../../../../templates/layout/group-validation/group-validation-display.component';
import { GroupValidationDirective } from '../../../../../templates/layout/group-validation/group-validation.directive';
import { ValidationErrorDirective } from '../../../../../templates/layout/group-validation/validation-error.directive';

class ProfileContactPhoneFormModel {
	number: number;
	type: string;
	sms: boolean;
	primary: boolean;
}

class ProfileContactEmailFormModel {
	email: string;
}

class ProfileContactFormModel {
	name: FormControl<string>;
	phone: FormArray<FormGroup<FormControlWrapper<ProfileContactPhoneFormModel>>>;
	relationship: FormControl<string>;
	email: FormArray<FormGroup<FormControlWrapper<ProfileContactEmailFormModel>>>;
	describeRelationship: FormControl<string>;
	guardian: FormControl<boolean>;
	delete: FormControl<boolean>;
	link_id?: FormControl<number>;
}

@Component({
	selector: 'ae-profile-familysupport-edit',
	templateUrl: './profile-familysupport-edit.component.html',
	styleUrls: ['./profile-familysupport-edit.component.scss'],
	imports: [
		FormsModule,
		GroupValidationDirective,
		GroupValidationDisplayComponent,
		InputCheckboxComponent,
		NgSelectModule,
		ReactiveFormsModule,
		ValidationErrorDirective,
	],
})
export class ProfileFamilySupportEditComponent implements OnInit, OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();

	@Input() public userId: number;
	@Input() public contactData: UserDataPersonalContactModel[];
	@Input() public phoneOptions: Map<string, string>;
	@Input() public contactOptions: UserUpdateRequestOptions;
	@Input() public contactSaveListener: (forceRefresh: boolean) => void;
	@Input() public ageGate: boolean;
	@Input() public dropdownOptions: StudentContactOptionsModel;

	@ViewChild('rootElement', { static: true })
	rootElement: ElementRef<HTMLElement>;

	public formattedDropdownRelationship: { key: string; value: string }[];
	public formattedDropdownPhoneType: { key: string; value: string }[];

	public savingInfo: boolean = false;
	public profileContactFormGroup: FormArray<FormGroup<ProfileContactFormModel>>;
	private primaryContactIndexOriginal: number = null;

	public primaryContactIndex: number = null;

	public hasChanges = false;

	private copyForm: Partial<{
		name: string;
		phone: Partial<{
			number: number;
			type: string;
			sms: boolean;
			primary: boolean;
		}>[];
		relationship: string;
		email: Partial<{
			email: string;
		}>[];
		describeRelationship: string;
		guardian: boolean;
		delete: boolean;
		link_id?: number;
	}>[];

	constructor(
		private fb: FormBuilder,
		private userRequestService: UsersUpdateRequestsService,
		private toastr: ToastrService,
		private cdr: ChangeDetectorRef,
	) {}

	ngOnInit() {
		if (this.dropdownOptions) {
			this.formattedDropdownRelationship = convertMapToArray(
				this.dropdownOptions.relationship,
			);
			this.formattedDropdownRelationship.push({ key: 'Other', value: 'Other' });

			this.formattedDropdownPhoneType = convertMapToArray(
				this.dropdownOptions.phone_type,
			);
		}
		this.profileContactFormGroup = this.fb.array<
			FormGroup<ProfileContactFormModel>
		>([]);

		this.contactData?.forEach((contact, index) => {
			const contactGroup = this.buildContactGroup(
				contact.account_uid ? true : false,
			);
			if (contact.phones?.length > 1) {
				new Array(contact.phones.length - 1)
					.fill(null)
					.map(() => this.buildPhoneGroup())
					.forEach((p) => contactGroup.controls.phone.push(p));
			}

			if (contact.emails?.length > 1) {
				let i = 0;
				contact.emails.forEach(() => {
					contactGroup.controls.email.push(
						this.buildEmailGroup(i === 0 && contact.account_uid ? true : false),
					);
					i++;
				});
			}

			if (contact.attributes.find((a) => a === 'primary') != null) {
				this.primaryContactIndex = index;
			}

			this.primaryContactIndexOriginal = this.primaryContactIndex;

			const isFoundRelationship = this.dropdownOptions?.relationship.has(
				contact.relationship,
			);

			contactGroup.patchValue({
				delete: false,
				relationship: isFoundRelationship ? contact.relationship : 'Other',
				describeRelationship: isFoundRelationship ? null : contact.relationship,
				guardian: contact.attributes.find((a) => a === 'guardian') != null,
				name: contact.name,
				link_id: contact.link_id,
				email: contact.emails?.map((e) => {
					return {
						email: e,
					};
				}),
				phone: contact.phones?.map((p) => {
					return {
						number: p.phone,
						primary: p.is_primary,
						type: isNonEmptyString(p.type) ? p.type : null,
						sms: p.sms ?? false,
					};
				}),
			});

			contactGroup.controls.email.controls.forEach((emailCtrl, emailIndex) => {
				if (emailIndex === 0 && contact.account_uid) {
					emailCtrl.controls.email.addValidators(Validators.required);
				}
			});

			this.profileContactFormGroup.push(contactGroup);
		});

		if (this.contactData?.length === 0) {
			this.addContact();
		}

		if (this.ageGate) {
			this.profileContactFormGroup.setValidators((_formArr) => {
				if (this.primaryContactIndex == null) {
					return { primaryContactRequired: true };
				}
				return null;
			});

			this.profileContactFormGroup.updateValueAndValidity();
		}

		this.copyForm = this.profileContactFormGroup.controls.map(
			(formGroup) => formGroup.value,
		);

		this.profileContactFormGroup.valueChanges
			.pipe(debounceTime(500))
			.subscribe(() => {
				this.hasChanges =
					!isEquivalentObject(
						this.profileContactFormGroup.value,
						this.copyForm,
					) || this.primaryContactIndexOriginal !== this.primaryContactIndex;
			});
	}

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}

	public save = () => {
		if (this.hasChanges) {
			const controlGroup = this.profileContactFormGroup.controls;
			const profileContactArgs: UserUpdateRequestArgument = {
				contacts: { personal: [] },
			};

			controlGroup.forEach((ctrl, index) => {
				const copy = this.copyForm.find(
					(c) => c.link_id === ctrl.controls.link_id.value,
				);

				if (
					copy == null ||
					!isEquivalentObject(ctrl.value, copy) ||
					this.primaryContactIndexOriginal !== this.primaryContactIndex
				) {
					const phones: UserDataPersonalContactPhoneArgument[] = [];
					const emails: string[] = [];
					let attr: StudentContactAttributes[];
					const groupCtrl = ctrl.controls;

					if (ctrl.controls.link_id?.value) {
						if (
							this.contactData
								.find((data) => {
									return data.link_id === ctrl.controls.link_id.value;
								})
								.attributes.find((a) => {
									return a === 'guardian';
								}) != null
						) {
							attr = ['guardian'];
						}
					}

					if (this.primaryContactIndex === index) {
						attr = ['guardian', 'primary'];
					}

					groupCtrl.phone.controls.forEach((phone) => {
						phones.push({
							is_primary: phone.controls.primary.value,
							phone: phone.controls.number.value,
							sms: phone.controls.sms.value ?? false,
							type: phone.controls.type.value,
						});
					});

					groupCtrl.email.controls.forEach((email) => {
						if (isNullOrEmptyString(email.controls.email?.value)) return;
						emails.push(email.controls.email.value);
					});

					// Find the primary phone and set it (or default to first if any)
					const phoneIndex = phones.findIndex((phone) => {
						return phone.is_primary === true;
					});

					if (groupCtrl.delete.value === false && phones.length !== 0) {
						if (phoneIndex === -1) {
							phones[0].is_primary = true;
						}
					}

					profileContactArgs.contacts.personal.push({
						name: groupCtrl.name.value,
						relationship:
							groupCtrl.relationship.value !== 'Other'
								? groupCtrl.relationship.value
								: groupCtrl.describeRelationship.value,
						account_uid: this.userId,
						attributes: attr,
						emails: emails,
						delete: groupCtrl.delete.value,
						link_id: groupCtrl.link_id.value ? groupCtrl.link_id.value : null,
						phones: phones,
					});
				}
			});

			// Make arrays go bye bye if empty
			if (
				profileContactArgs.contacts.personal.length < 1 &&
				this.contactData.length < 1
			) {
				delete profileContactArgs.contact_field.phone;
			}
			this.userRequestService
				.createUpdateRequest(this.userId, profileContactArgs)
				.subscribe({
					next: (response) => {
						if (response.success) {
							if (response.update_request) {
								this.toastr.success(
									'Contact info update has been requested. Your changes will not be shown until they are approved.',
								);
							} else {
								this.toastr.success('Contact info has been updated.');
							}
							this.contactSaveListener(true);
						} else {
							this.toastr.error(
								`There was an error when updating account info ${(
									response as any
								)?.error_messages.join('\n')}`,
							);
						}
					},
					error: (response) => {
						this.toastr.error(
							`There was an error when updating account info ${(
								response as any
							)?.error_messages.join('\n')}`,
						);
					},
				});
		}
	};

	public addContact = () => {
		this.profileContactFormGroup.push(this.buildContactGroup());

		// HACK. For some reason, the Save Changes button needs a little hand holding here.
		// It doesn't make sense why this is needed, but if the button is enabled before coming here
		// then it panics and has an Expression changed error
		this.cdr.detectChanges();
	};

	private buildContactGroup = (required?: boolean) => {
		const newContactGroup = this.fb.group<ProfileContactFormModel>({
			describeRelationship: new FormControl(null, [
				Validators.required,
				commonValidators.requiredNotEmpty,
			]),
			email: this.fb.array([this.buildEmailGroup(required)]),
			guardian: new FormControl(),
			name: new FormControl(null, [
				Validators.required,
				commonValidators.requiredNotEmpty,
			]),
			phone: this.fb.array([this.buildPhoneGroup()]),
			relationship: new FormControl(),
			delete: new FormControl(false),
			link_id: new FormControl(null),
		});

		newContactGroup.controls.relationship.valueChanges
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((val) => {
				if (val === 'Other') {
					newContactGroup.controls.describeRelationship.enable();
				} else {
					newContactGroup.controls.describeRelationship.patchValue(null);
					newContactGroup.controls.describeRelationship.disable();
				}
			});

		return newContactGroup;
	};

	public buildEmailGroup = (required?: boolean) => {
		const fg = this.fb.group({
			email: new FormControl(null, Validators.email),
		});

		if (required) {
			fg.addValidators(Validators.required);
		}

		return fg;
	};

	public deleteEmail = (index: number, emailIndex: number) => {
		this.profileContactFormGroup.controls[index].controls.email.removeAt(
			emailIndex,
		);
	};

	public buildPhoneGroup = () => {
		const fg = this.fb.group({
			number: new FormControl(null, [
				commonValidators.notSevenDigitPhone,
				commonValidators.phone,
			]),
			sms: new FormControl(true),
			type: new FormControl(null),
			primary: new FormControl(false),
		});

		fg.controls.type.valueChanges
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((val) => {
				if (val) {
					fg.controls.number.addValidators(Validators.required);
					fg.controls.number.markAsTouched();
					fg.controls.number.markAsDirty();
					fg.controls.number.updateValueAndValidity();
				} else {
					if (!fg.controls.sms.value) {
						fg.controls.number.removeValidators(Validators.required);
						fg.controls.number.updateValueAndValidity();
					}
				}
			});

		fg.controls.sms.valueChanges
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((val) => {
				if (val) {
					fg.controls.number.addValidators(Validators.required);
					fg.controls.number.markAsTouched();
					fg.controls.number.markAsDirty();
					fg.controls.number.updateValueAndValidity();
				} else {
					if (!fg.controls.type.value) {
						fg.controls.number.removeValidators(Validators.required);
						fg.controls.number.updateValueAndValidity();
					}
				}
			});

		return fg;
	};

	public deletePhone = (index: number, phoneIndex: number) => {
		this.profileContactFormGroup.controls[index].controls.phone.removeAt(
			phoneIndex,
		);
	};

	public setPrimaryContact = (currentIndex: number) => {
		if (
			!this.profileContactFormGroup.touched &&
			this.profileContactFormGroup.pristine
		) {
			this.profileContactFormGroup.markAsDirty();
		}
		if (this.primaryContactIndex === currentIndex) {
			this.primaryContactIndex = null;
		} else {
			this.primaryContactIndex = currentIndex;
		}
		this.profileContactFormGroup.updateValueAndValidity();
	};

	public deleteContact = (ctrlIndex: number) => {
		const ctrl = this.profileContactFormGroup.controls[ctrlIndex];
		if (ctrlIndex === this.primaryContactIndex) {
			this.setPrimaryContact(ctrlIndex);
		}
		if (ctrl.controls.link_id.value) {
			ctrl.controls.delete.patchValue(true);
			ctrl.disable();
			ctrl.controls.describeRelationship.disable();
			this.cdr.detectChanges();
			ctrl.updateValueAndValidity();
		} else {
			this.profileContactFormGroup.removeAt(ctrlIndex);
		}

		if (
			this.profileContactFormGroup.controls.find(
				(contact) => contact.controls.delete.value === false,
			) == null
		) {
			this.addContact();
		}
	};

	public hasAttribute = (
		contact: UserDataPersonalContactModel,
		attr: StudentContactAttributes,
	): boolean => {
		if (contact == null || contact.attributes == null) {
			return false;
		}
		return contact.attributes.indexOf(attr) !== -1;
	};
}
