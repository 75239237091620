import { Expose, Transform, Type } from 'class-transformer';
import {
	dateTransformer,
	integerTransformer,
} from 'src/lib/utilities/api/class-transformer-types';
import {
	WorkQueueTrackerResponseModel,
	WorkQueueTrackerResponseModel_ClassTransform_Type,
} from '../work-queue/work-queue-entity.model';
import {
	TrackerContactTypeModel,
	TrackerContactTypeModel_ClassTransform_Type,
} from './tracker-contact-type.model';

export class TeacherTrackerOrganizationModel {
	id: number;
	title: string;
	parent_id: number;
	parent_title: string;
	type: string;
	state: string;
}

export function TeacherTrackerOrganizationModel_ClassTransform_Type() {
	return TeacherTrackerOrganizationModel;
}

export class TeacherTrackerFinalModel {
	has_final: number;
	final_submitted: number;
	final_graded: number;
}

export function TeacherTrackerFinalModel_ClassTransform_Type() {
	return TeacherTrackerFinalModel;
}

export class TeacherTrackerLinksModel {
	student_gradebook: string;
	course_gradebook: string;
}

export function TeacherTrackerLinksModel_ClassTransform_Type() {
	return TeacherTrackerLinksModel;
}

export class TeacherTrackerProgramTimezoneModel {
	timezone: string;
	timezone_type: number;
}

export function TeacherTrackerProgramTimezoneModel_ClassTransform_Type() {
	return TeacherTrackerProgramTimezoneModel;
}

export class TeacherTrackerItemModel {
	get uid() {
		return this.student_id;
	}
	student_id: number;
	user_link_id: number;
	mail: string;
	profile_first_name: string;
	profile_last_name: string;
	profile_preferred_name: string;
	profile_grade_level: string;
	age: number;
	registration_id: number;
	registration_title: string;
	@Type(TeacherTrackerOrganizationModel_ClassTransform_Type)
	organization: TeacherTrackerOrganizationModel;
	grade: number;
	@Expose({ name: 'start_timestamp' })
	@Transform(dateTransformer)
	start_date: Date;
	@Expose({ name: 'target_end_timestamp' })
	@Transform(dateTransformer)
	target_end_date: Date;
	@Expose({ name: 'completed_timestamp' })
	@Transform(dateTransformer)
	completed_date: Date;
	literacy: number;
	numeracy: number;
	iep_504: string;
	teacher_notes: string;
	no_final_required_percent: number;
	course_attempt_count: number;
	lms: string;
	lms_success: number;
	lms_progress: number;
	@Expose({ name: 'lms_success' })
	current_grade: number;
	assignments_ungraded: number;
	@Type(TeacherTrackerFinalModel_ClassTransform_Type)
	final: TeacherTrackerFinalModel;
	@Type(TeacherTrackerLinksModel_ClassTransform_Type)
	links: TeacherTrackerLinksModel;
	@Type(WorkQueueTrackerResponseModel_ClassTransform_Type)
	queues: WorkQueueTrackerResponseModel[];
	@Type(TrackerContactTypeModel_ClassTransform_Type)
	last_contact: Partial<TrackerContactTypeModel>;
	@Type(TrackerContactTypeModel_ClassTransform_Type)
	last_attempt: Partial<TrackerContactTypeModel>;
	credits_remain: number;
	@Transform(integerTransformer)
	days_since_last_submission: number;
	@Type(TeacherTrackerProgramTimezoneModel_ClassTransform_Type)
	program_timezone: TeacherTrackerProgramTimezoneModel;
	esl: string;
}
