import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { ExternalAccountArgument } from './external-account.argument';
import { ExternalAccountModel } from './external-account.model';

@Injectable({
	providedIn: 'root',
})
export class ExternalAccountsService {
	constructor(private httpClient: HttpClient) {}

	public getExternalAccounts = (
		uid: number,
	): Observable<ExternalAccountModel[]> => {
		return this.httpClient
			.get<
				ExternalAccountModel[]
			>(`/api/v1/users/external-linked-accounts/${uid}/accounts`)
			.pipe(
				mapResponse((r) => plainToInstance(ExternalAccountModel, r), {
					errorCode: 'BF8C1638',
				}),
			);
	};

	public getExternalAccountsOptions = (): Observable<
		ExternalAccountModel[]
	> => {
		return this.httpClient
			.get<
				ExternalAccountModel[]
			>(`/api/v1/users/external-linked-accounts/options`)
			.pipe(
				mapResponse((r) => plainToInstance(ExternalAccountModel, r), {
					errorCode: '1A7932DB',
				}),
			);
	};

	public createExternalLink = (
		uid: number,
		arg: ExternalAccountArgument,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(
				`/api/v1/users/external-linked-accounts/${uid}/create`,
				arg,
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '90602F61',
				}),
			);
	};
}
