import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import {
	OrganizationModel,
	OrganizationSearchModel,
} from 'src/lib/services/api/organizations/organization.model';
import { buildQueryString } from 'src/lib/utilities/api/http';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import { OrganizationCourseOptionModel } from './organization-class-option.model';
import { OrganizationInfoModel } from './organization-info.model';
import { OrganizationPaperworkOptionModel } from './organization-paperwork-option.model';
import { OrganizationSelectedModel } from './organization-selected.model';
import { OrganizationStaffModel } from './organization-staff.model';

@Injectable({
	providedIn: 'root',
})
export class OrganizationsService {
	constructor(private httpClient: HttpClient) {}

	public getOrganizations = (
		show_deactive: boolean = false,
		include_structural: boolean = false,
	): Observable<OrganizationModel[]> => {
		const qs = buildQueryString({
			show_deactive,
			include_structural,
		});

		return this.httpClient.get<unknown[]>(`/api/v1/organizations${qs}`).pipe(
			mapResponse((r) => plainToInstance(OrganizationModel, r), {
				errorCode: '400AEF26',
			}),
		);
	};

	public searchOrganizations = (
		title: string,
		ids?: number[],
	): Observable<OrganizationModel[]> => {
		return this.httpClient
			.post<unknown[]>(`/api/v1/organizations`, {
				title,
				ids,
			})
			.pipe(
				mapResponse((r) => plainToInstance(OrganizationModel, r), {
					errorCode: 'A517A9E4',
				}),
			);
	};

	public getOrganizationInfo = (
		orgId: number,
	): Observable<OrganizationInfoModel> => {
		return this.httpClient
			.get<unknown>(`api/v1/organizations/${orgId}/organization-info`)
			.pipe(
				mapResponse((r) => plainToInstance(OrganizationInfoModel, r), {
					errorCode: '82F77E96',
				}),
			);
	};

	public getOrganizationStaff = (
		orgId: number,
	): Observable<OrganizationStaffModel[]> => {
		return this.httpClient
			.get<{
				staff: unknown[];
			}>(`/api/v1/organizations/${orgId}/organization-staff`)
			.pipe(
				mapResponse((r) => plainToInstance(OrganizationStaffModel, r.staff), {
					errorCode: '0F972439',
				}),
			);
	};

	public getEnrollmentFormPaperworkOptions = (
		orgId: number,
	): Observable<OrganizationPaperworkOptionModel> => {
		return this.httpClient
			.get<OrganizationPaperworkOptionModel>(
				`/api/v1/organizations/${orgId}/enrollment/form/paperwork`,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(OrganizationPaperworkOptionModel, r),
					{
						errorCode: '0F972439',
						validateSuccess: false,
						checkPreMap: false,
					},
				),
			);
	};

	public getOrgClassOptions = (
		orgId: number,
		showClasses: boolean = true,
	): Observable<OrganizationCourseOptionModel> => {
		return this.httpClient
			.post<{ results: unknown }>(
				`/api/v1/organizations/${orgId}/classes/options`,
				{
					show_classes: showClasses,
				},
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(OrganizationCourseOptionModel, r.results),
					{
						errorCode: 'A31DB238',
					},
				),
			);
	};

	public getProgramSelection = (): Observable<OrganizationSelectedModel> => {
		return this.httpClient
			.get<unknown>(`/api/v1/dashboard/program_selection`)
			.pipe(
				mapResponse((r) => plainToInstance(OrganizationSelectedModel, r), {
					errorCode: '489C2D70',
				}),
			);
	};

	public setProgramSelection = (
		orgIds: number[],
	): Observable<OrganizationSelectedModel> => {
		return this.httpClient
			.post<OrganizationSelectedModel>(`/api/v1/dashboard/program_selection`, {
				selected: orgIds,
			})
			.pipe(
				mapResponse((r) => plainToInstance(OrganizationSelectedModel, r), {
					errorCode: '6A032F0B',
				}),
			);
	};

	public organizationSearch = (
		args: PagingArgument<unknown>,
	): Observable<OrganizationSearchModel> => {
		return this.httpClient
			.post<OrganizationSearchModel>(`/api/v1/organizations/search`, args)
			.pipe(
				mapResponse((r) => plainToInstance(OrganizationSearchModel, r), {
					errorCode: '105523AD',
				}),
			);
	};
}
