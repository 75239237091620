// eslint-disable-next-line no-restricted-imports
import { SelectOptions } from '../types/select-options';

export enum StudentClassFinalStatusEnum {
	submitted = 'Submitted',
	not_submitted = 'Not submitted',
	no_final = 'No final',
}

export enum GraduationPacingValue {
	ahead = 'ahead',
	on_track = 'on track',
	behind = 'behind',
}

export const GraduationPacingValues: readonly {
	readonly title: string;
	readonly key: GraduationPacingValue;
}[] = [
	{
		title: 'Ahead',
		key: GraduationPacingValue.ahead,
	},
	{
		title: 'On Track',
		key: GraduationPacingValue.on_track,
	},
	{
		title: 'Behind',
		key: GraduationPacingValue.behind,
	},
];

export const StudentClassFinalStatusValues: readonly StudentClassFinalStatusEnum[] =
	[
		StudentClassFinalStatusEnum.submitted,
		StudentClassFinalStatusEnum.not_submitted,
		StudentClassFinalStatusEnum.no_final,
	];

export const months: readonly {
	readonly value: number;
	readonly name: string;
	readonly fullname: string;
}[] = [
	{
		value: 0,
		name: 'Jan',
		fullname: 'January',
	},
	{
		value: 1,
		name: 'Feb',
		fullname: 'February',
	},
	{
		value: 2,
		name: 'Mar',
		fullname: 'March',
	},
	{
		value: 3,
		name: 'Apr',
		fullname: 'April',
	},
	{
		value: 4,
		name: 'May',
		fullname: 'May',
	},
	{
		value: 5,
		name: 'Jun',
		fullname: 'June',
	},
	{
		value: 6,
		name: 'Jul',
		fullname: 'July',
	},
	{
		value: 7,
		name: 'Aug',
		fullname: 'August',
	},
	{
		value: 8,
		name: 'Sep',
		fullname: 'September',
	},
	{
		value: 9,
		name: 'Oct',
		fullname: 'October',
	},
	{
		value: 10,
		name: 'Nov',
		fullname: 'November',
	},
	{
		value: 11,
		name: 'Dec',
		fullname: 'December',
	},
];

export const days: readonly {
	readonly value: number;
	readonly name: string;
	readonly fullname: string;
}[] = [
	{
		value: 0,
		name: 'Sun',
		fullname: 'Sunday',
	},
	{
		value: 1,
		name: 'Mon',
		fullname: 'Monday',
	},
	{
		value: 2,
		name: 'Tue',
		fullname: 'Tuesday',
	},
	{
		value: 3,
		name: 'Wed',
		fullname: 'Wednesday',
	},
	{
		value: 4,
		name: 'Thu',
		fullname: 'Thursday',
	},
	{
		value: 5,
		name: 'Fri',
		fullname: 'Friday',
	},
	{
		value: 6,
		name: 'Sat',
		fullname: 'Saturday',
	},
];

export const iep504TableFilterOptions: readonly {
	readonly key: string;
	readonly title: string;
	readonly value?: number;
}[] = [
	{ key: '504', title: '504', value: 2 },
	{ key: 'iep', title: 'IEP', value: 1 },
	{ key: 'iep_504', title: 'IEP and 504', value: 3 },
	{ key: 'any', title: 'Any' },
	{ key: 'none', title: 'None', value: 0 },
];

export const yesNoSelectOptions: readonly SelectOptions<boolean>[] = [
	{ label: 'Yes', value: true },
	{ label: 'No', value: false },
];

export const enrollmentStatusOptions: readonly {
	readonly label: string;
	readonly value: number;
}[] = [
	{ label: 'Inactive', value: 0 },
	{ label: 'Active', value: 1 },
	{ label: 'Pending', value: 2 },
];

export const allLetterGrades: readonly {
	readonly percentage_high: number;
	readonly percentage_low: number;
	readonly letter_grade: string;
	readonly gpa: number;
	readonly is_passing: boolean;
}[] = [
	{
		percentage_high: null,
		percentage_low: null,
		letter_grade: 'A+',
		gpa: 4,
		is_passing: true,
	},
	{
		percentage_high: 5,
		percentage_low: 0.93,
		letter_grade: 'A',
		gpa: 4,
		is_passing: true,
	},
	{
		percentage_high: 0.93,
		percentage_low: 0.9,
		letter_grade: 'A-',
		gpa: 3.67,
		is_passing: true,
	},
	{
		percentage_high: 0.9,
		percentage_low: 0.87,
		letter_grade: 'B+',
		gpa: 3.33,
		is_passing: true,
	},
	{
		percentage_high: 0.87,
		percentage_low: 0.83,
		letter_grade: 'B',
		gpa: 3,
		is_passing: true,
	},
	{
		percentage_high: 0.83,
		percentage_low: 0.8,
		letter_grade: 'B-',
		gpa: 2.67,
		is_passing: true,
	},
	{
		percentage_high: 0.8,
		percentage_low: 0.77,
		letter_grade: 'C+',
		gpa: 2.33,
		is_passing: true,
	},
	{
		percentage_high: 0.77,
		percentage_low: 0.73,
		letter_grade: 'C',
		gpa: 2,
		is_passing: true,
	},
	{
		percentage_high: 0.73,
		percentage_low: 0.7,
		letter_grade: 'C-',
		gpa: 1.67,
		is_passing: true,
	},
	{
		percentage_high: 0.7,
		percentage_low: 0.67,
		letter_grade: 'D+',
		gpa: 1.33,
		is_passing: true,
	},
	{
		percentage_high: 0.67,
		percentage_low: 0.6,
		letter_grade: 'D',
		gpa: 1,
		is_passing: true,
	},
	{
		percentage_high: null,
		percentage_low: null,
		letter_grade: 'D-',
		gpa: 0.67,
		is_passing: true,
	},
	{
		percentage_high: null,
		percentage_low: null,
		letter_grade: 'P',
		gpa: null,
		is_passing: true,
	},
	{
		percentage_high: null,
		percentage_low: null,
		letter_grade: 'W',
		gpa: null,
		is_passing: false,
	},
	{
		percentage_high: null,
		percentage_low: null,
		letter_grade: 'EX',
		gpa: null,
		is_passing: false,
	},
	{
		percentage_high: null,
		percentage_low: null,
		letter_grade: 'NS',
		gpa: null,
		is_passing: false,
	},
	{
		percentage_high: 0.6,
		percentage_low: 0,
		letter_grade: 'NC',
		gpa: null,
		is_passing: false,
	},
	{
		percentage_high: 0.6,
		percentage_low: 0,
		letter_grade: 'F',
		gpa: 0,
		is_passing: false,
	},
	{
		percentage_high: null,
		percentage_low: null,
		letter_grade: 'I',
		gpa: null,
		is_passing: false,
	},
];
