import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { StudentCourseNoteModel } from './student-course-note.model';

@Injectable({
	providedIn: 'root',
})
export class StudentCourseNoteService {
	constructor(private httpClient: HttpClient) {}

	public getNote = (
		uid: number,
		registrationId: number,
	): Observable<StudentCourseNoteModel> => {
		return this.httpClient
			.get<unknown>(
				`api/v1/students/${uid}/classes/${registrationId}/student_notes`,
			)
			.pipe(
				mapResponse((r) => plainToInstance(StudentCourseNoteModel, r), {
					errorCode: '9C5AB447',
				}),
			);
	};

	public editNote = (
		uid: number,
		registrationId: number,
		note: string,
	): Observable<StudentCourseNoteModel> => {
		return this.httpClient
			.post<unknown>(
				`api/v1/students/${uid}/classes/${registrationId}/student_notes`,
				{ notes: note },
			)
			.pipe(
				mapResponse((r) => plainToInstance(StudentCourseNoteModel, r), {
					errorCode: '3B95F805',
				}),
			);
	};
}
