import { Expose, Transform, Type } from 'class-transformer';
import { UserAddressModel } from 'src/lib/services/utility/utility-models/address.model';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import { FieldRequiredValue } from 'src/lib/utilities/api/patterns/dynamic-fields/field-required-value.enum';
import { FieldSelectModel_ClassTransform_TransformFactory } from 'src/lib/utilities/api/patterns/dynamic-fields/field-select.model';
import { FieldModel_ClassTransform_Type } from 'src/lib/utilities/api/patterns/dynamic-fields/field.model';
import {
	StringEnumOption,
	StringEnumOption_ClassTransform_Type,
} from 'src/lib/utilities/api/patterns/dynamic-fields/string-enum-option.model';
import { StudentContactAttributes } from '../../students/contacts/student-contact-options.model';
import { StudentInformationUpdateAddressOptionSubfieldsModel } from '../../students/student-information-update-options.model';
import {
	UserDataContactAddressModel,
	UserDataContactEmailModel,
	UserDataContactMessengerModel,
	UserDataContactPhoneModel,
} from '../user-data.model';

/*
 *
 * Request Model
 *
 */
export class UserUpdateRequestProfileStringModel {
	new: string;
	old: string;
}
export function UserUpdateRequestProfileStringModel_ClassTransform_Type() {
	return UserUpdateRequestProfileStringModel;
}

export class UserUpdateProfileModel {
	@Type(UserUpdateRequestProfileStringModel_ClassTransform_Type)
	profile_first_name: UserUpdateRequestProfileStringModel;
	@Type(UserUpdateRequestProfileStringModel_ClassTransform_Type)
	profile_middle_name: UserUpdateRequestProfileStringModel;
	@Type(UserUpdateRequestProfileStringModel_ClassTransform_Type)
	profile_last_name: UserUpdateRequestProfileStringModel;
	@Type(UserUpdateRequestProfileStringModel_ClassTransform_Type)
	profile_maiden_name: UserUpdateRequestProfileStringModel;
	@Type(UserUpdateRequestProfileStringModel_ClassTransform_Type)
	profile_preferred_name: UserUpdateRequestProfileStringModel;
	@Type(UserUpdateRequestProfileStringModel_ClassTransform_Type)
	profile_student_gender: UserUpdateRequestProfileStringModel;
	@Expose({ name: 'profile_student_ethnicity' }) // API still refers to race as ethnicity 14E31FFD
	@Type(UserUpdateRequestProfileStringModel_ClassTransform_Type)
	profile_student_race: UserUpdateRequestProfileStringModel;
	@Type(UserUpdateRequestProfileStringModel_ClassTransform_Type)
	profile_employee_id: UserUpdateRequestProfileStringModel;
}
export function UserUpdateProfileModel_ClassTransform_Type() {
	return UserUpdateProfileModel;
}

export class UserUpdateStudentDataDobModel {
	@Transform(dateTransformer)
	new: Date;
	@Transform(dateTransformer)
	old: Date;
}
export function UserUpdateStudentDataDobModel_ClassTransform_Type() {
	return UserUpdateStudentDataDobModel;
}

export class UserUpdateStudentDataModel {
	@Type(UserUpdateStudentDataDobModel_ClassTransform_Type)
	dob: UserUpdateStudentDataDobModel;
	@Type(UserUpdateRequestProfileStringModel_ClassTransform_Type)
	cohort_year: UserUpdateRequestProfileStringModel;
	student_given_id: { new: string; old: string };
}
export function UserUpdateStudentDataModel_ClassTransform_Type() {
	return UserUpdateStudentDataModel;
}

export class UserUpdateStudentContactFieldPhoneCompareModel {
	value: number;
	type: string;
	prim: boolean;
	active: boolean;
	weight: number;
	sms: boolean;
}
export function UserUpdateStudentContactFieldPhoneCompareModel_ClassTransform_Type() {
	return UserUpdateStudentContactFieldPhoneCompareModel;
}

export class UserUpdateStudentContactFieldPhoneModel {
	@Type(UserUpdateStudentContactFieldPhoneCompareModel_ClassTransform_Type)
	new: UserUpdateStudentContactFieldPhoneCompareModel[];
	@Type(UserUpdateStudentContactFieldPhoneCompareModel_ClassTransform_Type)
	old: UserUpdateStudentContactFieldPhoneCompareModel[];
}
export function UserUpdateStudentContactFieldPhoneModel_ClassTransform_Type() {
	return UserUpdateStudentContactFieldPhoneModel;
}

export class UserUpdateStudentContactFieldAddressCompareModel extends UserAddressModel {
	active: boolean;
}
export function UserUpdateStudentContactFieldAddressCompareModel_ClassTransform_Type() {
	return UserUpdateStudentContactFieldAddressCompareModel;
}

export class UserUpdateStudentContactFieldAddressModel {
	@Type(UserUpdateStudentContactFieldAddressCompareModel_ClassTransform_Type)
	new: UserUpdateStudentContactFieldAddressCompareModel;
	@Type(UserUpdateStudentContactFieldAddressCompareModel_ClassTransform_Type)
	old: UserUpdateStudentContactFieldAddressCompareModel;
}
export function UserUpdateStudentContactFieldAddressModel_ClassTransform_Type() {
	return UserUpdateStudentContactFieldAddressModel;
}

export class UserUpdateStudentContactFieldModel {
	@Type(UserUpdateStudentContactFieldPhoneModel_ClassTransform_Type)
	phone: UserUpdateStudentContactFieldPhoneModel;
	@Type(UserUpdateStudentContactFieldAddressModel_ClassTransform_Type)
	mailing_address: UserUpdateStudentContactFieldAddressModel;
	@Type(UserUpdateStudentContactFieldAddressModel_ClassTransform_Type)
	physical_address: UserUpdateStudentContactFieldAddressModel;
}
export function UserUpdateStudentContactFieldModel_ClassTransform_Type() {
	return UserUpdateStudentContactFieldModel;
}

export class UserUpdateStudentContactPersonalComparePhoneModel {
	phone: number;
	is_primary: boolean;
	sms_blocked: boolean;
	type: string;
	sms: boolean;
}

export function UserUpdateStudentContactPersonalComparePhoneModel_ClassTransform_Type() {
	return UserUpdateStudentContactPersonalComparePhoneModel;
}

export class UserUpdateStudentContactPersonalCompareModel {
	index?: number; // Used on the front end to keep track of each object during submission
	attributes: StudentContactAttributes[];
	link_id?: number;
	delete: boolean;
	name: string;
	@Type(UserUpdateStudentContactPersonalComparePhoneModel_ClassTransform_Type)
	phones: UserUpdateStudentContactPersonalComparePhoneModel[];
	emails: string[];
	relationship: string;
}
export function UserUpdateStudentContactPersonalCompareModel_ClassTransform_Type() {
	return UserUpdateStudentContactPersonalCompareModel;
}

export class UserUpdateStudentContactPersonalModel {
	@Type(UserUpdateStudentContactPersonalCompareModel_ClassTransform_Type)
	new: UserUpdateStudentContactPersonalCompareModel[];
	@Type(UserUpdateStudentContactPersonalCompareModel_ClassTransform_Type)
	old: UserUpdateStudentContactPersonalCompareModel[];
}

export function UserUpdateStudentContactPersonalModel_ClassTransform_Type() {
	return UserUpdateStudentContactPersonalModel;
}

export class UserUpdateStudentContactModel {
	@Type(UserUpdateStudentContactPersonalModel_ClassTransform_Type)
	personal: UserUpdateStudentContactPersonalModel;
}

export function UserUpdateStudentContactModel_ClassTransform_Type() {
	return UserUpdateStudentContactModel;
}

export class UserUpdateModel {
	@Type(UserUpdateProfileModel_ClassTransform_Type)
	profile: UserUpdateProfileModel;
	@Type(UserUpdateStudentDataModel_ClassTransform_Type)
	student_data: UserUpdateStudentDataModel;
	@Type(UserUpdateStudentContactFieldModel_ClassTransform_Type)
	contact_field: UserUpdateStudentContactFieldModel;
	@Type(UserUpdateStudentContactModel_ClassTransform_Type)
	contacts: UserUpdateStudentContactModel;
}

export function UserUpdateModel_ClassTransform_Type() {
	return UserUpdateModel;
}

export class UserUpdateRequestModel {
	id: number;
	uid: number;
	status: number;
	requested: unknown;
	completed: boolean;
	@Type(UserUpdateModel_ClassTransform_Type)
	updates: UserUpdateModel;
}

export function UserUpdateRequestModel_ClassTransform_Type() {
	return UserUpdateRequestModel;
}

/*
 *
 * Request Options
 *
 */
export class UpdateRequestFieldModel<T> {
	title: string;
	type: string;
	required: FieldRequiredValue;
	can_save: boolean;
	current_value: T;
}

export function UpdateRequestFieldModel_ClassTransform_Type() {
	return UpdateRequestFieldModel;
}

export class UpdateRequestFieldOptionsArrayModel<
	TOption,
	TValue = TOption,
> extends UpdateRequestFieldModel<TValue> {
	options: TOption[];
}

export function UpdateRequestFieldOptionsArrayModel_ClassTransform_Type() {
	return UpdateRequestFieldOptionsArrayModel;
}

export class UpdateRequestFieldOptionsModel<
	TOption,
	TValue = string | number,
> extends UpdateRequestFieldModel<TValue> {
	options: Record<string | number, TOption>;
}

export function UpdateRequestFieldOptionsModel_ClassTransform_Type() {
	return UpdateRequestFieldOptionsModel;
}

export class UpdateRequestBirthdayFieldModel extends UpdateRequestFieldModel<Date> {
	minimum_age: number;
}

export function UpdateRequestBirthdayFieldModel_ClassTransform_Type() {
	return UpdateRequestBirthdayFieldModel;
}

export class UpdateRequestFieldAddressModel<
	T,
> extends UpdateRequestFieldModel<T> {
	subfields: StudentInformationUpdateAddressOptionSubfieldsModel;
}

export function UpdateRequestFieldAddressModel_ClassTransform_Type() {
	return UpdateRequestFieldAddressModel;
}

export class UserUpdateRequestContactOptions {
	@Type(UpdateRequestFieldOptionsArrayModel_ClassTransform_Type)
	phone: UpdateRequestFieldOptionsArrayModel<UserDataContactPhoneModel>;

	@Type(UpdateRequestFieldAddressModel_ClassTransform_Type)
	physical_address: UpdateRequestFieldAddressModel<UserDataContactAddressModel>;

	@Type(UpdateRequestFieldAddressModel_ClassTransform_Type)
	mailing_address: UpdateRequestFieldAddressModel<UserDataContactAddressModel>;

	@Type(UpdateRequestFieldModel_ClassTransform_Type)
	email: UpdateRequestFieldOptionsArrayModel<UserDataContactEmailModel>;

	@Type(UpdateRequestFieldModel_ClassTransform_Type)
	messenger: UpdateRequestFieldModel<UserDataContactMessengerModel[]>;
}

export function UserUpdateRequestContactOptions_ClassTransform_Type() {
	return UserUpdateRequestContactOptions;
}

export class UserUpdateRequestProfileOptions {
	@Type(FieldModel_ClassTransform_Type)
	profile_first_name: UpdateRequestFieldModel<string>;

	@Type(FieldModel_ClassTransform_Type)
	profile_last_name: UpdateRequestFieldModel<string>;

	@Type(FieldModel_ClassTransform_Type)
	profile_preferred_name: UpdateRequestFieldModel<string>;

	@Type(FieldModel_ClassTransform_Type)
	profile_maiden_name: UpdateRequestFieldModel<string>;

	@Transform(
		FieldSelectModel_ClassTransform_TransformFactory(
			StringEnumOption_ClassTransform_Type,
		),
	)
	profile_student_gender: UpdateRequestFieldOptionsArrayModel<StringEnumOption>;

	@Expose({ name: 'profile_student_ethnicity' }) // API still refers to race as ethnicity 14E31FFD
	@Transform(
		FieldSelectModel_ClassTransform_TransformFactory(
			StringEnumOption_ClassTransform_Type,
		),
	)
	profile_student_race: UpdateRequestFieldOptionsArrayModel<StringEnumOption>;
}

export function UserUpdateRequestProfileOptions_ClassTransform_Type() {
	return UserUpdateRequestProfileOptions;
}

export class UserUpdateRequestStudentOptions {
	@Type(UpdateRequestBirthdayFieldModel_ClassTransform_Type)
	dob: UpdateRequestBirthdayFieldModel;
}

export function UserUpdateRequestStudentOptions_ClassTransform_Type() {
	return UserUpdateRequestStudentOptions;
}

export class UserUpdateRequestContactsOptions {
	@Type(FieldModel_ClassTransform_Type)
	personal: UpdateRequestFieldModel<UserUpdateStudentContactPersonalCompareModel>;
}

export function UserUpdateRequestContactsOptions_ClassTransform_Type() {
	return UserUpdateRequestStudentOptions;
}

export class UpdateUserRequestUserOptionsThemeModel {
	key: string;
	title: string;
	color: string;
}

export function UpdateUserRequestUserOptionsThemeModel_ClassTransform_Type() {
	return UserUpdateRequestStudentOptions;
}

export class UpdateUserRequestUserOptionsTimezoneModel {
	key: string;
	title: string;
}

export class UserUpdateRequestUserOptions {
	@Type(UpdateRequestFieldOptionsArrayModel_ClassTransform_Type)
	theme: UpdateRequestFieldOptionsArrayModel<UpdateUserRequestUserOptionsThemeModel>;
	@Type(UpdateRequestFieldOptionsArrayModel_ClassTransform_Type)
	student_theme: UpdateRequestFieldOptionsArrayModel<UpdateUserRequestUserOptionsThemeModel>;
	@Type(UpdateRequestFieldOptionsArrayModel_ClassTransform_Type)
	mobile_theme: UpdateRequestFieldOptionsArrayModel<UpdateUserRequestUserOptionsThemeModel>;
	@Type(UpdateRequestFieldOptionsArrayModel_ClassTransform_Type)
	admin_type: UpdateRequestFieldOptionsArrayModel<string>;
	@Type(UpdateRequestFieldOptionsModel_ClassTransform_Type)
	timezone: UpdateRequestFieldOptionsModel<
		UpdateUserRequestUserOptionsTimezoneModel,
		string
	>;
}

export function UserUpdateRequestUserOptions_ClassTransform_Type() {
	return UserUpdateRequestUserOptions;
}

export class UserUpdateRequestOptions {
	@Type(UserUpdateRequestContactOptions_ClassTransform_Type)
	contact_field: UserUpdateRequestContactOptions;

	@Type(UserUpdateRequestProfileOptions_ClassTransform_Type)
	profile: UserUpdateRequestProfileOptions;

	@Type(UserUpdateRequestStudentOptions_ClassTransform_Type)
	student_data: UserUpdateRequestStudentOptions;

	@Type(UserUpdateRequestContactsOptions_ClassTransform_Type)
	contacts: UserUpdateRequestContactsOptions;

	@Type(UserUpdateRequestUserOptions_ClassTransform_Type)
	user: UserUpdateRequestUserOptions;
}

export function UserUpdateRequestOptions_ClassTransform_Type() {
	return UserUpdateRequestOptions;
}

/*
 *
 * Response
 *
 */
export class UsersRequestOptionsResponse {
	success: boolean;

	@Type(UserUpdateRequestOptions_ClassTransform_Type)
	allowed_updates: UserUpdateRequestOptions;
}

export class UsersPendingRequestsResponse {
	success: boolean;

	@Type(UserUpdateRequestModel_ClassTransform_Type)
	pending_update_requests: UserUpdateRequestModel[];
}
