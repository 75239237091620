import { hasValue, isArray } from './compare';
import { convertSetToArray } from './convert';

export function toDistinctArray<T>(arr: T[]): T[] {
	if (arr == null) return null;
	return [...new Set(arr)];
}

export function arrayUnion<T>(arr1: T[] | T, arr2: T[] | T): T[] {
	arr1 = isArray(arr1) ? arr1 : hasValue(arr1) ? [arr1] : [];
	arr2 = isArray(arr2) ? arr2 : hasValue(arr2) ? [arr2] : [];
	return convertSetToArray(new Set([...arr1, ...arr2]));
}

export function arrayIntersection<T>(arr1: T[] | T, arr2: T[] | T): T[] {
	const cleanArr1 = isArray(arr1) ? arr1 : hasValue(arr1) ? [arr1] : [];
	const cleanArr2 = isArray(arr2) ? arr2 : hasValue(arr2) ? [arr2] : [];

	return convertSetToArray(
		new Set([...cleanArr1.filter((a) => cleanArr2.indexOf(a) !== -1)]),
	);
}

export function arrayDifference<T>(
	sourceArray: T[] | T, // array to check if differenceArray items are not included
	differenceArray: T[] | T, // array of items to check if not included in the sourceArray
): T[] {
	const cleanSourceArray = isArray(sourceArray)
		? sourceArray
		: hasValue(sourceArray)
			? [sourceArray]
			: [];
	const cleanDifferenceArray = isArray(differenceArray)
		? differenceArray
		: hasValue(differenceArray)
			? [differenceArray]
			: [];

	return convertSetToArray(
		new Set([
			...cleanSourceArray.filter((a) => cleanDifferenceArray.indexOf(a) === -1),
		]),
	);
}

export function mergeStrings(
	arr: string[] | string,
	separator: string = ', ',
): string {
	if (isArray(arr)) {
		return arr.join(separator) ?? '';
	} else {
		return arr?.toString() ?? '';
	}
}

// https://gist.github.com/robmathers/1830ce09695f759bf2c4df15c29dd22d
export function groupBy<T, R extends keyof T>(
	data: T[],
	getKey:
		| ((item: T) => string | number)
		| (R & (T[R] extends string | number ? R : never)),
): Record<string | number, T[]> {
	let innerGetKey: (item: T) => string | number;
	if (getKey instanceof Function) {
		innerGetKey = getKey;
	} else innerGetKey = (item: T) => item[getKey] as string | number;

	const store: Record<string | number, T[]> = {};

	return data.reduce(function (storage, item) {
		const group = innerGetKey(item);
		storage[group] = storage[group] || [];
		storage[group].push(item);
		return storage;
	}, store);
}

export function reduceNumberSum(a: number, b: number): number {
	return a + b;
}
