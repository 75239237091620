import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { UserChecklistAssignArgument } from './user-checklist-assign.argument';
import { UserChecklistCompleteItemArgument } from './user-checklist-complete-item.argument';
import { UserChecklistModel } from './user-checklist.model';
import { UserChecklistsAvailableModel } from './user-checklists-available.model';

@Injectable({
	providedIn: 'root',
})
export class UserChecklistsService {
	constructor(private httpClient: HttpClient) {}

	public getUserChecklists = (
		studentId: number,
	): Observable<UserChecklistModel[]> => {
		return this.httpClient
			.get<{
				checklists: unknown[];
			}>(`/api/v1/students/${studentId}/user_checklist`)
			.pipe(
				mapResponse((r) => plainToInstance(UserChecklistModel, r.checklists), {
					errorCode: '8D94943E',
				}),
			);
	};

	public completeChecklistItem = (
		studentId: number,
		checklistId: number,
		actionId: number,
		args: UserChecklistCompleteItemArgument,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(
				`/api/v1/students/${studentId}/user_checklist/${checklistId}/action/${actionId}`,
				args,
			)
			.pipe(mapResponse((r) => r, { errorCode: 'B667C40C' }));
	};

	public getAvailableChecklists = (
		studentId: number,
	): Observable<UserChecklistsAvailableModel[]> => {
		return this.httpClient
			.get<{
				checklists: unknown[];
			}>(`/api/v1/students/${studentId}/user_checklist/possible`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(UserChecklistsAvailableModel, r.checklists),
					{ errorCode: '9D001B7A' },
				),
			);
	};

	public assignTieredIntervention = (
		studentId: number,
		checklistId: number,
		args: UserChecklistAssignArgument,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(
				`/api/v1/students/${studentId}/user_checklist/${checklistId}/assign`,
				args,
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '583300AC',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};
}
