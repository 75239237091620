import { Type } from 'class-transformer';

export enum ResponseCtrlType {
	textfield = 'textfield',
	textarea = 'textarea',
	radios = 'radios',
}

export function StudentResponseFieldsModel_ClassTransform_Type() {
	return StudentResponseFieldsModel;
}

export class StudentResponseConditionsModal {
	key: string;
	value: unknown;
}

export function StudentResponseConditionsModal_ClassTransform_Type() {
	return StudentResponseConditionsModal;
}

export class StudentResponseOptionModel {
	key: string | number;
	title?: string | number;
}

export function StudentResponseOptionModel_ClassTransform_Type() {
	return StudentResponseOptionModel;
}

export class StudentResponseQuestionModel {
	key: string;
	title: string;
	description: string;
	type: ResponseCtrlType;
	is_required?: boolean;
	required?: boolean;
	word_count_minimum?: number;
	category?: string;
	@Type(StudentResponseOptionModel_ClassTransform_Type)
	options?: StudentResponseOptionModel[];
	@Type(StudentResponseConditionsModal_ClassTransform_Type)
	enabled_conditions?: StudentResponseConditionsModal[];
}

export function StudentResponseQuestionModel_ClassTransform_Type() {
	return StudentResponseQuestionModel;
}

export class StudentResponseAllQuestionsModel {
	@Type(StudentResponseQuestionModel_ClassTransform_Type)
	all: StudentResponseQuestionModel[];
}

export function StudentResponseAllQuestionsModel_ClassTransform_Type() {
	return StudentResponseAllQuestionsModel;
}

export class StudentResponseAlertModel {
	type: 'danger' | 'warning' | 'info' | 'success';
	title: string;
	message: string;
}

export function StudentResponseAlertModel_ClassTransform_Type() {
	return StudentResponseAlertModel;
}

export class StudentResponseFieldsModel {
	title: string;
	description: string; //can be html
	required_context: string[];
	@Type(StudentResponseAllQuestionsModel_ClassTransform_Type)
	questions: StudentResponseAllQuestionsModel;
	@Type(StudentResponseAlertModel_ClassTransform_Type)
	alerts: StudentResponseAlertModel[];
}
