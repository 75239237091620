import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class SelfEnrollmentCourseMetadataModel {
	@Transform(dateTransformer)
	completed: Date;
	@Transform(dateTransformer)
	transferred: Date;
}

export function SelfEnrollmentCourseMetadataModel_ClassTransform_Type() {
	return SelfEnrollmentCourseMetadataModel;
}

export class SelfEnrollmentCourseModel {
	class_id: number;
	credits: number;
	@Type(SelfEnrollmentCourseMetadataModel_ClassTransform_Type)
	metadata: SelfEnrollmentCourseMetadataModel;
	selection_type: string;
	subject: string;
	title: string;
}

export function SelfEnrollmentCourseModel_ClassTransform_Type() {
	return SelfEnrollmentCourseModel;
}

export class SelfEnrollmentCourseOptionModel {
	@Type(SelfEnrollmentCourseModel_ClassTransform_Type)
	course_list: SelfEnrollmentCourseModel[];
	total_max_items: number;
	buy_new_classes_link?: string;
}
