import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { ApprovalModel } from './approval-request.model';
import {
	SalesforceApprovalRequestStatusResponseModel,
	SalesforceStudentApprovalRequestStatusModel,
} from './salesforce-approval.model';
import { SalesforceFileListModel } from './salesforce-files.model';
import { StudentEnrollmentDataResponseModel } from './salesforce.model';

@Injectable({
	providedIn: 'root',
})
export class SalesforceService {
	constructor(private httpClient: HttpClient) {}

	public getStudentApprovalRequestStatus = (
		uid: number,
	): Observable<SalesforceStudentApprovalRequestStatusModel> => {
		return this.httpClient
			.get<SalesforceStudentApprovalRequestStatusModel>(
				`/api/v1/enrollment_approval/user/${uid}/status`,
			)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(SalesforceStudentApprovalRequestStatusModel, r),
					{ errorCode: '5EB79CA9', checkPreMap: false, validateSuccess: false },
				),
			);
	};

	public getApprovalRequestStatus = (
		salesforceId: string,
		id: string,
	): Observable<SalesforceApprovalRequestStatusResponseModel> => {
		return this.httpClient
			.get<SalesforceApprovalRequestStatusResponseModel>(
				`/api/v1/enrollment_approval/${salesforceId}/${id}/status`,
			)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(SalesforceApprovalRequestStatusResponseModel, r),
					{ errorCode: 'EA02552D', checkPreMap: false, validateSuccess: false },
				),
			);
	};

	public createApprovalRequest = (
		salesforceId: string,
		id: string,
		instituteId: number,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(
				`/api/v1/enrollment_approval/${salesforceId}/${id}/create`,
				{
					institute_id: instituteId,
				},
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '82B0C8AB',
					errorMessage: DefaultErrorMessage.Saving,
					checkPreMap: false,
					validateSuccess: false,
				}),
			);
	};

	public determineApproval = (
		salesforceId: string,
		id: string,
		reason: string,
		action: string,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(
				`/api/v1/enrollment_approval/${salesforceId}/${id}/determine`,
				{ reason: reason, action: action },
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '1448373E',
					errorMessage: DefaultErrorMessage.Saving,
					checkPreMap: false,
					validateSuccess: false,
				}),
			);
	};

	public studentEnrollmentData = (
		salesforceId: string,
	): Observable<StudentEnrollmentDataResponseModel> => {
		return this.httpClient
			.get<StudentEnrollmentDataResponseModel>(
				`/api/v1/enrollment_approval/${salesforceId}/enrollment_data`,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentEnrollmentDataResponseModel, r),
					{ errorCode: 'ABF82B62', checkPreMap: false, validateSuccess: false },
				),
			);
	};

	public listFiles = (
		salesforceId: string,
	): Observable<SalesforceFileListModel[]> => {
		return this.httpClient
			.get<{
				files: unknown[];
			}>(`/api/v1/enrollment_approval/${salesforceId}/enrollment_file`)
			.pipe(
				mapResponse((r) => plainToInstance(SalesforceFileListModel, r.files), {
					errorCode: '2C363C88',
				}),
			);
	};

	public getFile = (
		salesforceId: string,
		file: {
			id: string;
			title: string; // title needs to include the extension
		},
	): Observable<File> => {
		return this.httpClient
			.get<BlobPart>(
				`/api/v1/enrollment_approval/${salesforceId}/enrollment_file/${file.id}`,
				{ responseType: 'blob' as 'json' },
			)
			.pipe(
				mapResponse((r) => new File([r], file.title), {
					errorCode: '1089263C',
				}),
			);
	};

	public getEnrollmentApprovalRequest = (salesforceId: string) => {
		return this.httpClient
			.get<unknown>(`/api/v1/enrollment_approval/${salesforceId}`)
			.pipe(
				mapResponse((r) => plainToInstance(ApprovalModel, r), {
					errorCode: 'EF29AE0B',
				}),
			);
	};

	public determineApprovalRequest = (
		salesforceId: string,
		action: 'approve' | 'reject',
		reason: string,
		drop_code: string,
	) => {
		const params = {
			action: action,
			reason: reason,
		};

		if (drop_code) {
			params['additionalFields'] = {
				dropout_code: drop_code,
			};
		}

		return this.httpClient
			.post<unknown>(
				`/api/v1/enrollment_approval/${salesforceId}/determine`,
				params,
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '7DCCD69E',
				}),
			);
	};

	public cancelApprovalRequest = (salesforceId: string, reason: string) => {
		return this.httpClient
			.post<unknown>(`/api/v1/enrollment_approval/${salesforceId}/cancel`, {
				reason: reason,
			})
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'D7B75B7F',
				}),
			);
	};

	public createEnrollmentApprovalRequest = (
		salesforceId: string,
		instituteId: number,
	) => {
		return this.httpClient
			.post<unknown>(`/api/v1/enrollment_approval/${salesforceId}/create`, {
				institute_id: instituteId,
			})
			.pipe(
				mapResponse((r) => r, {
					errorCode: '1DC2A2FC',
				}),
			);
	};
}
