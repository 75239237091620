import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { buildFormData } from 'src/lib/utilities/api/http';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import {
	ReentryCompleteArgument,
	ReentryPreApprovalArgument,
} from './re-entry-pre-approval.model';
import {
	ReEntryHistoryModel,
	ReEntryOptionsModel,
	ReEntryRequestCancelArgument,
	ReEntryRequestModel,
	ReEntryRequestResponseModel,
	ReEntryRequestUpsertArgument,
} from './re-entry-request.model';
import { ReentryRequirementsResponseModel } from './re-entry-requirements.model';

@Injectable({
	providedIn: 'root',
})
export class ReEntryRequestService {
	constructor(private httpClient: HttpClient) {}

	public viewStudentReentryHistory = (
		id: number,
	): Observable<ReEntryHistoryModel[]> => {
		return this.httpClient
			.get<{
				reentry_history: unknown[];
			}>(`/api/v1/students/${id}/reentry/history`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(ReEntryHistoryModel, r.reentry_history),
					{
						errorCode: '6F885BDE',
					},
				),
			);
	};

	public checkStudentReentryRequest = (
		id: number,
	): Observable<ReEntryRequestResponseModel> => {
		return this.httpClient
			.get<unknown>(`/api/v1/students/${id}/reentry/check`)
			.pipe(
				mapResponse((r) => plainToInstance(ReEntryRequestResponseModel, r), {
					errorCode: 'F4DF81DB',
					checkPreMap: false,
					validateSuccess: false,
				}),
			);
	};

	public viewStudentReentryRequest = (
		id: number,
	): Observable<ReEntryRequestResponseModel> => {
		return this.httpClient.get<unknown>(`/api/v1/students/${id}/reentry`).pipe(
			mapResponse((r) => plainToInstance(ReEntryRequestResponseModel, r), {
				errorCode: '9E29FC53',
				checkPreMap: false,
				validateSuccess: false,
			}),
		);
	};

	public viewReentryOptions = (): Observable<ReEntryOptionsModel> => {
		return this.httpClient
			.get<unknown>(`/api/v1/students/reentry/options`)
			.pipe(
				mapResponse((r) => plainToInstance(ReEntryOptionsModel, r), {
					errorCode: '40A47C1B',
				}),
			);
	};

	public createStudentReentryRequest = (
		id: number,
		args: ReEntryRequestUpsertArgument,
	): Observable<ReEntryRequestModel> => {
		return this.httpClient
			.post<{
				reentry_request: unknown;
			}>(`/api/v1/students/${id}/reentry/create`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(ReEntryRequestModel, r.reentry_request),
					{
						errorCode: 'FC9D43A5',
					},
				),
			);
	};

	public editStudentReentryRequest = (
		id: number,
		args: ReEntryRequestUpsertArgument,
	): Observable<ReEntryRequestModel> => {
		return this.httpClient
			.post<{
				reentry_request: unknown;
			}>(`/api/v1/students/${id}/reentry/edit`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(ReEntryRequestModel, r.reentry_request),
					{
						errorCode: '390AE32F',
					},
				),
			);
	};

	public cancelStudentReentryRequest = (
		id: number,
		args: ReEntryRequestCancelArgument,
	): Observable<unknown> => {
		return this.httpClient
			.post<{
				reentry_request: unknown;
			}>(`/api/v1/students/${id}/reentry/cancel`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(ReEntryRequestModel, r.reentry_request),
					{
						errorCode: 'DC4D7485',
					},
				),
			);
	};

	public assignStudentReentryCoach = (
		id: number,
	): Observable<{ success: boolean; error_messages?: string[] }> => {
		return this.httpClient
			.get<{
				success: boolean;
				error_messages?: string[];
			}>(`/api/v1/students/${id}/reentry/assign_reentry_coach`)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '8F1F09E0',
					checkPreMap: false,
					validateSuccess: false,
				}),
			);
	};

	public createReentryPreApproval = (
		uid: number,
		args: ReentryPreApprovalArgument,
		proof: File,
	): Observable<unknown> => {
		const bodyArgs = {};

		bodyArgs['proof'] = proof;
		bodyArgs['jsonBody'] = args;

		const formData = buildFormData(bodyArgs);

		return this.httpClient
			.post<unknown>(`/api/v1/students/${uid}/reentry/preapproval`, formData)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '0A119EEA',
				}),
			);
	};

	public viewStudentReentryRequirements = (
		uid: number,
	): Observable<ReentryRequirementsResponseModel> => {
		return this.httpClient
			.get<unknown>(`/api/v1/students/${uid}/reentry/requirements`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(ReentryRequirementsResponseModel, r),
					{
						errorCode: 'A0105868',
					},
				),
			);
	};

	public completeStudentReentry = (
		uid: number,
		args: ReentryCompleteArgument,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(`/api/v1/students/${uid}/reentry/complete`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '86C53EFE',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public markPaperworkComplete = (uid: number): Observable<unknown> => {
		return this.httpClient
			.get<unknown>(
				`api/v1/students/${uid}/reentry/mark_status_paperwork_complete`,
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '86C53EFE',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};
}
