import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable, map } from 'rxjs';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { filterTimezoneOptions } from 'src/lib/utilities/spacetime';
import { ProgramTypeOptionModel } from './program-type-option.model';
import { StateOptionModel } from './state-option.model';

@Injectable({
	providedIn: 'root',
})
export class OptionsService {
	constructor(private httpClient: HttpClient) {}

	public getStateOptions = (): Observable<StateOptionModel[]> => {
		return this.httpClient
			.get<{ states: unknown[] }>(`/api/v1/options/states`)
			.pipe(
				mapResponse((r) => plainToInstance(StateOptionModel, r.states), {
					errorCode: 'EBB2CB1C',
				}),
			);
	};

	public getProgramTypeOptions = (): Observable<ProgramTypeOptionModel[]> => {
		return this.httpClient
			.get<{ program_types: unknown[] }>(`/api/v1/options/program_types`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(ProgramTypeOptionModel, r.program_types),
					{
						errorCode: '2D98BFBC',
					},
				),
			);
	};

	public getTimezoneOptions = (): Observable<string[]> => {
		return this.httpClient.get<string[]>(`/api/v1/timezone/options`).pipe(
			mapResponse((r) => r, {
				errorCode: '40fEA74B',
			}),
			map((r) => filterTimezoneOptions(r)),
		);
	};
}
