import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { UserAgreementModel } from './user-agreement.model';

@Injectable({
	providedIn: 'root',
})
export class UsersAgreementsService {
	constructor(private httpClient: HttpClient) {}

	public getAgreements = (uid: number): Observable<UserAgreementModel[]> => {
		return this.httpClient
			.get<{
				agreements: unknown[];
			}>(`/api/v1/user/${uid}/agreements`)
			.pipe(
				mapResponse((r) => plainToInstance(UserAgreementModel, r.agreements), {
					errorCode: '86563662',
					checkPreMap: false,
					validateSuccess: false,
				}),
			);
	};

	public completeAgreement = (
		uid: number,
		agreementId: string,
	): Observable<unknown> => {
		return this.httpClient
			.get<unknown>(`/api/v1/user/${uid}/agreements/${agreementId}/complete`)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'FF5B5A22',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};
}
