import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
	AsyncSubject,
	BehaviorSubject,
	Observable,
	animationFrameScheduler,
	fromEvent,
	of,
	scheduled,
	timer,
} from 'rxjs';
import {
	delay,
	distinctUntilChanged,
	filter,
	map,
	mergeAll,
	skip,
	switchMap,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { getEnvironment } from 'src/lib/environment/environment';
import { SessionLogger } from 'src/lib/utilities/logging/session-logger';

enum ActivityValue {
	active = 15,

	idle = 20,
	tabSwitched = 25,
}

@Injectable({
	providedIn: 'root',
})
export class UserActiveService implements OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();
	private initialized = false;

	private _isActiveSubject = new BehaviorSubject<boolean>(true);
	private _isNotAwaySubject = new BehaviorSubject<boolean>(true);
	private _tabHiddenSubject = new BehaviorSubject<boolean>(false);
	private document: Document;

	constructor(@Inject(DOCUMENT) document: Document) {
		this.document = document;

		// Log activity
		this.isActive$.subscribe((x) => {
			SessionLogger.log('UserActiveService', 'isActive', x);
		});

		this.isAway$.pipe(skip(1)).subscribe((x) => {
			SessionLogger.log('UserActiveService', 'isAway', x);
		});

		this.tabHidden$.pipe(skip(1)).subscribe((x) => {
			SessionLogger.log('UserActiveService', 'tabHidden', x);
		});
	}

	get isActive$() {
		return this._isActiveSubject.asObservable();
	}
	get isActive() {
		let val = true;
		// This is a hack to get the value synchronously
		this.isActive$.pipe(take(1)).subscribe((x) => (val = x));
		return val;
	}

	get isAway$() {
		return this._isNotAwaySubject.asObservable().pipe(map((x) => !x));
	}
	get isAway() {
		let val = true;
		// This is a hack to get the value synchronously
		this.isAway$.pipe(take(1)).subscribe((x) => (val = x));
		return val;
	}

	get tabHidden$() {
		return this._tabHiddenSubject.asObservable().pipe(map((x) => x));
	}
	get tabHidden() {
		let val = false;
		// This is a hack to get the value synchronously
		this.tabHidden$.pipe(take(1)).subscribe((x) => (val = x));
		return val;
	}

	// https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API#Example
	private getVisibilityApi = (): [string, string] => {
		let hidden: string;
		let visibilityChange: string;

		// Opera 12.10 and Firefox 18 and later support
		if (typeof this.document.hidden !== 'undefined') {
			hidden = 'hidden';
			visibilityChange = 'visibilitychange';
		} else if (typeof this.document['msHidden'] !== 'undefined') {
			hidden = 'msHidden';
			visibilityChange = 'msvisibilitychange';
		} else if (typeof this.document['webkitHidden'] !== 'undefined') {
			hidden = 'webkitHidden';
			visibilityChange = 'webkitvisibilitychange';
		}

		return [hidden, visibilityChange];
	};

	public init = () => {
		if (this.initialized) {
			throw new Error('UserActiveService has already been initialized');
		}
		this.initialized = true;

		const activitySubject = new BehaviorSubject<ActivityValue>(
			ActivityValue.idle,
		);

		// Loop to detect activity
		const activityWatcher = new BehaviorSubject<null>(null);
		activityWatcher
			.pipe(
				switchMap(() => {
					// Watch for activity and then terminate event listeners
					return scheduled(
						[
							fromEvent(this.document, 'mousemove'),
							fromEvent(this.document, 'scroll'),
							fromEvent(this.document, 'mousedown'),
							fromEvent(this.document, 'keydown'),
						],
						animationFrameScheduler,
					).pipe(
						mergeAll(),
						take(1),
						tap(() => {
							activitySubject.next(ActivityValue.active);
						}),
					);
				}),
				// Wait for idle delay before resetting activity watcher
				delay(
					getEnvironment().settings.services.utilities.userActive
						.inactiveDelay / 2,
				),
				takeUntil(this._unsubscribe$),
			)
			.subscribe(() => {
				// Reset activity watcher
				activityWatcher.next(null);
			});

		// Detect tab switch
		const [hidden, visibilityChange] = this.getVisibilityApi();
		this.document.addEventListener(visibilityChange, () => {
			if (this.document[hidden]) {
				activitySubject.next(ActivityValue.tabSwitched);
				this._tabHiddenSubject.next(true);
			} else {
				this._tabHiddenSubject.next(false);
				activitySubject.next(ActivityValue.active);
			}
		});

		// Short term inactive
		this.inactivityWatcher$(
			activitySubject,
			getEnvironment().settings.services.utilities.userActive.inactiveDelay,
			this._isActiveSubject,
		)
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe();

		// Long term inactive
		this.inactivityWatcher$(
			activitySubject,
			getEnvironment().settings.services.utilities.userActive.awayDelay,
			this._isNotAwaySubject,
		)
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe();

		// Detect activity and set back to idle. This needs to be last so it doesn't preempt the other watchers
		activitySubject
			.pipe(
				filter((x) => x === ActivityValue.active),
				takeUntil(this._unsubscribe$),
			)
			.subscribe(() => {
				activitySubject.next(ActivityValue.idle);
			});
	};

	private inactivityWatcher$ = (
		activity$: Observable<ActivityValue>,
		idleDelay: number,
		outputSubject: BehaviorSubject<boolean>,
	) => {
		return activity$.pipe(
			distinctUntilChanged(),
			switchMap((v) => {
				if (v === ActivityValue.idle) {
					// Debounce idle state and output only if still idle
					return timer(idleDelay).pipe(map(() => v));
				} else {
					return of(v);
				}
			}),
			distinctUntilChanged(), // Only output if activity state changed to prevent unnecessary updates
			tap((x) => {
				let active: boolean;

				switch (x) {
					case ActivityValue.active:
						active = true;
						break;
					case ActivityValue.tabSwitched:
					case ActivityValue.idle:
						active = false;
						break;
				}

				outputSubject.next(active);
			}),
		);
	};

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
