export function containsString(
	target: string,
	source: string,
	caseInsensitive: boolean = true,
) {
	target = target || '';
	source = source || '';

	if (caseInsensitive) {
		target = target.toLowerCase();
		source = source.toLowerCase();
	}

	return target.indexOf(source) > -1;
}

export function arrayContainsValue<T, G>(
	target: T[],
	item: G,
	check?: (t: T, i: G) => boolean,
): boolean {
	check =
		check ??
		function (tt: unknown, ii: unknown) {
			return tt === ii;
		};

	return target?.some((t) => check(t, item)) ?? false;
}

export function isString(value: unknown): value is string {
	return typeof value === 'string';
}

export function isNullOrEmptyString(value: unknown): value is null {
	return !isString(value) || value == null || value.trim() === '';
}

export function isNonEmptyString(value: unknown): value is string {
	return isString(value) && !isNullOrEmptyString(value);
}

export function isNumber(value: unknown): value is number {
	return typeof value === 'number' && isFinite(value);
}

export function isInteger(value: unknown): value is number {
	return isNumber(value) && Math.floor(value) === value;
}

export function isDate(value: unknown): value is Date {
	return value instanceof Date;
}

export function isValidDate(value: unknown): value is Date {
	return isDate(value) && isNumber((value as Date).getTime());
}

export function isArray(value: unknown): value is unknown[] {
	return Array.isArray(value);
}

export function isObject(value: unknown): value is Record<string, unknown> {
	return typeof value === 'object';
}

export function hasValue(value: unknown): boolean {
	if (value === 0) return true;
	else if (typeof value === 'boolean') return true;
	else if (isDate(value)) return isValidDate(value);
	else return Boolean(value);
}

export function isEquivalentString(left: string, right: string): boolean {
	if (isString(left) && isString(right)) {
		return left.trim().toLocaleLowerCase() === right.trim().toLocaleLowerCase();
	} else {
		return false;
	}
}

export function isEquivalentArray<T>(
	left: T[],
	right: T[],
	itemCompare?: (il: T, ir: T) => boolean,
): boolean {
	itemCompare =
		itemCompare ||
		function (il: T, ir: T) {
			return il === ir;
		};

	if (isArray(left) && isArray(right)) {
		if (left.length !== right.length) {
			return false;
		} else {
			const leftCopy = left.slice();
			const rightCopy = right.slice();

			while (leftCopy.length > 0) {
				const l = leftCopy.pop();
				const ri = rightCopy.findIndex((r) => itemCompare(l, r));
				if (ri === -1) return false;
				else {
					rightCopy.splice(ri, 1);
				}
			}

			return true;
		}
	} else {
		return left === right;
	}
}

export function hasChangedString(
	original?: string,
	modified?: string,
	options?: {
		trim?: boolean;
		caseInsensitive?: boolean;
	},
): boolean {
	options = options ?? {};
	options.trim = options.trim ?? false;
	options.caseInsensitive = options.caseInsensitive ?? false;

	const origHas = hasValue(original);
	const modHas = hasValue(modified);

	if (!origHas && !modHas) {
		return false;
	}

	if (origHas && !modHas) {
		return true;
	}

	const origStr = isString(original);
	const modStr = isString(modified);

	if (origStr !== modStr) {
		return true;
	}

	if (options.trim) {
		original = original.trim();
		modified = modified.trim();
	}

	if (options.caseInsensitive) {
		original = original.toLowerCase();
		modified = modified.toLowerCase();
	}

	return original !== modified;
}

export function hasChangedNumber(
	original?: number,
	modified?: number,
): boolean {
	const origHas = hasValue(original);
	const modHas = hasValue(modified);

	if (!origHas && !modHas) {
		return false;
	}

	if (origHas && !modHas) {
		return true;
	}

	const origStr = isNumber(original);
	const modStr = isNumber(modified);

	if (origStr !== modStr) {
		return true;
	}

	return original !== modified;
}

export function hasKey<T>(obj: T, key: keyof T): boolean {
	if (obj == null) return false;
	return Object.keys(obj).find((k) => k === key) != null;
}

export function hasSomeKey<T>(obj: T, keys: (keyof T)[]): boolean {
	if (obj == null) return false;
	return Object.keys(obj).some((k) => keys.find((ki) => ki === k) != null);
}

export function isEquivalentObject(o1: unknown, o2: unknown) {
	if (typeof o1 === 'string' && typeof o2 === 'string') {
		return isEquivalentString(o1, o2);
	}

	if (o1 instanceof Array && o2 instanceof Array) {
		return isEquivalentArray<unknown>(o1, o2);
	}

	if (o1 === o2) return true;

	if (o1 == null || o2 == null) {
		return (o1 ?? null) === (o2 ?? null);
	}

	if (typeof o1 != 'object' || typeof o2 != 'object') {
		return false;
	}

	if (o1 instanceof Date && o2 instanceof Date) {
		return o1.getTime() === o2.getTime();
	}

	const o1keys = Object.keys(o1);
	const o2keys = Object.keys(o2);

	if (o1keys.length !== o2keys.length) return false;

	for (const key of o1keys) {
		if (!isEquivalentObject(o1[key], o2[key])) {
			return false;
		}
	}

	return true;
}

export function compareObjects<T extends object>(
	o1: T,
	o2: unknown,
): {
	[K in keyof T]: (
		compare?: (o1Val: T[K], o2Val: unknown) => boolean,
	) => ReturnType<typeof compareObjects<T>>;
} & { isDifferent: boolean; isSame: boolean } {
	// Store the results of individual comparisons
	const results: Partial<Record<keyof T, boolean>> = {};

	// Pre-check for null values
	if (o1 == null || o2 == null) {
		// eslint-disable-next-line eqeqeq
		if (o1 != o2) {
			results['isSame'] = false;
		} else {
			results['isSame'] = true;
		}
	}

	// Perform the comparisons for each key
	const proxy = new Proxy(
		{},
		{
			get(_, key: string | symbol) {
				if (key === 'isDifferent') {
					return !Object.values(results).every(Boolean);
				}
				if (key === 'isSame') {
					return Object.values(results).every(Boolean);
				}

				if (typeof key === 'string') {
					return (compare?: (o1Val: T[keyof T], o2Val: unknown) => boolean) => {
						// If the objects are null, bail on the comparison and return null
						if (o1 == null || o2 == null) {
							return proxy;
						}

						if (!(key in results)) {
							const o1Val = o1[key as keyof T];
							const o2Val = o2[key as keyof T];

							// Use the provided compare function or default to strict equality
							results[key as keyof T] = compare
								? compare(o1Val, o2Val)
								: isEquivalentObject(o1Val, o2Val);
						}

						// Return the proxy itself to allow chaining
						return proxy;
					};
				}

				// If the key is not valid, return empty but fail the compare
				results[key as keyof T] = false;
				return proxy;
			},
		},
	);

	return proxy as {
		[K in keyof T]: (
			compare?: (o1Val: T[K], o2Val: T[K]) => boolean,
		) => ReturnType<typeof compareObjects<T>>;
	} & { isDifferent: boolean; isSame: boolean };
}
