import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import {
	CurriculumMappingEndorsementListResponseModel,
	CurriculumMappingEndorsementModel,
	CurriculumMappingEndorsementOptionsModel,
	CurriculumMappingEndorsementsPagingArgument,
	CurriculumMappingEndorsementUpsertArgument,
} from './curriculum-mapping-endorsement.model';

@Injectable({
	providedIn: 'root',
})
export class CurriculumMappingEndorsementsService {
	constructor(private httpClient: HttpClient) {}

	public getAllEndorsements = (
		args?: PagingArgument<CurriculumMappingEndorsementsPagingArgument>,
	): Observable<CurriculumMappingEndorsementListResponseModel> => {
		return this.httpClient
			.post<unknown>(`/api/v1/teacher/endorsement/all`, args)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(CurriculumMappingEndorsementListResponseModel, r),
					{ errorCode: 'B0D7B743' },
				),
			);
	};

	public getEndorsement = (
		id: number,
	): Observable<CurriculumMappingEndorsementModel> => {
		return this.httpClient
			.get<{
				endorsement: unknown;
			}>(`/api/v1/teacher/endorsement/${id}`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(CurriculumMappingEndorsementModel, r.endorsement),
					{
						errorCode: 'ACA1EA72',
					},
				),
			);
	};

	public addEndorsement = (
		args: CurriculumMappingEndorsementUpsertArgument,
	): Observable<CurriculumMappingEndorsementModel> => {
		return this.httpClient
			.post<{
				endorsement: unknown;
			}>(`/api/v1/teacher/endorsement/create`, args)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(CurriculumMappingEndorsementModel, r.endorsement),
					{
						errorMessage: 'There was an issue adding the endorsement',
						errorCode: '4B6E8E43',
					},
				),
			);
	};

	public editEndorsement = (
		endorsementId: number,
		args: CurriculumMappingEndorsementUpsertArgument,
	): Observable<CurriculumMappingEndorsementModel> => {
		return this.httpClient
			.post<{
				endorsement: unknown;
			}>(`/api/v1/teacher/endorsement/${endorsementId}/edit`, args)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(CurriculumMappingEndorsementModel, r.endorsement),
					{
						errorMessage: 'There was an issue updating the endorsement',
						errorCode: '80B6105D',
					},
				),
			);
	};

	public deleteEndorsement = (id: number): Observable<boolean> => {
		return this.httpClient
			.post<unknown>(`/api/v1/teacher/endorsement/${id}/delete`, {})
			.pipe(
				mapResponse((r) => (r ? true : false), {
					errorMessage: 'There was an issue removing the endorsement',
					errorCode: 'DCABB2F4',
				}),
			);
	};

	public getEndorsementOptions =
		(): Observable<CurriculumMappingEndorsementOptionsModel> => {
			return this.httpClient
				.get<{
					options: unknown;
				}>(`/api/v1/teacher/endorsement/options`)
				.pipe(
					mapResponse(
						(r) =>
							plainToInstance(
								CurriculumMappingEndorsementOptionsModel,
								r.options,
							),
						{ errorCode: 'EFE0E974' },
					),
				);
		};
}
