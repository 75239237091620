export enum StudentStateTestAttendanceStatus {
	attending = 1,
	declined = 2,
	cancelled = 3,
	not_attending = 4,
}

export enum StudentStateTestStatus {
	// untaken = NULL
	taken = 1,
	excused = 2,
	attempt_only = 3,
}

export enum StudentStateTestResult {
	// untaken = NULL
	passed = 1,
	failed = 2,
	not_recorded = 3,
	pending = 4,
	req_met = 5,
}

export const stateTestResultOptions = new Map<
	StudentStateTestResult | 0,
	string
>([
	[0, 'Untaken'], // Untaken == null, 0 is placeholder
	[StudentStateTestResult.passed, 'Passing'],
	[StudentStateTestResult.failed, 'Non-passing'],
	[StudentStateTestResult.not_recorded, 'Not Recorded'],
	[StudentStateTestResult.pending, 'Pending'],
	[StudentStateTestResult.req_met, 'Requirement Met'],
]);

export const stateTestStatusOptions = new Map<
	StudentStateTestStatus | 0,
	string
>([
	[0, 'Untaken'], // Untaken == null, 0 is placeholder
	[StudentStateTestStatus.taken, 'Taken'],
	[StudentStateTestStatus.excused, 'Excused'],
	[StudentStateTestStatus.attempt_only, 'Attempt Only'],
]);
