import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { buildQueryString } from 'src/lib/utilities/api/http';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { isArray } from 'src/lib/utilities/compare';
import { UserSearchModel } from './user-search.model';

@Injectable({
	providedIn: 'root',
})
export class UserSearchService {
	constructor(private httpClient: HttpClient) {}

	public userSearch = (search: string): Observable<UserSearchModel[]> => {
		const qs = buildQueryString({ search: search });

		return this.httpClient.get<unknown[]>(`/api/v1/user/search${qs}`).pipe(
			mapResponse((r) => plainToInstance(UserSearchModel, r), {
				errorCode: '98032DDA',
			}),
			map((x) => (isArray(x) ? x : [])),
		);
	};
}
