import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StudentSchoolSearchModel } from 'src/lib/services/api/students/transcripts/school-search.model';
import { StudentTranscriptsService } from 'src/lib/services/api/students/transcripts/student-transcripts.service';
import { BehaviorCache } from 'src/lib/utilities/cache';

@Injectable({
	providedIn: 'root',
})
export class StudentTranscriptsStoreService {
	private _schoolCache = new BehaviorCache<number, StudentSchoolSearchModel>(
		(id) => this.transcriptsService.getSchool(id),
		'StudentTranscriptsStoreService SchoolCache',
	);

	constructor(private transcriptsService: StudentTranscriptsService) {}

	/**
	 * School
	 */
	public school$ = (id: number): Observable<StudentSchoolSearchModel> => {
		return this._schoolCache.getCache(id);
	};

	public refreshSchool = (id: number): Promise<boolean> => {
		return this._schoolCache.fetchData(id, true);
	};
}
