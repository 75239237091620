<div
	#popover="ngbPopover"
	container="body"
	placement="bottom"
	triggers="hover focus"
	[ngbPopover]="validationErrors?.length > 0 ? errors : null"
>
	<ng-content />
</div>

<ng-template #errors>
	@for (validationError of validationErrors; track validationError.error) {
		<div class="text-danger" role="alert">
			<ng-container
				[ngTemplateOutlet]="validationError.display.template"
				[ngTemplateOutletContext]="{ $implicit: validationError.error }"
			/>
		</div>
	}
</ng-template>
