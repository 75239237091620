import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { CalendarEventModel } from '../../organizations/calendar/calendar-event.model';

@Injectable({
	providedIn: 'root',
})
export class StudentProgramEventsService {
	constructor(private httpClient: HttpClient) {}

	public getStudentListCalendar = (
		uid: number,
		startDate: string,
		endDate: string,
	): Observable<CalendarEventModel[]> => {
		return this.httpClient
			.get<{
				calendar_events: unknown[];
			}>(
				`/api/v1/students/${uid}/calendar?start_date=${startDate}&end_date=${endDate}`,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(CalendarEventModel, r.calendar_events),
					{
						errorCode: '322e772c',
					},
				),
			);
	};
}
