import { Expose, Transform, Type } from 'class-transformer';
import {
	dateTransformer,
	integerTransformer,
} from 'src/lib/utilities/api/class-transformer-types';

export class EnrollmentInfoStudentDataModel {
	enrolled_by: string;
	@Transform(integerTransformer)
	free_reduced_lunch: number;
	last_school: string;
	previous_schools: string[];
	iep_504: string;
	@Transform(integerTransformer)
	dropout_reasons: number[];
	dropout_reasons_other: string;
	@Transform(dateTransformer)
	dropout_date: Date;
	referral_source: string;
	salesforce_id: string;
	career_pathway: string;
	esl_check: number;
	@Transform(dateTransformer)
	safe_funding_expiration: Date;
	computer_access_plan: string;
}

export class EnrollmentInfoPaperworkWaiveModel {
	by: number;
	by_name: string;
	@Expose({ name: 'timestamp' })
	@Transform(dateTransformer)
	waive_date: Date;
}

export function EnrollmentInfoPaperworkWaiveModel_ClassTransformType() {
	return EnrollmentInfoPaperworkWaiveModel;
}

export class EnrollmentInfoPaperworkModel {
	file_id: string;
	description: string;
	key: string;
	url: string;
	@Expose({ name: 'expiration_timestamp' })
	@Transform(dateTransformer)
	expiration_date: Date;
	@Expose({ name: 'uploaded_timestamp' })
	@Transform(dateTransformer)
	uploaded_date: Date;
	uploaded_by: number;
	uploaded_by_name: string;
	comment: string;
	@Type(EnrollmentInfoPaperworkWaiveModel_ClassTransformType)
	waived: EnrollmentInfoPaperworkWaiveModel;
}

export class EnrollmentInfoEditResponseModel {
	uid: number;
}
