import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { buildFormData } from 'src/lib/utilities/api/http';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import { SchoolCoursesSubjectsModel } from './school-course-list.model';
import {
	SchoolSearchArgument,
	SchoolSearchPagingArgument,
} from './school-search.argument';
import {
	StudentSchoolSearchListModel,
	StudentSchoolSearchModel,
} from './school-search.model';
import {
	StudentOfficialTranscriptRequestArgument,
	StudentTranscriptRequestArgument,
} from './student-transcript-request.argument';
import { StudentTranscriptRequestsResponseModel } from './student-transcript-requests-response.model';
import {
	StudentTranscriptHistoryModel,
	StudentTranscriptModel,
	StudentTranscriptUpdateArgument,
} from './student-transcripts.model';
import { TranscriptGenerateArgument } from './transcript-generate.argument';

@Injectable({
	providedIn: 'root',
})
export class StudentTranscriptsService {
	constructor(private httpClient: HttpClient) {}

	public getTranscriptRequests = (
		studentId: number,
	): Observable<StudentTranscriptRequestsResponseModel> => {
		return this.httpClient
			.get<StudentTranscriptRequestsResponseModel>(
				`/api/v1/students/${studentId}/transcript/request`,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentTranscriptRequestsResponseModel, r),
					{ errorCode: '09FC6E11' },
				),
			);
	};

	public createTranscriptRequest = (
		studentId: number,
		args: StudentTranscriptRequestArgument,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(`/api/v1/students/${studentId}/transcript/request`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '8979678E',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public createOfficialTranscriptRequest = (
		studentId: number,
		args: StudentOfficialTranscriptRequestArgument,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(
				`api/v1/students/${studentId}/transcript/request_official_transcript`,
				args,
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '7b02fe2c',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public bulkGenerateTranscripts = (args: TranscriptGenerateArgument[]) => {
		return this.httpClient
			.post<unknown>(`api/v1/students/transcript/generate`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '1DDBA020',
				}),
			);
	};

	public updateTranscript = (
		studentId: number,
		transcriptId: number,
		args: StudentTranscriptUpdateArgument,
	): Observable<StudentTranscriptModel> => {
		return this.httpClient
			.post<{
				transcript: unknown;
			}>(`api/v1/students/${studentId}/transcript/${transcriptId}`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentTranscriptModel, r.transcript),
					{
						errorCode: 'B407E594',
					},
				),
			);
	};

	public createSchool = (
		args: Partial<SchoolSearchArgument>,
	): Observable<StudentSchoolSearchModel> => {
		return this.httpClient
			.post<{
				school: unknown;
			}>(`api/v1/transcripts/schools/create`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentSchoolSearchModel, r.school),
					{
						errorCode: '58DA2EB7',
					},
				),
			);
	};

	public getSchool = (
		schoolId: number,
	): Observable<StudentSchoolSearchModel> => {
		return this.httpClient
			.get<{
				school: unknown;
			}>(`api/v1/transcripts/schools/${schoolId}`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentSchoolSearchModel, r.school),
					{
						errorCode: '0312B3B9',
					},
				),
			);
	};

	public editSchool = (
		schoolId: number,
		args: Partial<SchoolSearchArgument>,
	): Observable<StudentSchoolSearchModel> => {
		return this.httpClient
			.post<{
				school: unknown;
			}>(`api/v1/transcripts/schools/${schoolId}`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentSchoolSearchModel, r.school),
					{
						errorCode: '96C3E378',
					},
				),
			);
	};

	public deleteSchool = (schoolId: number): Observable<unknown> => {
		return this.httpClient
			.delete<unknown>(`api/v1/transcripts/schools/${schoolId}`)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '83280A1E',
				}),
			);
	};

	public getTranscripts = (
		studentId: number,
	): Observable<StudentTranscriptModel[]> => {
		return this.httpClient
			.get<{
				transcripts: unknown[];
			}>(`/api/v1/students/${studentId}/transcript`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentTranscriptModel, r.transcripts),
					{
						errorCode: '9E84E6BD',
					},
				),
			);
	};

	public createTranscript = (
		studentId: number,
		file: File,
		schoolId: number,
	): Observable<unknown> => {
		const bodyArgs = {};
		bodyArgs['jsonBody'] = { school_id: schoolId };
		bodyArgs['transcript_file'] = file;
		const formData = buildFormData(bodyArgs);

		return this.httpClient
			.post<unknown>(
				`/api/v1/students/${studentId}/transcript/create`,
				formData,
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'C94AD81A',
				}),
			);
	};

	public deleteTranscript = (
		studentId: number,
		transcriptId: number,
	): Observable<unknown> => {
		return this.httpClient
			.delete<unknown>(
				`/api/v1/students/${studentId}/transcript/${transcriptId}`,
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '23E727CA',
				}),
			);
	};

	public generateEXPLTranscript = (
		studentId: number,
		credits: number,
	): Observable<StudentTranscriptModel> => {
		return this.httpClient
			.post<{
				transcript: unknown;
			}>(`/api/v1/students/${studentId}/transcript/expl/create`, {
				credits_earned: credits,
			})
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentTranscriptModel, r.transcript),
					{
						errorCode: '41C2C98F',
					},
				),
			);
	};

	public getTranscriptHistory = (
		studentId: number,
		transcriptId: number,
	): Observable<StudentTranscriptHistoryModel[]> => {
		return this.httpClient
			.get<{
				results: unknown[];
			}>(`/api/v1/students/${studentId}/transcript/${transcriptId}/history`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentTranscriptHistoryModel, r.results),
					{ errorCode: '34C30356' },
				),
			);
	};

	public noTranscriptNeeded = (studentId: number): Observable<unknown> => {
		return this.httpClient
			.get<unknown>(`/api/v1/students/${studentId}/transcript/unneeded`)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'F9135DAA',
				}),
			);
	};

	public confirmTranscript = (
		studentId: number,
		transcriptId: number,
		confirm: boolean = true,
	): Observable<StudentTranscriptModel> => {
		return this.httpClient
			.post<{
				transcript: unknown;
			}>(`/api/v1/students/${studentId}/transcript/${transcriptId}/confirm`, {
				confirm_mapping: confirm,
			})
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentTranscriptModel, r.transcript),
					{
						errorCode: '5F6F1BB4',
					},
				),
			);
	};

	public getTranscriptCoursesSubjects = (
		studentId: number,
	): Observable<SchoolCoursesSubjectsModel> => {
		return this.httpClient
			.get<unknown>(`/api/v1/students/${studentId}/transcript/course_list`)
			.pipe(
				mapResponse((r) => plainToInstance(SchoolCoursesSubjectsModel, r), {
					errorCode: '9311E8EE',
				}),
			);
	};

	public searchSchools = (
		args?: PagingArgument<SchoolSearchPagingArgument>,
	): Observable<StudentSchoolSearchListModel> => {
		return this.httpClient
			.post<unknown>(`/api/v1/transcripts/schools`, args)
			.pipe(
				mapResponse((r) => plainToInstance(StudentSchoolSearchListModel, r), {
					errorCode: '7D287ED9',
				}),
			);
	};
}
