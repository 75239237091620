import { OperatorFunction, pipe } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// eslint-disable-next-line no-restricted-imports
import { HTTP_NO_PERMISSION } from 'src/lib/constants/constants';
// eslint-disable-next-line no-restricted-imports
import { isArray, isNullOrEmptyString, isObject } from '../compare';

export enum DefaultErrorMessage {
	Loading,
	Saving,
}

export type MapResponseErrors = string[];

export function mapResponse<T, R>(
	project: (value: T, index: number) => R,
	{
		errorMessage = DefaultErrorMessage.Loading,
		errorCode = '',
		validateSuccess = true,
		checkPreMap = true,
		checkPermission = false,
	}: {
		errorMessage?: DefaultErrorMessage | string;
		errorCode?: string;
		validateSuccess?: boolean;
		checkPreMap?: boolean;
		checkPermission?: boolean;
	} = {},
): OperatorFunction<T, R> {
	if (typeof project !== 'function') {
		throw new TypeError('mapResponse argument is not a function?');
	}

	// eslint-disable-next-line no-invalid-this, @typescript-eslint/no-this-alias
	const that = this;
	const getCode = () => {
		return isNullOrEmptyString(errorCode) ? '' : `(Error Code: ${errorCode})`;
	};

	switch (errorMessage) {
		case DefaultErrorMessage.Loading: {
			errorMessage = 'There was an issue loading data';
			break;
		}
		case DefaultErrorMessage.Saving: {
			errorMessage = 'There was an issue saving';
			break;
		}
	}

	let count = 0;
	errorMessage = `${errorMessage} ${getCode()}`.trim();

	return pipe(
		catchError((error) => {
			if (checkPermission && error.status === 403) {
				// eslint-disable-next-line no-throw-literal
				throw [HTTP_NO_PERMISSION];
			} else {
				throw gatherErrors(error, errorMessage as string);
			}
		}),
		map(function (value: T) {
			if (checkPreMap) {
				if (value != null && isObject(value) && 'success' in value) {
					if (value.success === false) {
						throw gatherErrors(value, errorMessage as string);
					}
				}
			}

			let result: R;
			try {
				result = project.call(that, value, count++);
			} catch {
				// eslint-disable-next-line no-throw-literal
				throw [];
			}

			if (
				result != null &&
				isObject(result) &&
				'success' in result &&
				validateSuccess
			) {
				if (result.success === false) {
					throw gatherErrors(result, errorMessage as string);
				}
			}
			return result;
		}),
	);
}

function gatherErrors(
	value: object,
	customErrorMsg: string,
): MapResponseErrors {
	let errs: unknown;

	if (value != null) {
		if ('error_messages' in value) {
			errs = value.error_messages;
		} else if ('errors' in value) {
			errs = value.errors;
		} else if ('error' in value) {
			if (value.error != null && isObject(value.error)) {
				if ('error_messages' in value.error) {
					errs = value.error.error_messages;
				} else if ('error' in value.error) {
					errs = value.error.error;
				} else if ('message' in value.error) {
					errs = value.error.message;
				}
			}
		}
	}

	let retErrors: string[] = isArray(errs) ? errs.map((e) => String(e)) : [];

	retErrors = retErrors.flat(20);
	retErrors.unshift(customErrorMsg);
	return retErrors;
}
