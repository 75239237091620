import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import { PermissionByRoleModel } from './permission-by-role.model';
import { PermissionEditOptionsModel } from './permission-edit-options.model';
import { PermissionEditArgument } from './permission-edit.argument';
import { PermissionHistoryResponseModel } from './permission-history.model';
import {
	PermissionOverrideType,
	PermissionProgramOverrideModel,
} from './permission-program-override.model';
import {
	PermissionRoleHistoryResponseModel,
	PermissionRoleHistorySearchArgument,
} from './permission-role-history.model';
import {
	PermissionUserRolesArgument,
	PermissionUserRolesModel,
	PermissionUserRolesResponseModel,
	PermissionUserSearchArgument,
} from './permission-user-roles.model';

@Injectable({
	providedIn: 'root',
})
export class PermissionManagerService {
	constructor(private httpClient: HttpClient) {}

	public getEditOptions = (): Observable<PermissionEditOptionsModel> => {
		return this.httpClient.get<unknown>(`/api/v1/permissions/edit`).pipe(
			mapResponse((r) => plainToInstance(PermissionEditOptionsModel, r), {
				errorCode: '48C2FF42',
			}),
		);
	};

	public getPermissionsByRole = (): Observable<PermissionByRoleModel[]> => {
		return this.httpClient
			.get<{
				roles: unknown[];
			}>(`/api/v1/permissions/role`)
			.pipe(
				mapResponse((r) => plainToInstance(PermissionByRoleModel, r.roles), {
					errorCode: '5119365C',
				}),
			);
	};

	public permissionEdit = (
		args: PermissionEditArgument,
	): Observable<{ success: boolean; error_messages?: string[] }> => {
		return this.httpClient
			.post<{
				success: boolean;
				error_messages?: string[];
			}>(`/api/v1/permissions/edit`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '7ED603E5',
					checkPreMap: false,
					validateSuccess: false,
				}),
			);
	};

	public getProgramOverrides = (
		type: PermissionOverrideType,
	): Observable<PermissionProgramOverrideModel[]> => {
		return this.httpClient
			.get<{
				institute_overrides?: unknown[];
				itype_overrides?: unknown[];
			}>(`/api/v1/permissions/overrides?shown=${type}`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							PermissionProgramOverrideModel,
							type === PermissionOverrideType.institute
								? r.institute_overrides
								: r.itype_overrides,
						),
					{
						errorCode: 'F84B0B0F',
					},
				),
			);
	};

	public getPermissionHistory = (
		args: PagingArgument<unknown>,
	): Observable<PermissionHistoryResponseModel> => {
		return this.httpClient
			.post<unknown>(`/api/v1/permissions/edit/history`, args)
			.pipe(
				mapResponse((r) => plainToInstance(PermissionHistoryResponseModel, r), {
					errorCode: '7CD751B5',
				}),
			);
	};

	public getUserRoles = (
		args: PagingArgument<PermissionUserSearchArgument>,
	): Observable<PermissionUserRolesResponseModel> => {
		return this.httpClient
			.post<unknown>(`/api/v1/permissions/users`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(PermissionUserRolesResponseModel, r),
					{
						errorCode: 'F84B0B0F',
					},
				),
			);
	};

	public editUserRoles = (
		uid: number,
		args: Partial<PermissionUserRolesArgument>,
	): Observable<PermissionUserRolesModel> => {
		return this.httpClient
			.post<{
				user: unknown;
			}>(`/api/v1/permissions/users/${uid}`, args)
			.pipe(
				mapResponse((r) => plainToInstance(PermissionUserRolesModel, r.user), {
					errorCode: 'F84B0B0F',
				}),
			);
	};

	public getRoleHistory = (
		args: PagingArgument<PermissionRoleHistorySearchArgument>,
	): Observable<PermissionRoleHistoryResponseModel> => {
		return this.httpClient
			.post<unknown>(`/api/v1/tracker/user_role_history`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(PermissionRoleHistoryResponseModel, r),
					{
						errorCode: 'C8C10929',
					},
				),
			);
	};
}
