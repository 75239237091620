import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable, tap } from 'rxjs';
import spacetime from 'spacetime';
import { StudentInformationModel } from 'src/lib/services/api/students/student-information.model';
import { StudentLinksModel } from 'src/lib/services/api/students/student-links.model';
import {
	HttpCallCache,
	buildFormData,
	buildQueryString,
} from 'src/lib/utilities/api/http';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import {
	StudentAssignmentsArgument,
	StudentAssignmentsModel,
} from './student-assignments.model';
import {
	StudentDailyAssignmentCountArgument,
	StudentDailyAssignmentCountModel,
} from './student-daily-assignments-count.model';
import {
	CustomActivationRequirementsModel,
	StudentEnrollmentActivationItemResponseModel,
} from './student-enrollment-activation-item.model';
import { StudentEnrollmentChecklistItemModel } from './student-enrollment-checklist-item.model';
import {
	StudentEnrollmentPaperworkRequestArgs,
	StudentEnrollmentPaperworkStatusModel,
} from './student-enrollment-paperwork-request.model';
import {
	EnrollmentApprovalRequestArgument,
	StudentEnrollmentRequestStatusModel,
} from './student-enrollment-request-status.model';
import { StudentInformationUpdateOptionsModel } from './student-information-update-options.model';
import {
	StudentInformationArgument,
	StudentInformationUpdateResponseModel,
} from './student-information.argument';
import { StudentLearningPlanNotesModel } from './student-learning-plan-notes.model';
import { StudentOverviewModel } from './student-overview.model';
import { StudentPerformanceExpectationModel } from './student-performance-expectation.model';
import {
	StudentProgramContact,
	StudentProgramContactModel,
	StudentProgramTechSupportModel,
	StudentProgramTutorSupportModel,
} from './student-program-contact.model';
import { StudentStarAssessmentResponseModel } from './student-star-assessment.model';
import { StudentTelehealthArgument } from './student-telehealth.argument';
import { StudentTelehealthModel } from './student-telehealth.model';
import {
	StudentArgument,
	StudentFilesArgument,
	StudentResponseMap,
	StudentResponseModel,
} from './student.argument';
import { StudentModel } from './student.model';

@Injectable({
	providedIn: 'root',
})
export class StudentsService {
	constructor(private httpClient: HttpClient) {}

	private httpCallCache_getStudentOverview = new HttpCallCache<
		number,
		StudentOverviewModel
	>();

	public getStudentOverview = (
		uid: number,
		force: boolean = false,
	): Observable<StudentOverviewModel> => {
		if (force) this.httpCallCache_getStudentOverview.flushAll();

		return this.httpCallCache_getStudentOverview.fetch$(
			uid,
			this.httpClient
				.get<StudentOverviewModel>(`/api/v1/students/${uid}/overview`)
				.pipe(
					mapResponse((r) => plainToInstance(StudentOverviewModel, r), {
						errorCode: 'B41FF798',
					}),
				),
		);
	};

	public updateStudent = (
		uid: number,
		args: StudentArgument,
		files: StudentFilesArgument,
	): Observable<StudentResponseModel> => {
		const bodyArgs = {};
		bodyArgs['jsonBody'] = args;

		Object.keys(files).forEach((key) => {
			if (files[key] != null) {
				bodyArgs[key] = files[key]['file'] ?? files[key];
			}
			const isWaived = files[key] ? files[key]['waived'] : null;
			if (isWaived) {
				bodyArgs['jsonBody'][`${key}_comment`] = files[key]['comment'];
				bodyArgs['jsonBody'][`${key}_waived`] = isWaived;
			}
		});

		const formData = buildFormData(bodyArgs);
		return this.httpClient
			.post<unknown>(`/api/v1/students/${uid}/update`, formData, {
				headers: new HttpHeaders({ enctype: 'multipart/form-data' }),
			})
			.pipe(
				mapResponse((r) => plainToInstance(StudentResponseModel, r), {
					errorCode: '233962EB',
					checkPreMap: false,
					validateSuccess: false,
				}),
			);
	};

	private httpCallCache_getStudent = new HttpCallCache<number, StudentModel>();

	public getStudent = (
		uid: number,
		force: boolean = false,
	): Observable<StudentModel> => {
		if (force) this.httpCallCache_getStudent.flushAll();

		return this.httpCallCache_getStudent.fetch$(
			uid,
			this.httpClient.get(`/api/v1/students/${uid}`).pipe(
				mapResponse((r) => plainToInstance(StudentModel, r), {
					errorCode: '74688980',
					checkPreMap: false,
					validateSuccess: false,
				}),
				tap((x) => {
					if (x?.cohort_year === 0) {
						x.cohort_year = null;
					}

					if ((x?.graduation_plan as unknown) === 0) {
						x.graduation_plan = null;
					}
				}),
			),
		);
	};

	public updateStudents = (
		args: StudentArgument[],
	): Observable<StudentResponseMap> => {
		return this.httpClient.post(`/api/v1/students/update`, args).pipe(
			mapResponse((r) => plainToInstance(StudentResponseMap, r), {
				errorCode: 'F1CD3E1F',
				checkPreMap: false,
				validateSuccess: false,
			}),
		);
	};

	public getLinks = (studentId: number): Observable<StudentLinksModel[]> => {
		return this.httpClient
			.get<StudentLinksModel[]>(`/api/v1/students/${studentId}/links`)
			.pipe(
				mapResponse((r) => plainToInstance(StudentLinksModel, r), {
					errorCode: '04092197',
					checkPreMap: false,
					validateSuccess: false,
				}),
			);
	};

	public getPasswordOptions = (studentId: number): Observable<string[]> => {
		return this.httpClient
			.get<string[]>(`/api/v1/students/${studentId}/password`)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '8250A5E4',
					checkPreMap: false,
					validateSuccess: false,
				}),
			);
	};

	public updatePassword = (
		studentId: number,
		password?: string,
		newAccountEmail?: string,
		systems?: string[],
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(`/api/v1/students/${studentId}/password`, {
				password: password,
				systems: systems,
				primary_email: newAccountEmail,
			})
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'ECD88EB3',
				}),
			);
	};

	public getStudentInformation = (
		uid: number,
	): Observable<StudentInformationModel> => {
		return this.httpClient
			.get<{
				student_information: unknown;
			}>(`/api/v1/students/${uid}/student_information`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(StudentInformationModel, r.student_information),
					{
						errorCode: '7B80F3EA',
						checkPreMap: false,
						validateSuccess: false,
					},
				),
			);
	};

	public getStudentInformationUpdateOptions = (
		studentId: number,
	): Observable<StudentInformationUpdateOptionsModel> => {
		return this.httpClient
			.get<{
				student_information_options: unknown;
			}>(`api/v1/students/${studentId}/student_information/options`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							StudentInformationUpdateOptionsModel,
							r.student_information_options,
						),
					{
						errorCode: 'D5457904',
						checkPreMap: false,
						validateSuccess: false,
					},
				),
			);
	};

	public updateStudentInformation = (
		studentId: number,
		args: StudentInformationArgument,
	): Observable<StudentInformationUpdateResponseModel> => {
		return this.httpClient
			.post<unknown>(`api/v1/students/${studentId}/student_information`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentInformationUpdateResponseModel, r),
					{ errorCode: 'D00C7C4F' },
				),
			);
	};

	public getStarAssessments = (
		studentId: number,
		args: { skip: number; take: number },
	): Observable<StudentStarAssessmentResponseModel> => {
		return this.httpClient
			.post<unknown>(`api/v1/students/${studentId}/star_assessments`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentStarAssessmentResponseModel, r),
					{ errorCode: '017D4505' },
				),
			);
	};

	public getStudentEnrollmentChecklist = (
		studentId: number,
	): Observable<StudentEnrollmentChecklistItemModel[]> => {
		return this.httpClient
			.get<{
				enrollment_checklist_items: unknown[];
			}>(`api/v1/students/${studentId}/requirements/enrollment_checklist`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							StudentEnrollmentChecklistItemModel,
							r.enrollment_checklist_items,
						),
					{ errorCode: '82BC32EA' },
				),
			);
	};

	public getStudentActivationRequirements = (
		studentId: number,
	): Observable<StudentEnrollmentActivationItemResponseModel> => {
		return this.httpClient
			.get<unknown>(`api/v1/students/${studentId}/requirements/activation`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(StudentEnrollmentActivationItemResponseModel, r),
					{ errorCode: '13F68C76' },
				),
			);
	};

	public getCustomActivationRequirements = (
		studentId: number,
	): Observable<CustomActivationRequirementsModel> => {
		return this.httpClient
			.get<unknown>(`/api/v1/students/${studentId}/requirements/custom`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(CustomActivationRequirementsModel, r),
					{
						errorCode: 'E69D5B7B',
					},
				),
			);
	};

	public completeCustomActivationRequirement = (
		studentId: number,
		id: number,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(
				`api/v1/students/${studentId}/requirements/custom/activation`,
				{
					req_id: [id],
				},
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '16E24DFA',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public manualStudentActivation = (
		studentId: number,
		date: string,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(`api/v1/students/${studentId}/requirements/activation`, {
				date: date,
			})
			.pipe(
				mapResponse((r) => r, {
					errorCode: '13F68C76',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public removeStudentActivation = (
		studentId: number,
		reason: string,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(`api/v1/student/${studentId}/activate/remove-activation`, {
				reason: reason,
			})
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'F317615F',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public addManualActivation = (
		studentId: number,
		reason: string,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(
				`api/v1/student/${studentId}/activate/add-manual-activation-requirement`,
				{
					reason: reason,
				},
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '146012D3',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public getPaperworkStatus = (
		studentId: number,
		removeManuallyUploadedRequirements: boolean,
		includeAlreadyRequestedRequirements: boolean,
		targetProgramId?: number,
	): Observable<StudentEnrollmentPaperworkStatusModel> => {
		const qs = buildQueryString({
			into_institute: targetProgramId,
			remove_manually_updated: removeManuallyUploadedRequirements,
			include_already_assigned: includeAlreadyRequestedRequirements,
		});

		return this.httpClient
			.get<unknown>(
				`/api/v1/students/${studentId}/paperwork/required/status${qs}`,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentEnrollmentPaperworkStatusModel, r),
					{
						errorCode: '3DB52057',
						checkPreMap: false,
						validateSuccess: false,
					},
				),
			);
	};

	public paperworkRequest = (
		studentId: number,
		requestArgs: StudentEnrollmentPaperworkRequestArgs,
		runQueue: boolean = false, //setting to true runs the queue directly skipping the queue
		into_institute?: number, //used for sending paperwork from a different program than the one the student is in
	): Observable<unknown> => {
		const qs = buildQueryString({
			run_queue: runQueue,
			into_institute: into_institute,
		});
		return this.httpClient
			.post<unknown>(
				`/api/v1/students/${studentId}/paperwork/request${qs}`,
				requestArgs,
			)
			.pipe(mapResponse((r) => r, { errorCode: '7D96919E' }));
	};

	public getPaperworkRequestStatus = (
		uid: number,
		run_queue: boolean = true,
	): Observable<StudentEnrollmentPaperworkStatusModel> => {
		const qs = buildQueryString({ run_queue });
		return this.httpClient
			.get<unknown>(`/api/v1/students/${uid}/paperwork/request_status${qs}`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentEnrollmentPaperworkStatusModel, r),
					{ errorCode: '64D0EB52' },
				),
			);
	};

	public cancelPaperworkRequest = (
		studentId: number,
		paperworkId: number,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(`/api/v1/students/${studentId}/paperwork/cancel`, {
				paperwork_request_id: paperworkId,
			})
			.pipe(mapResponse((r) => r, { errorCode: '5390FC73' }));
	};

	public getEnrollmentApprovalRequestStatus = (
		uid: number,
		intoInstituteId: number,
	): Observable<StudentEnrollmentRequestStatusModel> => {
		const qs = buildQueryString({
			into_institute: intoInstituteId,
		});
		return this.httpClient
			.get<unknown>(`/api/v1/enrollment_approval/user/${uid}/status${qs}`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentEnrollmentRequestStatusModel, r),
					{ errorCode: '8AFF1BB9', checkPreMap: false, validateSuccess: false },
				),
			);
	};

	public createEnrollmentApprovalRequest = (
		studentReferenceId: string,
		args: EnrollmentApprovalRequestArgument,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(
				`/api/v1/enrollment_approval/${studentReferenceId}/create`,
				args,
			)
			.pipe(mapResponse((r) => r, { errorCode: 'CE2B96B5' }));
	};

	public getTelehealth = (
		studentUid: number,
	): Observable<StudentTelehealthModel> => {
		return this.httpClient
			.get<unknown>(`/api/v1/students/${studentUid}/telehealth`)
			.pipe(
				mapResponse((r) => plainToInstance(StudentTelehealthModel, r), {
					errorCode: 'D23E4E47',
					checkPreMap: false,
					validateSuccess: false,
				}),
			);
	};

	public updateTelehealth = (
		studentUid: number,
		args: StudentTelehealthArgument,
	): Observable<StudentTelehealthModel> => {
		return this.httpClient
			.post<unknown>(`/api/v1/students/${studentUid}/telehealth`, args)
			.pipe(
				mapResponse((r) => plainToInstance(StudentTelehealthModel, r), {
					errorCode: '3EB580CC',
					checkPreMap: false,
					validateSuccess: false,
				}),
			);
	};

	public getProgramContacts = (
		studentUid: number,
	): Observable<StudentProgramContact[]> => {
		return this.httpClient
			.get<
				{
					relation: string;
				}[]
			>(`/api/v1/students/${studentUid}/program/contacts`)
			.pipe(
				mapResponse(
					(r) => {
						return r.map((rr) => {
							if (rr.relation === 'Tech Support') {
								return plainToInstance(StudentProgramTechSupportModel, rr);
							} else if (rr.relation === 'Varsity Tutors') {
								return plainToInstance(StudentProgramTutorSupportModel, rr);
							} else {
								return plainToInstance(StudentProgramContactModel, rr);
							}
						});
					},
					{
						errorCode: '01D1E992',
					},
				),
			);
	};

	public getPerformanceExpectations = (
		studentUid: number,
	): Observable<StudentPerformanceExpectationModel> => {
		return this.httpClient
			.get<unknown>(`/api/v1/students/${studentUid}/performance/expectations`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentPerformanceExpectationModel, r),
					{
						errorCode: 'a08a02b6',
					},
				),
			);
	};

	public getSubmittedAssignments = (
		studentId: number,
		startDate: Date,
		endDate: Date,
	): Observable<StudentAssignmentsModel[]> => {
		const args: StudentAssignmentsArgument = {
			start_date: spacetime(startDate)
				.format(`{year}-{iso-month}-{date-pad}`)
				.toString(),
			end_date: spacetime(endDate)
				.format(`{year}-{iso-month}-{date-pad}`)
				.toString(),
		};

		return this.httpClient
			.post<
				StudentAssignmentsModel[]
			>(`api/v1/students/${studentId}/assignments`, args)
			.pipe(
				mapResponse((r) => plainToInstance(StudentAssignmentsModel, r), {
					errorCode: '08C0BD18',
				}),
			);
	};

	public getLearningPlanNotes = (
		uid: number,
	): Observable<StudentLearningPlanNotesModel> => {
		return this.httpClient
			.get<unknown>(`/api/v1/students/${uid}/learning_plan/notes`)
			.pipe(
				mapResponse((r) => plainToInstance(StudentLearningPlanNotesModel, r), {
					errorCode: 'DE56A8F3',
				}),
			);
	};

	public dailyAssignmentCount = (
		args: StudentDailyAssignmentCountArgument,
	): Observable<StudentDailyAssignmentCountModel[]> => {
		const queryString = buildQueryString({
			from: args.from,
			to: args.to,
			student_uids: args.student_uids.join(','),
		});

		return this.httpClient
			.get<{
				daily_assignment_count: unknown[];
			}>(`/api/v1/students/daily_assignment_count${queryString}`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							StudentDailyAssignmentCountModel,
							r.daily_assignment_count,
						),
					{
						errorCode: '9F1F2F1B',
					},
				),
			);
	};
}
