import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { buildFormData } from 'src/lib/utilities/api/http';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import {
	StudentCreditVerificationArgument,
	StudentCreditVerificationResponseModel,
} from './student-credit-verification-response.model';

@Injectable({
	providedIn: 'root',
})
export class StudentCreditVerificationService {
	constructor(private httpClient: HttpClient) {}

	public getCreditVerification = (
		uid: number,
	): Observable<StudentCreditVerificationResponseModel> => {
		return this.httpClient
			.get<unknown>(`/api/v1/students/${uid}/credit_verification`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentCreditVerificationResponseModel, r),
					{ errorCode: '34A8E0E6' },
				),
			);
	};

	public updateCreditVerification = (
		uid: number,
		args: StudentCreditVerificationArgument,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(`/api/v1/students/${uid}/credit_verification`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '50F0474E',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public uploadCreditVerificationFile = (
		uid: number,
		file: File,
	): Observable<unknown> => {
		const formData = buildFormData({
			file: file,
		});

		return this.httpClient
			.post<unknown>(
				`/api/v1/students/${uid}/credit_verification/file`,
				formData,
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '85CB1D80',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};
}
