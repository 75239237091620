import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { buildQueryString } from 'src/lib/utilities/api/http';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import {
	CustomGroupOptionsModel,
	CustomGroupShareOptions,
} from './custom-group-options.model';
import {
	CustomGroupArgument,
	CustomGroupUpdateOwnerArgument,
} from './custom-group.argument';
import { CustomGroupModel } from './custom-group.model';
import {
	GroupInstitutesSearchModel,
	GroupMembersSearchModel,
} from './group-members-search.model';
import { GroupsSearchArgument } from './group-search.argument';
import { GroupUpdateModel } from './group-update.model';
import { GroupModel } from './group.model';

@Injectable({
	providedIn: 'root',
})
export class GroupsService {
	constructor(private httpClient: HttpClient) {}

	public getGroups = (
		groupType?: 'user' | 'institute',
	): Observable<GroupModel[]> => {
		const qa = buildQueryString({
			group_type_shown: groupType,
		});

		return this.httpClient.get<GroupModel[]>(`/api/v1/groups${qa}`).pipe(
			mapResponse((r) => plainToInstance(GroupModel, r), {
				errorCode: 'CA7E1A37',
			}),
		);
	};

	public getInstituteGroups = (orgId: number): Observable<GroupModel[]> => {
		return this.httpClient
			.get<{ groups: unknown[] }>(`/api/v1/groups/institute/${orgId}`)
			.pipe(
				mapResponse((r) => plainToInstance(GroupModel, r.groups), {
					errorCode: '057CCFE8',
				}),
			);
	};

	public getCustomGroups = (
		showAll?: boolean,
	): Observable<CustomGroupModel[]> => {
		const qs = buildQueryString({
			show_all: showAll || undefined,
		});
		return this.httpClient
			.get<CustomGroupModel[]>(`/api/v1/groups/custom/list${qs}`)
			.pipe(
				mapResponse((r) => plainToInstance(CustomGroupModel, r), {
					errorCode: 'F2948809',
				}),
			);
	};

	public getCustomGroup = (id: string): Observable<CustomGroupModel> => {
		return this.httpClient
			.get<CustomGroupModel>(`/api/v1/groups/custom/${id}`)
			.pipe(
				mapResponse((r) => plainToInstance(CustomGroupModel, r), {
					errorCode: 'CF203272',
					checkPreMap: false,
					validateSuccess: false,
				}),
			);
	};

	public getCustomGroupOptions = (): Observable<CustomGroupOptionsModel> => {
		return this.httpClient
			.get<CustomGroupOptionsModel>(`/api/v1/groups/custom/options_list`)
			.pipe(
				mapResponse((r) => plainToInstance(CustomGroupOptionsModel, r), {
					errorCode: '521D709C',
				}),
			);
	};

	public getCustomGroupsShareOptions =
		(): Observable<CustomGroupShareOptions> => {
			return this.httpClient.get<CustomGroupShareOptions>(
				`/api/v1/groups/custom/share_with_options`,
			);
		};

	public createCustomGroup = (
		args: CustomGroupArgument,
	): Observable<GroupUpdateModel> => {
		return this.httpClient
			.post<unknown>(`/api/v1/groups/custom/create`, args)
			.pipe(
				mapResponse((r) => plainToInstance(GroupUpdateModel, r), {
					errorCode: 'ED014E4C',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public editCustomGroup = (
		args: CustomGroupArgument,
		id: string,
	): Observable<GroupUpdateModel> => {
		return this.httpClient
			.post<unknown>(`/api/v1/groups/custom/${id}/edit`, args)
			.pipe(
				mapResponse((r) => plainToInstance(GroupUpdateModel, r), {
					errorCode: 'ED014E4C',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	// same call as above but with different argument
	public updateCustomGroupOwner = (
		args: CustomGroupUpdateOwnerArgument,
		id: string,
	): Observable<GroupUpdateModel> => {
		return this.httpClient
			.post<unknown>(`/api/v1/groups/custom/${id}/edit`, args)
			.pipe(
				mapResponse((r) => plainToInstance(GroupUpdateModel, r), {
					errorCode: 'B688AD6A',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};
	public deleteCustomGroup = (group: string): Observable<unknown> => {
		return this.httpClient
			.get<unknown>(`/api/v1/groups/custom/${group}/delete`)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '354738BD',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public searchGroupMembers = (
		args: PagingArgument<GroupsSearchArgument>,
	): Observable<GroupMembersSearchModel> => {
		return this.httpClient.post<unknown>(`/api/v1/groups/members`, args).pipe(
			mapResponse((r) => plainToInstance(GroupMembersSearchModel, r), {
				errorCode: 'E5254380',
			}),
		);
	};

	public searchGroupInstitutes = (
		args: PagingArgument<GroupsSearchArgument>,
	): Observable<GroupInstitutesSearchModel> => {
		return this.httpClient
			.post<unknown>(`/api/v1/groups/institute_members`, args)
			.pipe(
				mapResponse((r) => plainToInstance(GroupInstitutesSearchModel, r), {
					errorCode: '9B7D91E2',
				}),
			);
	};
}
