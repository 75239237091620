/* eslint-disable @typescript-eslint/no-explicit-any */

import fuzzysort from 'fuzzysort';
import { isArray } from './compare';

export type SearchResult<T> = T extends string
	? {
			match: boolean;
			highlights?: (open?: string, close?: string) => string;
		}
	: {
			match: boolean;
			highlights?: (open?: string, close?: string) => { [K in keyof T]: T[K] };
		};

type StringKeys<T> = {
	[K in keyof T]: T[K] extends string ? K : never;
}[keyof T];

export function singleSearch<T>(parameters: {
	search: string;
	target: T;
	fuzzyThreshold: number;
	keys?: readonly StringKeys<T>[];
}): SearchResult<T> {
	const { target, fuzzyThreshold } = parameters;
	let { search, keys } = parameters;

	if (search == null || search === '') {
		return { match: false } as SearchResult<T>;
	}

	if (keys == null && isArray(target)) {
		keys = target.map((_, i) => i) as any;
	}

	if (keys == null && typeof target !== 'string') {
		throw new Error(
			'singleSearch.keys must be provided for non-string targets',
		);
	}

	const fuzzyResults = fuzzysort.go(search, [target], {
		keys: keys as any,
		threshold: fuzzyThreshold,
	});

	// If no fuzzy results found, try looking for substring matches
	if (fuzzyResults.length === 0) {
		search = search.toLowerCase();

		if (typeof target === 'string') {
			const index =
				typeof target === 'string' ? target.toLowerCase().indexOf(search) : -1;
			if (index === -1) {
				return { match: false } as SearchResult<T>;
			} else {
				return {
					match: true,
					highlights: indexHighlight.bind(null, target, [index], search.length),
				} as SearchResult<T>;
			}
		} else {
			const indexes = keys.map((key) => {
				return typeof target[key] === 'string'
					? (target[key] as string).toLowerCase().indexOf(search)
					: -1;
			});
			if (indexes.every((index) => index === -1)) {
				return { match: false } as SearchResult<T>;
			} else {
				return {
					match: true,
					highlights: indexHighlight.bind(
						null,
						target,
						keys,
						indexes,
						search.length,
					),
				} as SearchResult<T>;
			}
		}
	} else {
		return {
			match: true,
			highlights: fuzzyHighlight.bind(null, target, keys, fuzzyResults[0]),
		} as SearchResult<T>;
	}
}

function highlightString(
	val: string,
	index: number,
	length: number,
	open: string,
	close: string,
): string {
	return (
		val.slice(0, index) +
		open +
		val.slice(index, index + length) +
		close +
		val.slice(index + length)
	);
}

function indexHighlight<T>(
	target: T,
	keys: readonly StringKeys<T>[],
	indexes: number[],
	length: number,
	open: string = '<b>',
	close: string = '</b>',
): string | { [K in keyof T]: T[K] } {
	if (typeof target === 'string') {
		return highlightString(target, indexes[0], length, open, close);
	}

	const retObj = {} as { [K in keyof T]: T[K] };
	for (let i = 0; i < keys.length; i++) {
		const key = keys[i];
		const value = target[key];
		if (indexes[i] === -1) {
			retObj[key] = value;
		} else if (typeof value === 'string') {
			retObj[key] = highlightString(
				value,
				indexes[i],
				length,
				open,
				close,
			) as T[typeof key];
		} else {
			retObj[key] = value;
		}
	}

	return retObj;
}

function fuzzyHighlight<T>(
	target: T,
	keys: readonly StringKeys<T>[],
	fuzzyResult: Fuzzysort.KeysResult<T>,
	open: string = '<b>',
	close: string = '</b>',
): string | { [K in keyof T]: T[K] } {
	if (typeof target === 'string') {
		return (fuzzyResult as any as Fuzzysort.KeyResult<T>).highlight(
			open,
			close,
		);
	}

	const retObj = {} as { [K in keyof T]: T[K] };
	for (let i = 0; i < keys.length; i++) {
		retObj[keys[i]] =
			fuzzyResult[i].target !== ''
				? (fuzzyResult[i].highlight(open, close) as any)
				: target[keys[i]];
	}

	return retObj;
}
