import { Injectable, Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs';
import { GlobalListenerFuncTypes } from 'src/lib/types/globals/listeners';
import { StudentEventDetailModalService } from 'src/lib/views/common/student/student-event/student-event-detail-modal/student-event-detail-modal.service';
import {
	GLStudentEventArgument,
	GLToastArgument,
} from './global-listener.arguments';

@Injectable({
	providedIn: 'root',
})
export abstract class AbstractGlobalListenersService {
	protected globalListeners: Partial<GlobalListenerFuncTypes>;

	protected initialized = false;

	constructor(protected injector: Injector) {
		document['globalListeners'] = {};
	}

	protected openStudentEvent = (
		args: GLStudentEventArgument,
		complete: () => void,
	): void => {
		const service = this.injector.get(StudentEventDetailModalService);
		service
			.openModal$(args.studentId, args.eventId, args.edit)
			.pipe(filter((x) => x))
			.subscribe({
				next: () => {
					complete();
				},
			});
	};

	protected closeStudentEvent = (
		args: GLStudentEventArgument,
		complete: () => void,
	): void => {
		const service = this.injector.get(StudentEventDetailModalService);
		service.closeModal(args.studentId, args.eventId);
		complete();
	};

	protected addToast = (args: GLToastArgument, complete: () => void): void => {
		const service = this.injector.get(ToastrService);
		const toast = service[args.type](args.body, args.title);
		toast.onHidden.subscribe(() => {
			complete();
		});
	};
}
