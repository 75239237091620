import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { map, Observable } from 'rxjs';
import { PlannedMaintenanceModel } from './planned-maintenance.model';

@Injectable({
	providedIn: 'root',
})
export class PlannedMaintenanceService {
	constructor(private httpClient: HttpClient) {}

	public viewMaintenance = (): Observable<PlannedMaintenanceModel[]> => {
		return this.httpClient
			.get<{ planned_maintenance: unknown[] }>(`/api/v1/planned_maintenance`)
			.pipe(
				map((r) =>
					plainToInstance(PlannedMaintenanceModel, r.planned_maintenance),
				),
			);
	};
}
