import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import { MeetupManagementOptionsModel } from './meetup-management-options.model';
import { MeetupManagementUserModel } from './meetup-management-user.model';
import {
	FutureMeetupEditArgument,
	MeetupAttendeeArgument,
	MeetupAttendingArgument,
	MeetupListSearchArgument,
	MeetupManagementAddArgument,
	MeetupRecurringAttendingArgument,
	ScheduledMeetupEditArgument,
} from './meetup-management.argument';
import {
	MeetupAttendanceModel,
	MeetupAttendeeModel,
	MeetupManagementItemModel,
	MeetupManagementListItemModel,
	MeetupManagementViewItemModel,
	MeetupPlannedAttendeeModel,
} from './meetup-management.model';

@Injectable({
	providedIn: 'root',
})
export class MeetupManagementService {
	constructor(private httpClient: HttpClient) {}

	public getScheduledMeetup = (): Observable<MeetupManagementItemModel> => {
		return this.httpClient
			.get<{
				scheduled_meetup: unknown;
			}>(`/api/v1/meetups/scheduled`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(MeetupManagementItemModel, r.scheduled_meetup),
					{
						errorCode: 'FE72ADF7',
					},
				),
			);
	};

	public createScheduledMeetup = (
		args: MeetupManagementAddArgument,
	): Observable<MeetupManagementItemModel> => {
		return this.httpClient
			.post<{
				scheduled_meetup: unknown;
			}>(`/api/v1/meetups/scheduled/create`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(MeetupManagementItemModel, r.scheduled_meetup),
					{
						errorCode: 'BAE4E9B5',
						errorMessage: DefaultErrorMessage.Saving,
					},
				),
			);
	};

	public editScheduledMeetup = (
		meetupId: number,
		args: ScheduledMeetupEditArgument,
	): Observable<MeetupManagementItemModel> => {
		return this.httpClient
			.post<{
				scheduled_meetup: unknown;
			}>(`/api/v1/meetups/scheduled/${meetupId}`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(MeetupManagementItemModel, r.scheduled_meetup),
					{
						errorCode: '2BA63339',
						errorMessage: DefaultErrorMessage.Saving,
					},
				),
			);
	};

	public viewScheduledMeetupOptions =
		(): Observable<MeetupManagementOptionsModel> => {
			return this.httpClient
				.get<unknown>(`/api/v1/meetups/scheduled/options`)
				.pipe(
					mapResponse((r) => plainToInstance(MeetupManagementOptionsModel, r), {
						errorCode: 'E9616201',
					}),
				);
		};

	public scheduledMeetupOptionUsers = (
		search: string,
	): Observable<MeetupManagementUserModel[]> => {
		return this.httpClient
			.get<
				unknown[]
			>(`/api/v1/meetups/scheduled/options/search/${search}?take=50`)
			.pipe(
				mapResponse((r) => plainToInstance(MeetupManagementUserModel, r), {
					errorCode: 'F781DAED',
				}),
			);
	};

	public listFutureMeetups = (
		args: PagingArgument<Partial<MeetupListSearchArgument>> & {
			for_student?: number;
		},
	): Observable<MeetupManagementListItemModel[]> => {
		return this.httpClient
			.post<{
				future_meetups: unknown[];
			}>(`/api/v1/meetups/future`, args)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(MeetupManagementListItemModel, r.future_meetups),
					{
						errorCode: '47FC6445',
					},
				),
			);
	};

	public viewAttendance = (id: number): Observable<MeetupAttendanceModel> => {
		return this.httpClient
			.get<MeetupAttendanceModel>(`/api/v1/meetups/future/${id}/attend`)
			.pipe(
				mapResponse((r) => plainToInstance(MeetupAttendanceModel, r), {
					errorCode: '7ED9953A',
				}),
			);
	};

	public markAsAttending = (
		meetupId: number,
		args: Partial<MeetupAttendingArgument>,
	): Observable<MeetupPlannedAttendeeModel> => {
		return this.httpClient
			.post<{
				planned_attendee: unknown;
			}>(`/api/v1/meetups/future/${meetupId}/attend`, args)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(MeetupPlannedAttendeeModel, r.planned_attendee),
					{
						errorCode: 'E9F9C915',
						errorMessage: DefaultErrorMessage.Saving,
					},
				),
			);
	};

	public removeAttending = (meetupId: number): Observable<unknown> => {
		return this.httpClient
			.delete<unknown>(`/api/v1/meetups/future/${meetupId}/attend`)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'A4094B99',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public editAttendance = (
		meetupId: number,
		uid: number,
		args: Partial<MeetupAttendeeArgument>,
	): Observable<MeetupAttendeeModel> => {
		return this.httpClient
			.post<{
				future_meetup_attendance: unknown;
			}>(`/api/v1/meetups/future/${meetupId}/attendee/${uid}`, args)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(MeetupAttendeeModel, r.future_meetup_attendance),
					{
						errorCode: '6C0BCB7C',
						errorMessage: DefaultErrorMessage.Saving,
					},
				),
			);
	};

	public viewFutureMeetup = (
		id: number,
	): Observable<MeetupManagementViewItemModel> => {
		return this.httpClient
			.get<{
				future_meetup: unknown;
			}>(`/api/v1/meetups/future/${id}`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(MeetupManagementViewItemModel, r.future_meetup),
					{
						errorCode: '4B16228E',
					},
				),
			);
	};

	public editFutureMeetup = (
		id: number,
		args: Partial<FutureMeetupEditArgument>,
	): Observable<MeetupManagementViewItemModel> => {
		return this.httpClient
			.post<{
				future_meetup: unknown;
			}>(`/api/v1/meetups/future/${id}`, args)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(MeetupManagementViewItemModel, r.future_meetup),
					{
						errorCode: '459AE8E8',
						errorMessage: DefaultErrorMessage.Saving,
					},
				),
			);
	};

	// by student
	public markAsAttendingRecurringMeetup = (
		id: number,
		args: Partial<MeetupRecurringAttendingArgument>,
	): Observable<MeetupAttendanceModel> => {
		return this.httpClient
			.post<unknown>(`/api/v1/meetups/future/${id}/attend/recurring`, args)
			.pipe(
				mapResponse((r) => plainToInstance(MeetupAttendanceModel, r), {
					errorCode: '58841797',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	// delete by student
	public deleteRecurringMeetup = (id: number): Observable<unknown> => {
		return this.httpClient
			.delete<unknown>(`/api/v1/meetups/future/${id}/attend/recurring`)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'B1390D9A',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	// delete for student
	public deleteStudentRecurringMeetup = (
		meetupId: number,
		attendeeId: number,
	): Observable<unknown> => {
		return this.httpClient
			.delete<unknown>(
				`/api/v1/meetups/future/${meetupId}/attendee/${attendeeId}/recurring`,
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'B6538EA7',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public viewStudentAttendance = (
		meetupId: number,
		attendeeId: number,
	): Observable<MeetupAttendanceModel> => {
		return this.httpClient
			.get<unknown>(`/api/v1/meetups/future/${meetupId}/attendee/${attendeeId}`)
			.pipe(
				mapResponse((r) => plainToInstance(MeetupAttendanceModel, r), {
					errorCode: 'F39825AC',
				}),
			);
	};
}
