import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class StudentTranscriptUserModel {
	user_id: number;
	first_name: string;
	last_name: string;
}

export class StudentTranscriptCourseModel {
	id: string;
	course_name: string;
	credits: number;
	grade: string;
	grade_level: number;
	powerschool_id: string;
	errors: string[];
	metadata: {
		program_credits: number;
		grade_percent: number;
	};
	mapped_course: {
		id: number;
		type: 'subject_area' | 'area' | 'course';
		title: string;
		ai_suggestion: boolean;
		code?: string;
	};
	terms: {
		type: string;
		selections: number[];
	};
}

export class StudentTranscriptModel {
	courses: StudentTranscriptCourseModel[];
	created_by: StudentTranscriptUserModel;
	last_edited_by: StudentTranscriptUserModel;
	@Transform(dateTransformer)
	last_edited_timestamp: Date;
	metadata: {
		default_credits: number | null;
		credit_conversion_rate: number | null;
	};
	notes: string;
	school: { id: number; name: string };
	student_id: number;
	@Transform(dateTransformer)
	transcript_from_date: Date;
	transcript_id: number;
	@Transform(dateTransformer)
	transcript_to_date: Date;
	transcript_url: string;
	@Transform(dateTransformer)
	uploaded_timestamp: Date;
	verified_by: StudentTranscriptUserModel;
	@Transform(dateTransformer)
	confirm_mapping_timestamp: Date;
	@Transform(dateTransformer)
	verified_timestamp: Date;
	@Transform(dateTransformer)
	sent_to_external_mapper: Date;
	@Transform(dateTransformer)
	received_from_external_mapper_timestamp: Date;
}

export function StudentTranscriptModel_ClassTransform_Type() {
	return StudentTranscriptModel;
}

export class StudentTranscriptsResponseModel {
	@Type(StudentTranscriptModel_ClassTransform_Type)
	transcripts: StudentTranscriptModel[];
}

export class StudentTranscriptCourseArgument {
	id?: string;
	course_name: string;
	credits: number;
	grade: string;
	mapped_course: {
		type: 'area' | 'course';
		id: number;
		ai_suggestion: boolean;
	};
	grade_level?: number;
	powerschool_id?: string;
	terms?: {
		type?: 'quarter' | 'semester';
		selections?: number[];
	};
	metadata?: {
		program_credits?: number;
		grade_percent?: number;
	};
}

export class StudentTranscriptUpdateArgument {
	school_id?: number;
	transcript_from_date?: string; // yyyy-mm-dd
	transcript_to_date?: string; // yyyy-mm-dd
	notes?: string;
	metadata?: {
		default_credits?: number;
		credit_conversion_rate?: number;
	};
	courses?: StudentTranscriptCourseArgument[];
}

export class StudentTranscriptHistoryModel {
	first_name: string;
	last_name: string;
	@Transform(dateTransformer)
	timestamp: Date;
	transcript_version: string;
	type: string;
	user_id: number;
}
