import {
	AfterContentInit,
	ContentChild,
	Directive,
	ElementRef,
	Input,
	OnDestroy,
	Renderer2,
} from '@angular/core';
import {
	AbstractControl,
	FormControlDirective,
	FormControlName,
} from '@angular/forms';
import { AsyncSubject, interval, merge, of } from 'rxjs';
import {
	debounceTime,
	filter,
	switchMap,
	take,
	takeUntil,
} from 'rxjs/operators';
import { GroupValidationDisplayComponent } from './group-validation-display.component';
import { GroupValidationInlineDisplayComponent } from './group-validation-inline-display.component';

let errorIdIndex = 0;

@Directive({
	selector: 'div[aeGroupValidation]',
})
export class GroupValidationDirective implements AfterContentInit, OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();

	@Input() validationDebounce: number = 500;

	constructor(
		private render: Renderer2,
		private ele: ElementRef<HTMLElement>,
	) {}

	@ContentChild(FormControlDirective)
	formControl: FormControlDirective;
	@ContentChild(FormControlName)
	formControlName: FormControlName;
	@ContentChild(GroupValidationDisplayComponent)
	validationDisplayComponent: GroupValidationDisplayComponent;
	@ContentChild(GroupValidationInlineDisplayComponent)
	validationInlineDisplayComponent: GroupValidationInlineDisplayComponent;

	private ctrl: AbstractControl;
	private dirName: string;

	private getControlElement = () => {
		let childInput;
		if (this.ele.nativeElement.children) {
			const attrSearchChildren: HTMLElement[] = [].slice.call(
				this.ele.nativeElement.children,
			);
			// eslint-disable-next-line @typescript-eslint/prefer-for-of
			for (let i = 0; i < attrSearchChildren.length; i++) {
				const child = attrSearchChildren[i];
				if (
					child.attributes &&
					child.attributes.getNamedItem(this.dirName) != null
				) {
					childInput = childInput ? childInput : child;
					break;
				} else if (child.children) {
					attrSearchChildren.push(...[].slice.call(child.children));
				}
			}

			if (childInput == null) {
				const formClasses = [
					'custom-form-control',
					'form-control',
					'form-select',
					'form-check-input',
					'custom-control-input',
					'ng-select',
					'ae-multi-slider',
				];

				const classSearchChildren: HTMLElement[] = [].slice.call(
					this.ele.nativeElement.children,
				);
				// eslint-disable-next-line @typescript-eslint/prefer-for-of
				for (let i = 0; i < classSearchChildren.length; i++) {
					const child = classSearchChildren[i];
					if (
						formClasses.filter((fc) => child.classList.contains(fc)).length > 0
					) {
						childInput = childInput ? childInput : child;
						break;
					} else if (child.children) {
						classSearchChildren.push(...[].slice.call(child.children));
					}
				}
			}
		}
		return childInput;
	};

	private refreshFormControl = () => {
		this.dirName = this.formControl ? 'formcontrol' : 'formcontrolname';
		this.ctrl = this.formControl
			? this.formControl.control
			: this.formControlName.control;

		if (this.ctrl == null) {
			throw new Error(
				`Could not find controller using: ${this.dirName} on object.`,
			);
		}
	};

	public reattach = () => {
		if (this._unsubscribe$ == null) return;

		// Setting properties
		if (this.formControl == null && this.formControlName == null) {
			throw new Error('No FormControl and FormControlName were supplied');
		}
		if (this.formControl && this.formControlName) {
			throw new Error('Both FormControl and FormControlName were supplied');
		}
		this.refreshValidation();
		// Setting up observable
		merge(this.ctrl.statusChanges, this.ctrl.valueChanges)
			.pipe(
				debounceTime(this.validationDebounce),
				switchMap(() => {
					if (this.ctrl.touched || this.ctrl.pristine) {
						return of(null);
					} else {
						// HACK: we are breaking the law because angular does not have a touchChanges observable
						// https://github.com/angular/angular/issues/10887
						return interval(50).pipe(
							filter(() => this.ctrl.touched),
							take(1),
						);
					}
				}),
				takeUntil(this._unsubscribe$),
			)
			.subscribe(() => {
				this.refreshValidation();
			});
	};

	ngOnDestroy(): void {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}

	ngAfterContentInit(): void {
		setTimeout(() => {
			this.reattach();
		}, 5);
	}

	private refreshValidation = () => {
		const controlElement = this.getControlElement();
		this.refreshFormControl();
		if (this.ctrl.errors && this.ctrl.dirty) {
			if (this.ctrl.touched) {
				errorIdIndex++;

				if (controlElement) {
					this.render.addClass(controlElement, 'is-invalid');
					this.render.setAttribute(controlElement, 'aria-invalid', 'true');
					this.render.setAttribute(
						controlElement,
						'aria-errormessage',
						`group.validation.control-error-output.index.${errorIdIndex}`,
					);
				}
				this.render.addClass(this.ele.nativeElement, 'group-is-invalid');

				if (this.validationDisplayComponent != null) {
					this.validationDisplayComponent.hostElement.nativeElement.id = `group.validation.control-error-output.index.${errorIdIndex}`;
					this.validationDisplayComponent.displayError(
						this.ctrl.errors ? this.ctrl.errors : {},
					);
				}

				if (this.validationInlineDisplayComponent != null) {
					this.validationInlineDisplayComponent.hostElement.nativeElement.id = `group.validation.control-error-inline-output.index.${errorIdIndex}`;
					this.validationInlineDisplayComponent.displayError(
						this.ctrl.errors ? this.ctrl.errors : {},
					);
				}
			}
		} else {
			if (controlElement) {
				this.render.removeClass(controlElement, 'is-invalid');
				this.render.removeAttribute(controlElement, 'aria-invalid');
				this.render.removeAttribute(controlElement, 'aria-errormessage');
			}

			this.render.removeClass(this.ele.nativeElement, 'group-is-invalid');

			if (this.validationDisplayComponent != null) {
				this.validationDisplayComponent.displayError({});
			}

			if (this.validationInlineDisplayComponent != null) {
				this.validationInlineDisplayComponent.displayError({});
			}
		}
	};
}
