export class UniqueEmailModel {
	active: boolean;
	email_in_use: boolean;
	first_name: string;
	institute_id: number;
	last_name: string;
	maiden_name: string;
	mail: string;
	middle_name: string;
	preferred_name: string;
	program_title: string;
	role: string;
	uid: number;
	user_image: unknown;
}
