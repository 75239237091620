import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'cast',
	pure: true,
})
export class CastPipe implements PipeTransform {
	transform<S, T = S>(value: S, _type?: T): T {
		return value as unknown as T;
	}
}
