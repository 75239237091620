import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import { StudentCourseActivityResponseModel } from './student-course-activity-response.model';
import { StudentCourseActivitySearchArgument } from './student-course-activity-search.argument';

@Injectable({
	providedIn: 'root',
})
export class StudentCourseActivityService {
	constructor(private httpClient: HttpClient) {}

	public getCourseActivity = (
		studentId: number,
		args: PagingArgument<StudentCourseActivitySearchArgument>,
	): Observable<StudentCourseActivityResponseModel> => {
		return this.httpClient
			.post<unknown>(`/api/v1/students/${studentId}/course-activity`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentCourseActivityResponseModel, r),
					{ errorCode: 'F156F269' },
				),
			);
	};
}
