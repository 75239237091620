import { AsyncSubject, Observable, race, Subject, timer } from 'rxjs';
import { take } from 'rxjs/operators';
// eslint-disable-next-line no-restricted-imports
import { isArray, isObject, isString } from '../compare';

export function buildQueryString(queryObject: object): string {
	const components: string[] = [];

	if (queryObject != null) {
		for (const key in queryObject) {
			if (queryObject.hasOwnProperty(key)) {
				const currentObject = queryObject[key];

				if (
					currentObject == null ||
					(isString(currentObject) && (currentObject as string).trim() === '')
				) {
					continue;
				}

				if (isArray(currentObject)) {
					const strigifiedArray = currentObject.map((x) => `${x}`).join('%26');
					components.push(`${key}=${strigifiedArray}`);
				} else {
					components.push(`${key}=${currentObject}`);
				}
			}
		}
	}

	return joinQueryString(components);
}

export function joinQueryString(components: string[]): string {
	let queryString = '';

	if (components != null && components.length > 0) {
		queryString = `?${components.join('&')}`;
	}

	return queryString;
}

export type QueryValues = Record<string, string>;

export function parseQuery(queryString: string): QueryValues {
	const query: QueryValues = {};
	const pairStrings = (
		queryString[0] === '?' ? queryString.substring(1) : queryString
	).split('&');

	for (const pairString of pairStrings) {
		const pair = pairString.split('=');
		query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
	}

	return query;
}

export function buildFormData(input: Record<string, unknown>): FormData {
	if (input == null) throw new Error('Undefined input in buildFormData');

	const formData = new FormData();

	for (const key in input) {
		if (input.hasOwnProperty(key)) {
			let value: string | Blob;
			const test = input[key];

			if (test instanceof Blob) {
				value = test;
			} else if (isObject(test)) {
				value = JSON.stringify(test);
			} else {
				value = `${test}`;
			}

			formData.append(key, value);
		}
	}

	return formData;
}

export class HttpCallCache<Key extends string | number, Result> {
	constructor(
		private cacheTimer: number = 5000,
		private flushOnError = true,
	) {}

	private _destroy = new Subject<null>();
	private _cache = new Map<Key, AsyncSubject<Result>>();

	public fetch$(key: Key, call$: Observable<Result>): Observable<Result> {
		if (!this._cache.has(key)) {
			const subject = new AsyncSubject<Result>();
			this._cache.set(key, subject);

			const setCacheClear = () => {
				race(timer(this.cacheTimer), this._destroy)
					.pipe(take(1))
					.subscribe(() => {
						this._cache.delete(key);
					});
			};

			call$.subscribe({
				next: (s) => {
					subject.next(s);
					subject.complete();
					setCacheClear();
				},
				error: (e) => {
					subject.error(e);
					setCacheClear();

					if (this.flushOnError) {
						this.flushAll();
					}
				},
			});
		}

		return this._cache.get(key);
	}

	public flushAll = (): void => this._destroy.next(null);
}
