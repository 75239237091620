import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { buildQueryString } from 'src/lib/utilities/api/http';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { ChannelsResponse } from '../channels/channel.model';
import { GabbyUserRelationshipModel } from './gabby-user-relationship.model';
import { GabbyUserSearchArgument } from './gabby-user-search.argument';
import { GabbyUserSearchModel } from './gabby-user-search.model';
import { GabbyUserModel } from './gabby-user.model';

@Injectable({
	providedIn: 'root',
})
export class GabbyUsersService {
	constructor(private httpClient: HttpClient) {}

	public getUser = (linkId: number): Observable<GabbyUserModel> => {
		return this.httpClient
			.get<GabbyUserModel>(`/api/v1/gradchat/user/${linkId}`)
			.pipe(map((r) => plainToInstance(GabbyUserModel, r)));
	};

	public searchUsers = (
		args: GabbyUserSearchArgument,
	): Observable<GabbyUserSearchModel[]> => {
		return this.httpClient
			.post<GabbyUserSearchModel[]>(`/api/v1/gradchat/user/search`, args)
			.pipe(map((r) => plainToInstance(GabbyUserSearchModel, r)));
	};

	public getContacts = (
		userLinkId: number,
	): Observable<GabbyUserRelationshipModel[]> => {
		return this.httpClient
			.get<
				GabbyUserRelationshipModel[]
			>(`/api/v1/gradchat/user/${userLinkId}/relationships`)
			.pipe(map((r) => plainToInstance(GabbyUserRelationshipModel, r)));
	};

	public getChannels = (
		userLinkId: number,
		skip: number,
		take: number,
	): Observable<ChannelsResponse> => {
		const qs = buildQueryString({
			skip,
			take,
		});

		return this.httpClient
			.get<ChannelsResponse>(
				`/api/v1/gradchat/user/${userLinkId}/channels${qs}`,
			)
			.pipe(map((r) => plainToInstance(ChannelsResponse, r)));
	};

	public markAllRead = (userLinkId: number): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(`/api/v1/gradchat/user/${userLinkId}/mark_as_read`, {})
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'A8F3C7CC',
				}),
			);
	};
}
