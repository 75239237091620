import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { buildFormData, HttpCallCache } from 'src/lib/utilities/api/http';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { StaffDataModel } from './staff-data.model';
import { UserDataArgument } from './user-data.argument';
import { UserDataModel, UserTimezoneModel } from './user-data.model';
import { NotificationUpdateArgument } from './user-notification.argument';
import { UserNotificationSubscriptionsModel } from './user-notification.model';

@Injectable({
	providedIn: 'root',
})
export class UsersService {
	private httpCallCache_getTimezone = new HttpCallCache<
		number,
		UserTimezoneModel
	>(15 * 60 * 1000 /* 15 minutes */);

	constructor(private httpClient: HttpClient) {}

	public getUserData = (uid: number): Observable<UserDataModel> => {
		return this.httpClient
			.get<{ fields: unknown }>(`/api/v1/user/${uid}/user_data`)
			.pipe(
				mapResponse((r) => plainToInstance(UserDataModel, r.fields), {
					errorCode: '26005A6C',
					validateSuccess: false,
					checkPreMap: false,
				}),
			);
	};

	public setUserData = (
		uid: number,
		userArgs: UserDataArgument,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(`/api/v1/user/${uid}/user_data`, userArgs)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '3CEF93FA',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public getTimezone = (uid: number): Observable<UserTimezoneModel> => {
		return this.httpCallCache_getTimezone.fetch$(
			uid,
			this.httpClient.get<unknown>(`/api/v1/timezone/${uid}`).pipe(
				mapResponse((r) => plainToInstance(UserTimezoneModel, r), {
					errorCode: '617D3B9E',
					checkPreMap: false,
					validateSuccess: false,
				}),
			),
		);
	};

	public updateProfilePicture = (
		uid: number,
		profilePicture: File,
	): Observable<unknown> => {
		const bodyArgs = {};
		bodyArgs['profile_picture'] = profilePicture;
		const formData = buildFormData(bodyArgs);

		return this.httpClient
			.post<unknown>(`/api/v1/user/${uid}/profile_picture`, formData)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'A2F8689E',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	// SSO
	public getOpenIdStatus = (
		uid: number,
	): Observable<{ connect?: Record<string, string>; connection?: string }> => {
		return this.httpClient
			.get<{
				connect?: Record<string, string>;
				connection?: string;
			}>(`/api/v1/user/${uid}/openid`)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'B69BCE4F',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public disconnectOpenIdStatus = (
		uid: number,
		connectionType: string,
	): Observable<{
		success: boolean;
	}> => {
		return this.httpClient
			.post<{
				success: boolean;
			}>(`api/v1/user/${uid}/openid/${connectionType}/disconnect`, {})
			.pipe(
				mapResponse((r) => r, {
					errorCode: '6F435D69',
					errorMessage:
						'There was an error while attempting to disconnect the student SSO',
				}),
			);
	};

	public getProfileUserTabs = (uid: number): Observable<string[]> => {
		return this.httpClient
			.get<string[]>(`/api/v1/user/${uid}/tabs`)
			.pipe(mapResponse((r) => r, { errorCode: 'F43613E2' }));
	};

	// Password
	public updateUserEmailPassword = (
		uid: number,
		password: string,
		email: string,
		current_password: string,
	): Observable<{
		success: boolean;
	}> => {
		return this.httpClient
			.post<{
				success: boolean;
			}>(`/api/v1/user/${uid}/account`, {
				current_password: current_password,
				password: password,
				email: email,
			})
			.pipe(
				mapResponse((r) => r, {
					errorCode: '3D80458F',
					errorMessage: DefaultErrorMessage.Saving,
					validateSuccess: false,
					checkPreMap: false,
				}),
			);
	};

	public getStaffData = (userId: number): Observable<StaffDataModel> => {
		return this.httpClient
			.get<{ staff_information: unknown }>(`/api/v1/staff/${userId}`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StaffDataModel, r.staff_information),
					{
						errorCode: '176580f7',
						validateSuccess: false,
					},
				),
			);
	};

	public deactivateUser = (userId: number): Observable<unknown> => {
		return this.httpClient.post(`/api/v1/user/${userId}/deactivate`, {}).pipe(
			mapResponse((r) => r, {
				errorCode: 'c48b5d4d',
			}),
		);
	};

	public reactivateUser = (userId: number): Observable<unknown> => {
		return this.httpClient.post(`/api/v1/user/${userId}/reactivate`, {}).pipe(
			mapResponse((r) => r, {
				errorCode: 'd7a089ba',
			}),
		);
	};

	public getUserNotifications = (
		userId: number,
	): Observable<UserNotificationSubscriptionsModel> => {
		return this.httpClient
			.get<unknown>(`/api/v1/user/${userId}/notifications`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(UserNotificationSubscriptionsModel, r),
					{
						errorCode: '96FD362E',
						validateSuccess: false,
					},
				),
			);
	};

	public setUserNotifications = (
		userId: number,
		args: NotificationUpdateArgument,
	): Observable<UserNotificationSubscriptionsModel> => {
		return this.httpClient
			.post<unknown>(`/api/v1/user/${userId}/notifications`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(UserNotificationSubscriptionsModel, r),
					{
						errorCode: 'C90F8CC2',
					},
				),
			);
	};
}
