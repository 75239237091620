import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { buildQueryString } from 'src/lib/utilities/api/http';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { CommunicationTokenReplaceArgument } from './communication-token-replace.argument';
import { CommunicationTokenReplaceModel } from './communication-token-replace.model';
import { CommunicationTokenModel } from './communication-token.model';

@Injectable({
	providedIn: 'root',
})
export class CommunicationTokensService {
	constructor(private httpClient: HttpClient) {}

	public getTokens = (
		groups: string[],
		includeAllTokens?: boolean,
	): Observable<CommunicationTokenModel[]> => {
		const qs = buildQueryString({
			include_all_tokens: includeAllTokens || undefined,
		});
		return this.httpClient
			.post<unknown[]>(`/api/v1/tokens${qs}`, {
				groups: groups,
			})
			.pipe(
				mapResponse((r) => plainToInstance(CommunicationTokenModel, r), {
					errorCode: 'A6F5FF80',
				}),
			);
	};

	public getTokenReplacement = (
		args: CommunicationTokenReplaceArgument,
	): Observable<CommunicationTokenReplaceModel> => {
		return this.httpClient.post<unknown>(`/api/v1/tokens/replace`, args).pipe(
			mapResponse((r) => plainToInstance(CommunicationTokenReplaceModel, r), {
				errorCode: 'FDA65ED5',
			}),
		);
	};
}
