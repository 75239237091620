import { Observable, forkJoin, from, throwError } from 'rxjs';
import { isNonEmptyString } from './compare';
import { convertToString } from './convert';

export const defaultAllowedFileTypes = [
	'jpg',
	'jpeg',
	'jfif',
	'gif',
	'png',
	'txt',
	'doc',
	'docx',
	'xls',
	'pdf',
	'ppt',
	'pps',
	'odt',
	'ods',
	'odp',
];

export const resizableImageFileTypes = ['jpg', 'jpeg', 'png', 'jfif'];

// https://stackoverflow.com/a/29390393/3780285
export function blobToFile(theBlob: Blob, fileName: string): File {
	// A Blob() is almost a File() - it's just missing the two properties below which we will add
	theBlob['lastModifiedDate'] = new Date();
	theBlob['name'] = fileName;

	// Cast to a File() type
	return theBlob as File;
}

export function getFileFromPaste(e: ClipboardEvent): File | null {
	const items = e.clipboardData?.items;
	if (!items) return null;

	// find pasted image among pasted items
	let file: File = null;

	// eslint-disable-next-line @typescript-eslint/prefer-for-of
	for (let i = 0; i < items.length; i++) {
		if (items[i].type.indexOf('image') === 0) {
			file = items[i].getAsFile();
		}
	}

	return file;
}

export function getFilesFromFileList(fl: FileList): File[] {
	const files: File[] = [];

	if (fl != null) {
		for (let i = 0; i < fl.length; i++) {
			files.push(fl.item(i));
		}
	}

	return files;
}

export function getFilesFromDrop(e: DragEvent): File[] {
	// use event.originalEvent.clipboard for newer chrome versions
	return getFilesFromFileList(e.dataTransfer.files);
}

export function getFilesFromInput$(
	e: Event,
	element?: HTMLInputElement,
	fileSize?: number,
): Observable<File[]> {
	return new Observable<File[]>((o) => {
		let files = getFilesFromFileList(e.target['files'] as FileList);
		const observables: Observable<File>[] = [];
		files.forEach((file) => {
			const type = file.type.replace('image/', '');
			if (resizableImageFileTypes.includes(type) && fileSize != null) {
				if (file.size > fileSize) {
					observables.push(
						throwError(
							() => new Error(`File must be less than ${fileSize / 1000000}MB`),
						),
					);
				}
			}
		});
		if (element != null) {
			element.value = null;
		}
		if (observables.length > 0) {
			forkJoin(observables).subscribe({
				next: (images: File[]) => {
					files = files.map<File>((obj: File) =>
						images.find((img: File) => img.name === obj.name),
					);
					o.next(files);
				},
				error: (err) => {
					o.error(err);
				},
			});
		} else {
			o.next(files);
		}
	});
}

export function getFileExtension(f: File): string {
	if (f == null) return null;
	const splits = f.name.toLowerCase().split('.');
	return splits[splits.length - 1];
}

export function isFileAllowed(
	f: File,
	allowedFileTypes: string[] = null,
): boolean {
	allowedFileTypes = allowedFileTypes || defaultAllowedFileTypes;
	const ext = getFileExtension(f);
	return allowedFileTypes.map((x) => x.toLowerCase()).indexOf(ext) !== -1;
}

export function canvasToFile$(
	canvas: HTMLCanvasElement,
	name: string,
	type: string,
) {
	return new Observable<File>((o) => {
		canvas.toBlob(
			(blob) => {
				type = type.replace('image/', '');
				const f = new File([blob], name, { type: type });

				o.next(f);
				o.complete();
			},
			type,
			1,
		);
	});
}

export function convertURLToFile$(
	url: string,
	fileName?: string,
): Observable<File> {
	if (!fileName) {
		fileName = (url ?? '').split('/').pop().split('?')[0];
	}

	return from(
		fetch(url)
			.then((res) => res.blob())
			.then((blob) => {
				return new File([blob], fileName, {
					type: blob.type,
				});
			}),
	);
}

export function downloadCSV(
	headers: string[],
	content: string[][],
	fileName?: string,
) {
	let csvContent = `${headers
		.map((c) => convertToString(c || ''))
		.map((c) => `"${c.replace(/"/g, '""')}"`)
		.join(',')}\n`;
	content.forEach((x) => {
		csvContent += `${x
			.map((c) => {
				return typeof c === 'boolean'
					? c
						? 'TRUE'
						: 'FALSE'
					: convertToString(c || '');
			})
			.map((c) => `"${c.replace(/"/g, '""')}"`)
			.join(',')}\n`;
	});

	csvContent = `data:text/csv;charset=utf-8,${csvContent}`;
	const file = encodeURI(csvContent);
	const link = document.createElement('a');
	link.setAttribute('style', '{display: none}');
	link.setAttribute('href', file);
	link.setAttribute('download', `${fileName ?? 'download'}.csv`);
	document.body.appendChild(link);
	link.click();
}

export function parseCsvUploaded(
	fileLoadedEvent: ProgressEvent<FileReader>,
): string[][] {
	const textFromFileLoaded = fileLoadedEvent.target.result;
	if (isNonEmptyString(textFromFileLoaded)) {
		const rows = textFromFileLoaded.split(/\r\n|\n|\r/);
		const content = rows.map((row) => {
			const regex = /(".*?"|[^",]+)(?=\s*,|\s*$)/g;
			const columns = (row.match(regex) || []).map((column) =>
				column.replace(/^"|"$/g, ''),
			);
			return columns;
		});
		return content;
	} else {
		throw new Error('File is empty or not a valid CSV file');
	}
}
