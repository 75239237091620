export const colorPickerDefaultOptions = [
	['#b97a56', '#c3c3c3', '#585858', '#000000', '#88001b', '#ec1c24'],
	['#ff7f27', '#ffca18', '#fdeca6', '#fff200', '#c4ff0e', '#0ed145'],
	['#8cfffb', '#00a8f3', '#3f48cc', '#b83dba', '#ffaec8', '#ff1a75'],
];

export const colorPickerLightContrastOptions = [
	['#1273d3', '#1e113c', '#7349c4', '#e70202', '#f1632a'],
	['#975F3F', '#44800a', '#5191b6', '#6c757d', '#343a40'],
];
