import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { SelfEnrollmentCourseOptionModel } from './self-enrollment-course.model';
import { SelfEnrollmentArgument } from './self-enrollment.argument';

@Injectable({
	providedIn: 'root',
})
export class SelfEnrollmentService {
	constructor(private httpClient: HttpClient) {}

	public getSelfEnrollmentOptions = (
		uid: number,
	): Observable<SelfEnrollmentCourseOptionModel> => {
		return this.httpClient
			.get<unknown>(`/api/v1/self_enroll/${uid}/options`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(SelfEnrollmentCourseOptionModel, r),
					{
						errorCode: 'f5d33e0b',
						checkPermission: true,
					},
				),
			);
	};

	//setOptions
	public setSelfEnrollmentOptions = (
		studentId: number,
		args: SelfEnrollmentArgument[],
	): Observable<unknown> => {
		return this.httpClient
			.post<SelfEnrollmentCourseOptionModel>(
				`/api/v1/self_enroll/${studentId}`,
				args,
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '4e8ffd1a',
				}),
			);
	};
}
