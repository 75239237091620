import {
	Component,
	ElementRef,
	forwardRef,
	HostBinding,
	Input,
	NgZone,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	FormControl,
	FormsModule,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AsyncSubject } from 'rxjs';
import spacetime from 'spacetime';
import { isValidDate } from 'src/lib/utilities/compare';
import {
	convertDateToNgbDateStruct,
	convertToDate,
} from 'src/lib/utilities/convert';
import {
	createDateValidation,
	createMaxCalendarDateValidation,
	createMinCalendarDateValidation,
} from './validators';

@Component({
	selector: 'ae-single-date-picker',
	templateUrl: './single-date-picker.component.html',
	styleUrls: ['./single-date-picker.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SingleDatePickerComponent),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => SingleDatePickerComponent),
			multi: true,
		},
	],
	imports: [FormsModule],
})
export class SingleDatePickerComponent
	implements ControlValueAccessor, OnChanges, OnDestroy
{
	@HostBinding('class.custom-form-control') customFormControl = true;
	@HostBinding('class.validate-include-children') validateIncludeChildren =
		true;

	private _unsubscribe$ = new AsyncSubject<null>();

	@Input() placeholder: string = null;
	@Input() minDate: string | Date | null = null;
	@Input() maxDate: string | Date | null = null;
	@Input() startDate: string | Date | null = null;
	@Input() id: any = null;
	@Input() autocomplete: string = null;
	@Input() markDisabled: (
		date: NgbDateStruct,
		current: { year: number; month: number },
	) => boolean = null;
	@Input() noValidate: boolean = false;
	@Input() preferTextInput: boolean = false;

	@ViewChild('dateInput', { static: true })
	dateInput: ElementRef<HTMLInputElement>;

	private format: string = 'YYYY-MM-DD';

	private _blockAutoUpdate: boolean = false;

	private _minDate: Date;
	private _maxDate: Date;
	private _startDate: Date;
	private _verifiedValue: Date;

	public disabled: boolean = false;
	public formattedModel: string;
	protected inputType: 'date' | 'text' = 'date';

	private _touchFunction: () => void;
	private _validateFn: ((_: any) => any)[];
	private _changeFunction: (value: Date) => void = () => null;

	constructor(
		private ele: ElementRef<HTMLElement>,
		private ngZone: NgZone,
	) {
		this.init();
	}

	private init = () => {
		this.ngZone.runOutsideAngular(() => {
			document.addEventListener('keydown', this.keydownListener);
			document.addEventListener('keyup', this.keyupListener);
		});
	};

	private updateFormattedModel = (updatedModel: Date, force = false) => {
		if (isValidDate(updatedModel)) {
			let calendarDate = spacetime(updatedModel);
			calendarDate = calendarDate.add(1, 'hour');
			this.formattedModel = calendarDate.format(this.format);
		} else if (force) {
			this.formattedModel = null;
		}
	};

	private setVerifiedValue = (newValue: Date) => {
		if (this._changeFunction == null) return;

		const oldValue = this._verifiedValue;

		this._verifiedValue = convertToDate(newValue);

		if (!isValidDate(this._verifiedValue)) {
			this._verifiedValue = null;
		}

		if (
			(oldValue !== this._verifiedValue && this._verifiedValue == null) ||
			!spacetime(oldValue).isSame(spacetime(this._verifiedValue), 'day')
		) {
			this._changeFunction(this._verifiedValue);
		}
	};

	public onFormattedChange = (dateModel, force = false) => {
		if (this._blockAutoUpdate && !force) {
			return;
		}
		const updatedModel = convertToDate(dateModel);
		this.updateFormattedModel(updatedModel, force);

		// update the real date given back to the parent
		this.setVerifiedValue(updatedModel);
	};

	public getMaxDate = () => {
		return this._maxDate ? spacetime(this._maxDate).format(this.format) : null;
	};

	public getMinDate = () => {
		return this._minDate ? spacetime(this._minDate).format(this.format) : null;
	};

	public getStartDate = () => {
		if (this._startDate == null) return null;
		if (this._verifiedValue != null) {
			return spacetime(this._verifiedValue).format(this.format);
		} else {
			return spacetime(this._startDate).format(this.format);
		}
	};

	public isPresentingInvalid = () => {
		return this.ele.nativeElement.classList.contains('is-invalid');
	};

	protected changeInputType = (type: 'date' | 'text') => {
		if (this.preferTextInput) {
			type = 'text';
		}

		this.inputType = type;
	};

	protected blur = () => {
		this.changeInputType('date');
		this.onFormattedChange(this.formattedModel, true);

		if (this._touchFunction) {
			this._touchFunction();
		}
	};

	private keydownListener = () => {
		this._blockAutoUpdate = true;
	};

	private keyupListener = () => {
		this._blockAutoUpdate = false;
	};

	// Implementing NG_VALIDATORS
	public validate(ctrl: FormControl) {
		const errors = {};

		if (!this.noValidate) {
			this._validateFn?.forEach((fn) => {
				const error = fn(ctrl);
				if (error) {
					const key = Object.keys(error)[0];
					errors[key] = error[key];
				}
			});
		}

		return errors;
	}

	// Implementing OnChanges
	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.minDate) {
			this._minDate = convertToDate(changes.minDate.currentValue);
		}

		if (changes.maxDate) {
			this._maxDate = convertToDate(changes.maxDate.currentValue);
		}

		if (changes.startDate) {
			this._startDate = convertToDate(changes.startDate.currentValue);
		}

		this._validateFn = [];
		if (this._maxDate != null) {
			this._validateFn.push(createMaxCalendarDateValidation(this._maxDate));
		}
		if (this._minDate != null) {
			this._validateFn.push(createMinCalendarDateValidation(this._minDate));
		}

		if (changes.markDisabled && this.markDisabled != null) {
			const func = this.markDisabled;
			this._validateFn.push((ctrl: AbstractControl) => {
				if (ctrl.value == null) {
					return null;
				}

				const parsedDate = convertToDate(ctrl.value);
				if (!isValidDate(parsedDate)) {
					return null;
				}

				const error = {
					markDisabled: {
						given: ctrl.value,
					},
				};

				let current: any = {};
				if (this._verifiedValue) {
					current = convertDateToNgbDateStruct(this._verifiedValue);
				}

				if (func(convertDateToNgbDateStruct(parsedDate), current)) {
					return error;
				}

				// Date greater than minDate
				return null;
			});
		}

		if (changes.id) {
			this.ele.nativeElement.removeAttribute('id');
		}

		if (changes.preferTextInput) {
			this.changeInputType('date');
		}

		this._validateFn.push(createDateValidation());
		this._changeFunction(this._verifiedValue);
	}

	// Implementing ControlValueAccessor
	public writeValue(val: any): void {
		this.onFormattedChange(val, true);
	}
	public registerOnChange(fn: any): void {
		this._changeFunction = fn;
	}
	public registerOnTouched(fn: any): void {
		this._touchFunction = fn;
	}
	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	ngOnDestroy() {
		document.removeEventListener('keydown', this.keydownListener);
		document.removeEventListener('keyup', this.keyupListener);

		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
