import { Transform, Type } from 'class-transformer';
import { StaffRelationshipTypes } from 'src/lib/constants/staff-relationship-types.enum';
import {
	UserAddressModel,
	UserAddressModel_ClassTransform_Type,
} from 'src/lib/services/utility/utility-models/address.model';
import { stringTransformer } from 'src/lib/utilities/api/class-transformer-types';
import { OrganizationTagModel } from '../tags/organization-tag.model';
import {
	ProgramManagementDataPropertiesModel,
	ProgramManagementDataPropertiesModel_ClassTransform_Type,
} from './program-management-data-properties.model';

export enum BlueprintOverviewSectionEnum {
	contact = 'contact',
	administrative = 'administrative',
	configuration = 'config',
	properties = 'properties',
	program_notes = 'program_notes',
	user_assignments = 'user_assignments',
	files = 'files',
	calendar = 'calendar',
	admin_notes = 'admin_notes',
	meetup_info = 'meetup_info',
	links = 'links',
	kickoff = 'kickoff_checklist',
	branding = 'branding',
	student_welcome_email = 'student_welcome_email',
	sso = 'sso',
}

export enum ConfigurationFieldsEnum {
	no_final_required_percent = 'ga_teacher.auto_final.no_final_required_percent',
	no_final_required_meetup_minutes = 'ga_teacher.auto_final.no_final_required_meetup_minutes',
	no_final_required_past_official_end_force = 'ga_teacher.auto_final.no_final_required_past_official_end_force',
	monthly_registration_limit_style = 'monthly_registration_limit.style',
	student_start_completion_date_weeks = 'student_start_completion_date_weeks',
	high_school_and_beyond_requires_plan = 'high_school_and_beyond.requires_plan',
	monthly_registration_limit_max = 'monthly_registration_limit.max',
	monthly_registration_limit_cohort_exception = 'monthly_registration_limit.cohort_exception',
	credit_conversion_rate = 'credit_conversion_rate',
	state_notes = 'state_notes',
}

export enum SSOFieldsEnum {
	google = 'sso_openid_google',
	microsoft = 'sso_saml_loginmicrosoftonlinecom',
	clever = 'sso_openid_clever',
}

export enum WelcomeEmailFieldsEnum {
	body = 'body',
	custom_sender = 'custom_sender',
	enabled = 'enabled',
	sender = 'sender',
	subject = 'subject',
}

export class ProgramManagementDataUserModel {
	uid: number | string;
	name: string;
	is_active: boolean;
}

export function ProgramManagementDataUserModel_ClassTransform_Type() {
	return ProgramManagementDataUserModel;
}

export class ProgramManagementDataAdminRoleModel {
	id: string;
	name: string;
	is_active: boolean;
}

export function ProgramManagementDataAdminRolesModel_ClassTransform_Type() {
	return ProgramManagementDataAdminRoleModel;
}

export class ProgramManagementDataDefaultRelationshipModel {
	name: string;
	members: ProgramManagementDataUserModel[];
}

export function ProgramManagementDataDefaultRelationshipModel_ClassTransform_Type() {
	return ProgramManagementDataUserModel;
}

export class ProgramManagementDataContactModel {
	email: string;
	phone: number;
	website: string;
	document_request: string[] | number[] | string;
	iep504_request: string[] | number[] | string;
	county: string;
	// country: string; not being used
	@Type(UserAddressModel_ClassTransform_Type)
	address: UserAddressModel;
}

export function ProgramEditRequestContactFieldsModel_ClassTransform_Type() {
	return ProgramManagementDataContactModel;
}

export class ProgramManagementDataConfigItemModel<T> {
	key: string;
	title: string;
	value: T;
}

export class ProgramManagementDataProgramNotesModel {
	diploma_from: string;
	@Transform(stringTransformer)
	extracurricular: string;
	@Transform(stringTransformer)
	graduation: string;
	@Transform(stringTransformer)
	lenient: string;
	age_restrictions: string;
	other_specifics: string;
	registrar: string;
	meetup_information: string;
	paperwork_instructions: string;
	contact_notes: string;
}

export function ProgramManagementDataProgramNotesModel_ClassTransform_Type() {
	return ProgramManagementDataProgramNotesModel;
}

export class ProgramManagementDataUserDefaultRelationshipModel {
	@Type(ProgramManagementDataDefaultRelationshipModel_ClassTransform_Type)
	[StaffRelationshipTypes.academic_coach]: ProgramManagementDataDefaultRelationshipModel;
	@Type(ProgramManagementDataDefaultRelationshipModel_ClassTransform_Type)
	[StaffRelationshipTypes.local_advocate]: ProgramManagementDataDefaultRelationshipModel;
	@Type(ProgramManagementDataDefaultRelationshipModel_ClassTransform_Type)
	[StaffRelationshipTypes.enrollment_counselor]: ProgramManagementDataDefaultRelationshipModel;
	@Type(ProgramManagementDataDefaultRelationshipModel_ClassTransform_Type)
	[StaffRelationshipTypes.counselor]: ProgramManagementDataDefaultRelationshipModel;
	@Type(ProgramManagementDataDefaultRelationshipModel_ClassTransform_Type)
	[StaffRelationshipTypes.administrator]: ProgramManagementDataDefaultRelationshipModel;
	@Type(ProgramManagementDataDefaultRelationshipModel_ClassTransform_Type)
	[StaffRelationshipTypes.assistant]: ProgramManagementDataDefaultRelationshipModel;
	@Type(ProgramManagementDataDefaultRelationshipModel_ClassTransform_Type)
	[StaffRelationshipTypes.college_career_counselor]: ProgramManagementDataDefaultRelationshipModel;
	@Type(ProgramManagementDataDefaultRelationshipModel_ClassTransform_Type)
	[StaffRelationshipTypes.gatekeeper]: ProgramManagementDataDefaultRelationshipModel;
	@Type(ProgramManagementDataDefaultRelationshipModel_ClassTransform_Type)
	[StaffRelationshipTypes.businessManager]: ProgramManagementDataDefaultRelationshipModel;
	@Type(ProgramManagementDataDefaultRelationshipModel_ClassTransform_Type)
	[StaffRelationshipTypes.programManager]: ProgramManagementDataDefaultRelationshipModel;
	@Type(ProgramManagementDataDefaultRelationshipModel_ClassTransform_Type)
	[StaffRelationshipTypes.registrar]: ProgramManagementDataDefaultRelationshipModel;
	@Type(ProgramManagementDataDefaultRelationshipModel_ClassTransform_Type)
	[StaffRelationshipTypes.specialEdDirector]: ProgramManagementDataDefaultRelationshipModel;
	@Type(ProgramManagementDataDefaultRelationshipModel_ClassTransform_Type)
	[StaffRelationshipTypes.transcriptCoordinator]: ProgramManagementDataDefaultRelationshipModel;
	@Type(ProgramManagementDataDefaultRelationshipModel_ClassTransform_Type)
	[StaffRelationshipTypes.caseManager]: ProgramManagementDataDefaultRelationshipModel;
}

export function ProgramManagementDataUserDefaultRelationshipModel_ClassTransform_Type() {
	return ProgramManagementDataUserDefaultRelationshipModel;
}

export class ProgramManagementDataUserAssignmentsAdminModel {
	@Type(ProgramManagementDataAdministrativeDetailsModel_ClassTransform_Type)
	gatekeepers: ProgramManagementDataAdministrativeDetailsModel[];
	@Type(ProgramManagementDataAdministrativeDetailsModel_ClassTransform_Type)
	business_managers: ProgramManagementDataAdministrativeDetailsModel[];
	@Type(ProgramManagementDataAdministrativeDetailsModel_ClassTransform_Type)
	program_managers: ProgramManagementDataAdministrativeDetailsModel[];
	@Type(ProgramManagementDataAdministrativeDetailsModel_ClassTransform_Type)
	registrars: ProgramManagementDataAdministrativeDetailsModel[];
	@Type(ProgramManagementDataAdministrativeDetailsModel_ClassTransform_Type)
	special_ed_directors: ProgramManagementDataAdministrativeDetailsModel[];
	@Type(ProgramManagementDataAdministrativeDetailsModel_ClassTransform_Type)
	transcript_coordinators: ProgramManagementDataAdministrativeDetailsModel[];
	@Type(ProgramManagementDataAdministrativeDetailsModel_ClassTransform_Type)
	all: ProgramManagementDataAdministrativeDetailsModel[];
}

export function ProgramManagementDataUserAssignmentsAdminModel_ClassTransform_Type() {
	return ProgramManagementDataUserAssignmentsModel;
}

export class ProgramManagementDataUserAssignmentsModel {
	@Type(ProgramManagementDataUserDefaultRelationshipModel_ClassTransform_Type)
	default_relationships: ProgramManagementDataUserDefaultRelationshipModel;

	@Type(ProgramManagementDataUserAssignmentsAdminModel_ClassTransform_Type)
	admins: ProgramManagementDataUserAssignmentsAdminModel;
}

export function ProgramManagementDataUserAssignmentsModel_ClassTransform_Type() {
	return ProgramManagementDataUserAssignmentsModel;
}

export class ProgramManagementDataConfigurationModel {
	[ConfigurationFieldsEnum.no_final_required_percent]: ProgramManagementDataConfigItemModel<number>;
	[ConfigurationFieldsEnum.no_final_required_meetup_minutes]: ProgramManagementDataConfigItemModel<number>;
	[ConfigurationFieldsEnum.no_final_required_past_official_end_force]: ProgramManagementDataConfigItemModel<boolean>;
	[ConfigurationFieldsEnum.monthly_registration_limit_style]: ProgramManagementDataConfigItemModel<string>;
	[ConfigurationFieldsEnum.student_start_completion_date_weeks]: ProgramManagementDataConfigItemModel<number>;
	[ConfigurationFieldsEnum.high_school_and_beyond_requires_plan]: ProgramManagementDataConfigItemModel<boolean>;
	[ConfigurationFieldsEnum.monthly_registration_limit_max]: ProgramManagementDataConfigItemModel<number>;
	[ConfigurationFieldsEnum.monthly_registration_limit_cohort_exception]: ProgramManagementDataConfigItemModel<boolean>;
	[ConfigurationFieldsEnum.credit_conversion_rate]: ProgramManagementDataConfigItemModel<number>;
	[ConfigurationFieldsEnum.state_notes]: ProgramManagementDataConfigItemModel<string>;
}

export function ProgramManagementDataConfigurationModel_ClassTransform_Type() {
	return ProgramManagementDataConfigurationModel;
}

export class WelcomeEmailTokenModel {
	body: Record<string, string>;
	subject: Record<string, string>;
}

export function WelcomeEmailTokenModel_ClassTransform_Type() {
	return WelcomeEmailTokenModel;
}

export class ProgramManagementDataWelcomeEmailModel {
	[WelcomeEmailFieldsEnum.body]: string;
	[WelcomeEmailFieldsEnum.custom_sender]: string;
	[WelcomeEmailFieldsEnum.enabled]: boolean;
	[WelcomeEmailFieldsEnum.sender]: string;
	[WelcomeEmailFieldsEnum.subject]: string;
	@Type(WelcomeEmailTokenModel_ClassTransform_Type)
	tokens: WelcomeEmailTokenModel;
}

export function ProgramManagementDataWelcomeEmailModel_ClassTransform_Type() {
	return ProgramManagementDataWelcomeEmailModel;
}

export class ProgramManagementDataSsoItemModel {
	enabled: boolean;
	authenticator: string;
	district_id: string;
}

export function ProgramManagementDataSsoItemModel_ClassTransform_Type() {
	return ProgramManagementDataSsoItemModel;
}

export class ProgramManagementDataSsoModel {
	@Type(ProgramManagementDataSsoItemModel_ClassTransform_Type)
	[SSOFieldsEnum.google]: ProgramManagementDataSsoItemModel;
	@Type(ProgramManagementDataSsoItemModel_ClassTransform_Type)
	[SSOFieldsEnum.microsoft]: ProgramManagementDataSsoItemModel;
	@Type(ProgramManagementDataSsoItemModel_ClassTransform_Type)
	[SSOFieldsEnum.clever]: ProgramManagementDataSsoItemModel;
}

export function ProgramManagementDataSsoModel_ClassTransform_Type() {
	return ProgramManagementDataSsoModel;
}

export class ProgramManagementDataAdministrativeModel {
	active: boolean;
	name: string;
	default_student_password: string;
	quickbooks_map: string;
	esd_number: string;
	serving_district_number: string;
	salesforce_id: string;
	parent_institute_id: number;
	parent_institute_title: string;
	timezone: string;
	program_type: number;
	institute_type: string;
	school_type: string;
	structural: boolean;
	itype: number;
	tiers: number[];
	unique_id: string;
}

export function ProgramManagementDataAdministrativeModel_ClassTransform_Type() {
	return ProgramManagementDataAdministrativeModel;
}

export class ProgramManagementDataBrandingModel {
	branding_light_image: string;
	light_image: string;
	light_bg_color: string;
	light_border_color: string;
	light_text_color: string;
	branding_dark_image: string;
	dark_image: string;
	dark_bg_color: string;
	dark_border_color: string;
	dark_text_color: string;
	branding_enabled: boolean;
	institute_title: string;
}

export function ProgramManagementDataBrandingModel_ClassTransform_Type() {
	return ProgramManagementDataBrandingModel;
}

export class ProgramManagementDataModel {
	id?: number;
	@Type(ProgramEditRequestContactFieldsModel_ClassTransform_Type)
	[BlueprintOverviewSectionEnum.contact]?: Partial<ProgramManagementDataContactModel>;
	@Type(ProgramManagementDataAdministrativeModel_ClassTransform_Type)
	[BlueprintOverviewSectionEnum.administrative]?: Partial<ProgramManagementDataAdministrativeModel>;
	@Type(ProgramManagementDataProgramNotesModel_ClassTransform_Type)
	[BlueprintOverviewSectionEnum.program_notes]?: Partial<ProgramManagementDataProgramNotesModel>;
	@Type(ProgramManagementDataUserAssignmentsModel_ClassTransform_Type)
	[BlueprintOverviewSectionEnum.user_assignments]?: Partial<ProgramManagementDataUserAssignmentsModel>;
	@Type(ProgramManagementDataConfigurationModel_ClassTransform_Type)
	[BlueprintOverviewSectionEnum.configuration]?: Partial<ProgramManagementDataConfigurationModel>;
	@Type(ProgramManagementDataPropertiesModel_ClassTransform_Type)
	[BlueprintOverviewSectionEnum.properties]?: Partial<ProgramManagementDataPropertiesModel>;
	@Type(ProgramManagementDataBrandingModel_ClassTransform_Type)
	[BlueprintOverviewSectionEnum.branding]?: Partial<ProgramManagementDataBrandingModel>;
	@Type(ProgramManagementDataWelcomeEmailModel_ClassTransform_Type)
	[BlueprintOverviewSectionEnum.student_welcome_email]?: Partial<ProgramManagementDataWelcomeEmailModel>;
	@Type(ProgramManagementDataSsoModel_ClassTransform_Type)
	sso?: Partial<ProgramManagementDataSsoModel>;
	tags?: { tags: number[] } | OrganizationTagModel[];
}

export function ProgramEditRequestFieldsModel_ClassTransform_Type() {
	return ProgramManagementDataModel;
}

export class ProgramManagementDataAdministrativeDetailsModel {
	uid: number;
	first_name: string;
	last_name: string;
	title: string;
	email: string;
	phone: number;
	is_staff: boolean;
	@Type(ProgramManagementDataAdminRolesModel_ClassTransform_Type)
	default_relationships: ProgramManagementDataAdminRoleModel[];
	admin_role: string;
	admin_role_name: string;
	@Type(ProgramManagementDataAdminRolesModel_ClassTransform_Type)
	additional_roles: ProgramManagementDataAdminRoleModel[];
	notes: string;
	is_active: boolean;
}

export function ProgramManagementDataAdministrativeDetailsModel_ClassTransform_Type() {
	return ProgramManagementDataAdministrativeDetailsModel;
}

export class ProgramManagementOverviewAccessModel {
	institute_administrative: boolean;
	institute_config: boolean;
	institute_contact: boolean;
	institute_files: boolean;
	institute_program_notes: boolean;
	institute_properties: boolean;
	institute_user_assignments: boolean;
}

export function ProgramManagementOverviewAccessModel_ClassTransform_Type() {
	return ProgramManagementOverviewAccessModel;
}

export class ProgramManagementLinkModel {
	type: string;
	id: string;
	title: string;
	link: string;
}

export function ProgramManagementLinkModel_ClassTransform_Type() {
	return ProgramManagementLinkModel;
}

export class ProgramManagementOverviewModel {
	@Type(ProgramManagementOverviewAccessModel_ClassTransform_Type)
	access: ProgramManagementOverviewAccessModel;
	@Type(ProgramEditRequestFieldsModel_ClassTransform_Type)
	institute: ProgramManagementDataModel;
	@Type(ProgramEditRequestFieldsModel_ClassTransform_Type)
	links: ProgramManagementLinkModel[];
}
