import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StateTestArgument } from './state-test.argument';
import {
	StateTestCreateModel,
	StateTestListResponseModel,
	StateTestMapperSearchArgument,
} from './state-test.model';

import { plainToInstance } from 'class-transformer';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import { StudentStateTestEventModel } from '../students/tests/student-state-test.model';
import { RegistrationListModel } from '../students/tests/test-registration.model';
import { StateTestAttendanceArgument } from './state-test-attendance.argument';
import { StateTestEventDeleteArgument } from './state-test-delete.argument';
import { StateTestEventArgument } from './state-test-event.argument';
import {
	StateTestEventListModel,
	StateTestEventModel,
} from './state-test-event.model';

@Injectable({
	providedIn: 'root',
})
export class StateTestsService {
	constructor(private httpClient: HttpClient) {}

	public getStateTests = (
		args?: PagingArgument<StateTestMapperSearchArgument>,
	): Observable<StateTestListResponseModel> => {
		return this.httpClient.post<unknown>(`/api/v1/tests`, args).pipe(
			mapResponse((r) => plainToInstance(StateTestListResponseModel, r), {
				errorCode: '4F77137E',
			}),
		);
	};

	public createTest = (args: StateTestArgument) => {
		return this.httpClient.post<unknown>(`/api/v1/test/create`, args).pipe(
			mapResponse((r) => plainToInstance(StateTestEventModel, r), {
				errorCode: '93615CBC',
				errorMessage: DefaultErrorMessage.Saving,
			}),
		);
	};

	public updateTest = (id: number, args: StateTestArgument) => {
		return this.httpClient.patch(`/api/v1/test/${id}`, args).pipe(
			mapResponse((r) => r, {
				errorCode: 'E4F299AF',
			}),
		);
	};

	public getTest = (id: number): Observable<StateTestCreateModel> => {
		return this.httpClient
			.get<{
				state_test: unknown;
			}>(`/api/v1/test/${id}`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StateTestCreateModel, r.state_test),
					{
						errorCode: '62F77BE4',
					},
				),
			);
	};

	/**State Events */
	public getStateTestEvents = (
		args?: PagingArgument<unknown>,
	): Observable<StateTestEventListModel> => {
		return this.httpClient.post<unknown>(`/api/v1/test/events`, args).pipe(
			mapResponse((r) => plainToInstance(StateTestEventListModel, r), {
				errorCode: 'B33D818F',
			}),
		);
	};

	public getTestEvent = (id: number): Observable<StateTestEventModel> => {
		return this.httpClient
			.get<{
				state_test_instance: unknown;
			}>(`/api/v1/test/event/${id}`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StateTestEventModel, r.state_test_instance),
					{
						errorCode: '736ECE25',
					},
				),
			);
	};

	public createTestEvent = (args: StateTestEventArgument) => {
		return this.httpClient
			.post<unknown>(`/api/v1/test/event/create`, args)
			.pipe(
				mapResponse((r) => plainToInstance(StateTestEventModel, r), {
					errorCode: '587978B8',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public updateTestEvent = (
		id: number,
		args: StateTestEventArgument | StateTestEventDeleteArgument,
	): Observable<unknown> => {
		return this.httpClient.patch(`/api/v1/test/event/${id}`, args).pipe(
			mapResponse((r) => r, {
				errorCode: '89E4b573',
			}),
		);
	};

	public downloadRegistrationList = (
		id: number,
	): Observable<RegistrationListModel[]> => {
		return this.httpClient
			.get<unknown[]>(`/api/v1/test/event/${id}/attendance/download`, {
				responseType: 'json',
			})
			.pipe(
				mapResponse((r) => plainToInstance(RegistrationListModel, r), {
					errorCode: '2309F667',
				}),
			);
	};

	public saveTestEventAttendance = (
		id: number,
		args: StateTestAttendanceArgument,
	): Observable<StudentStateTestEventModel> => {
		return this.httpClient
			.post<unknown>(`/api/v1/test/event/${id}/attendance/record`, args)
			.pipe(
				mapResponse((r) => plainToInstance(StudentStateTestEventModel, r), {
					errorCode: 'B86EA8CF',
				}),
			);
	};
}
