import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { buildFormData } from 'src/lib/utilities/api/http';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import {
	GradReviewChecklistArgument,
	GradReviewCreateArgument,
	GradReviewUpdateArgument,
	GraduationReviewOptionResponseModel,
	GraduationReviewRequestModel,
} from './graduation-review.model';

@Injectable({
	providedIn: 'root',
})
export class GraduationReviewService {
	constructor(private httpClient: HttpClient) {}

	public getReviewRequest = (
		uid: number,
	): Observable<GraduationReviewRequestModel> => {
		return this.httpClient
			.get<{
				graduation_review_request: unknown;
			}>(`/api/v1/students/${uid}/graduation/review_request`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							GraduationReviewRequestModel,
							r.graduation_review_request,
						),
					{
						errorCode: '5C5C78B0',
					},
				),
			);
	};

	public getReviewRequestOptions = (
		uid: number,
	): Observable<GraduationReviewOptionResponseModel> => {
		return this.httpClient
			.get<unknown>(`/api/v1/students/${uid}/graduation/review_request/options`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(GraduationReviewOptionResponseModel, r),
					{
						errorCode: '55877577',
					},
				),
			);
	};

	// used to create a request and to save/update internal note
	public createReviewRequest = (
		uid: number,
		args: Partial<GradReviewCreateArgument>,
	): Observable<GraduationReviewRequestModel> => {
		return this.httpClient
			.post<{
				graduation_review_request: unknown;
			}>(`/api/v1/students/${uid}/graduation/review_request`, args)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							GraduationReviewRequestModel,
							r.graduation_review_request,
						),
					{
						errorCode: '5091B75C',
					},
				),
			);
	};

	public updateReviewRequest = (
		uid: number,
		args: GradReviewUpdateArgument,
	): Observable<GraduationReviewRequestModel> => {
		return this.httpClient
			.post<{
				graduation_review_request: unknown;
			}>(`/api/v1/students/${uid}/graduation/review_request/update`, args)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							GraduationReviewRequestModel,
							r.graduation_review_request,
						),
					{
						errorCode: '99D667BB',
					},
				),
			);
	};

	public attachFileToReviewUpdate = (
		uid: number,
		requestUpdateId: number,
		file: File,
	): Observable<GraduationReviewRequestModel> => {
		const formData = buildFormData({ file: file });

		return this.httpClient
			.post<{
				graduation_review_request: unknown;
			}>(
				`/api/v1/students/${uid}/graduation/review_request/${requestUpdateId}/attach`,
				formData,
			)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							GraduationReviewRequestModel,
							r.graduation_review_request,
						),
					{
						errorCode: '291BA259',
					},
				),
			);
	};

	// POSTMAN: Edit Graduation Review Request - Checklist
	public updateChecklistItem = (
		uid: number,
		args: GradReviewChecklistArgument,
	): Observable<GraduationReviewRequestModel> => {
		return this.httpClient
			.post<{
				graduation_review_request: unknown;
			}>(`/api/v1/students/${uid}/graduation/review_request/checklist`, args)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							GraduationReviewRequestModel,
							r.graduation_review_request,
						),
					{
						errorCode: '35CA20AE',
					},
				),
			);
	};
}
