import { Injectable, OnDestroy } from '@angular/core';
import {
	AsyncSubject,
	Observable,
	Subject,
	catchError,
	filter,
	of,
	switchMap,
	take,
	takeUntil,
	tap,
	timer,
} from 'rxjs';
import { SessionExpiredModalService } from 'src/lib/views/modals/session-expired-modal/session-expired-modal.service';
import { LoginService } from '../../api/generic/login/login.service';
import { InitializationService } from '../../utility/initialization/initialization.service';
import { UserActiveService } from '../user-active/user-active.service';

const NORMAL_CHECK_INTERVAL = 1000 * 60; // 1 minute
const PANIC_CHECK_INTERVAL = 5000; // 5 seconds
const AWAY_CHECK_INTERVAL = 1000 * 60 * 10; // 10 minutes

@Injectable({
	providedIn: 'root',
})
export class AuthenticationCheckService implements OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();
	private initialized = false;

	private _isTerminated = false;

	private _authCheckSubject = new Subject<Observable<unknown>>();

	constructor(
		private loginService: LoginService,
		private userActiveService: UserActiveService,
		private sessionExpiredModalService: SessionExpiredModalService,
		private initializationService: InitializationService,
	) {}

	public init = () => {
		if (this.initialized) {
			throw new Error(
				'AuthenticationCheckService has already been initialized',
			);
		}
		this.initialized = true;

		this.watchForAuthentication();
		this.watchForTabSwitch();

		if (this.initializationService.id > 0) {
			this.queueNextCheck(false);
		} else {
			console.info(
				'AuthenticationCheckService: Not logged in, not checking authentication status.',
			);
		}
	};

	private queueNextCheck = (panic: boolean) => {
		this.userActiveService.isAway$.pipe(take(1)).subscribe((isAway) => {
			if (isAway) {
				this._authCheckSubject.next(timer(AWAY_CHECK_INTERVAL));
			} else {
				this._authCheckSubject.next(
					timer(panic ? PANIC_CHECK_INTERVAL : NORMAL_CHECK_INTERVAL),
				);
			}
		});
	};

	private watchForAuthentication() {
		let errorCount = 0;

		this._authCheckSubject
			.pipe(
				filter(() => !this._isTerminated),
				switchMap((wait$) => {
					// We need the switchMap to cancel the previous check if a new one is queued
					return wait$.pipe(
						take(1),
						switchMap(() => this.loginService.getLoginStatus()),
						tap((x) => {
							errorCount = 0;

							if (x.is_anonymous) {
								this.terminate();
							} else {
								this.queueNextCheck(false);
							}
						}),
						catchError((err) => {
							errorCount++;
							console.error(
								'There was an error checking authentication status.',
								err,
							);

							if (errorCount > 3) {
								this.terminate();
							} else {
								this.queueNextCheck(true);
							}

							return of(null);
						}),
					);
				}),
				takeUntil(this._unsubscribe$),
			)
			.subscribe();
	}

	private watchForTabSwitch() {
		this.userActiveService.tabHidden$
			.pipe(filter(() => !this._isTerminated))
			.subscribe((isHidden) => {
				if (isHidden) {
					this._authCheckSubject.next(
						this.userActiveService.isActive$.pipe(filter((x) => x)),
					);
				}
			});
	}

	private terminate() {
		this._isTerminated = true;
		this.sessionExpiredModalService.openModal$().subscribe();
	}

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
