import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { HttpCallCache } from 'src/lib/utilities/api/http';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import {
	ProgramKickoffModel,
	ProgramKickoffTrackerArgument,
} from './program-kickoff-checklist.model';
import {
	ProgramManagementDataModel,
	ProgramManagementOverviewModel,
} from './program-management-data.model';

@Injectable({
	providedIn: 'root',
})
export class ProgramManagementService {
	constructor(private httpClient: HttpClient) {}

	// POSTMAN: Organization Admin Overview
	private httpCallCache_getProgramOverview = new HttpCallCache<
		number,
		ProgramManagementOverviewModel
	>();

	public getProgramOverview = (
		orgId: number,
		forceRefresh: boolean = false,
	): Observable<ProgramManagementOverviewModel> => {
		if (forceRefresh) {
			this.httpCallCache_getProgramOverview.flushAll();
		}

		return this.httpCallCache_getProgramOverview.fetch$(
			orgId,
			this.httpClient
				.get<unknown>(`/api/v1/organizations/${orgId}/admin/overview`)
				.pipe(
					mapResponse(
						(r) => plainToInstance(ProgramManagementOverviewModel, r),
						{
							errorCode: '737D172B',
						},
					),
				),
		);
	};

	public createProgram = (
		args: Partial<ProgramManagementDataModel>,
	): Observable<ProgramManagementOverviewModel> => {
		return this.httpClient
			.post<unknown>(`/api/v1/organizations/create`, {
				organization: { fields: args },
			})
			.pipe(
				mapResponse((r) => plainToInstance(ProgramManagementOverviewModel, r), {
					errorCode: '4AB5391D',
				}),
			);
	};

	public getCurrentProgramChecklist = (
		orgId: number,
	): Observable<ProgramKickoffModel> => {
		return this.httpClient
			.get<unknown>(`/api/v1/organizations/${orgId}/checklist/current`)
			.pipe(
				mapResponse((r) => plainToInstance(ProgramKickoffModel, r), {
					errorCode: 'FB16D419',
				}),
			);
	};

	public toggleProgramChecklistItem = (
		orgId: number,
		item_id: string,
		checked: boolean,
	): Observable<{ item_id: string }> => {
		return this.httpClient
			.post<{
				item_id: string;
			}>(`/api/v1/organizations/${orgId}/checklist/${checked ? 'check_item' : 'uncheck_item'}`, { item_id: item_id })
			.pipe(
				mapResponse((r) => r, {
					errorCode: '9C233072',
				}),
			);
	};

	public getProgramKickoffList = (
		args: ProgramKickoffTrackerArgument,
	): Observable<ProgramKickoffModel[]> => {
		return this.httpClient
			.post<unknown[]>(`/api/v1/tracker/program-checklist`, args)
			.pipe(
				mapResponse((r) => plainToInstance(ProgramKickoffModel, r), {
					errorCode: '0B45865B',
				}),
			);
	};
}
