import { PagingSearchFieldArgument } from './paging-search-field.argument';

export interface PagingArgument<
	PagingSearch extends {
		[K in keyof PagingSearch]: PagingSearchFieldArgument | unknown; // Adding unknown disables the type check, there's too many variations to account for. Leaving the type for developers to know the intent
	} = unknown,
> {
	skip?: number;
	take?: number;
	sort?: { by: string; order: 'DESC' | 'ASC' };
	filters?: Partial<PagingSearch>;
}

export function booleanToSortOrderDescending(
	val: boolean,
): 'DESC' | 'ASC' | null {
	if (val == null) return null;
	return val ? 'DESC' : 'ASC';
}
