import { HttpClient } from '@angular/common/http';
import { plainToInstance } from 'class-transformer';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	WithdrawEditArgument,
	WithdrawStudentArgument,
} from 'src/lib/services/api/students/enrollment/withdraw-student.argument';
import { ExitReasonModel } from './student-enrollment-exit-reason.model';
import { StudentEnrollmentInfoOptionsResponseModel } from './student-enrollment-info-options-response.model';
import {
	EnrollmentInfoEditResponseModel,
	EnrollmentInfoPaperworkModel,
	EnrollmentInfoStudentDataModel,
} from './student-enrollment-info-response.model';
import { WithdrawRequestResponseModel } from './student-enrollment-withdraw-request-model';

import {
	buildFormData,
	buildQueryString,
	HttpCallCache,
} from 'src/lib/utilities/api/http';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { StudentEnrollmentInfoArgument } from './student-enrollment-info.argument';
import { UploadPaperworkArgument } from './student-enrollment-paperwork-option.model';
import { PaywhirlInfoModel } from './student-enrollment-payment.model';

@Injectable({
	providedIn: 'root',
})
export class StudentsEnrollmentService {
	private getWithdrawRequest_HttpCallCache = new HttpCallCache<
		number,
		WithdrawRequestResponseModel
	>();

	private getEnrollmentFormData_HttpCallCache = new HttpCallCache<
		string,
		EnrollmentInfoStudentDataModel
	>();

	constructor(private httpClient: HttpClient) {}

	public getExitReasons = (uid: number): Observable<ExitReasonModel> => {
		return this.httpClient
			.get<unknown>(`/api/v1/students/${uid}/exit_reasons`)
			.pipe(
				mapResponse((r) => plainToInstance(ExitReasonModel, r), {
					errorCode: 'EBDADCFB',
				}),
			);
	};

	public withdrawSingleStudent = (
		uid: number,
		args: WithdrawStudentArgument,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(`/api/v1/students/${uid}/withdraw`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '5AB760E3',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public cancelWithdrawRequest = (uid: number): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(`/api/v1/students/${uid}/withdraw/cancel`, {})
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'E09B4745',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public getWithdrawRequest = (
		uid: number,
		force: boolean = false,
	): Observable<WithdrawRequestResponseModel> => {
		if (force) this.getWithdrawRequest_HttpCallCache.flushAll();

		return this.getWithdrawRequest_HttpCallCache.fetch$(
			uid,
			this.httpClient.get<unknown>(`/api/v1/students/${uid}/withdraw`).pipe(
				mapResponse((r) => plainToInstance(WithdrawRequestResponseModel, r), {
					errorCode: '1959CAA2',
					checkPreMap: false,
					validateSuccess: false,
				}),
			),
		);
	};

	public editWithdrawSingleStudent = (
		uid: number,
		args: WithdrawEditArgument,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(`/api/v1/students/${uid}/withdraw/edit`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'FA485584',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	// Enrollment Form Info
	public getEnrollmentFormData = (
		studentId: number,
		includeExpiredPaperwork?: boolean,
	): Observable<EnrollmentInfoStudentDataModel> => {
		const qs = buildQueryString({
			include_expired_paperwork: includeExpiredPaperwork || undefined,
		});

		return this.getEnrollmentFormData_HttpCallCache.fetch$(
			`${studentId}.${includeExpiredPaperwork}`,
			this.httpClient
				.get<{
					student_data: unknown;
				}>(`/api/v1/students/${studentId}/enrollment/form${qs}`)
				.pipe(
					mapResponse(
						(r) =>
							plainToInstance(EnrollmentInfoStudentDataModel, r.student_data),
						{
							errorCode: '186E3DDD',
						},
					),
				),
		);
	};

	public getEnrollmentFormDataOptions = (
		studentId: number,
	): Observable<StudentEnrollmentInfoOptionsResponseModel> => {
		return this.httpClient
			.get<unknown>(`/api/v1/students/${studentId}/enrollment/form/options`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentEnrollmentInfoOptionsResponseModel, r),
					{ errorCode: '8CFAE5C4' },
				),
			);
	};

	public setEnrollmentFormData = (
		studentId: number,
		args: StudentEnrollmentInfoArgument,
	): Observable<EnrollmentInfoEditResponseModel> => {
		return this.httpClient
			.post<unknown>(`/api/v1/students/${studentId}/enrollment/form`, {
				student_data: args,
			})
			.pipe(
				mapResponse(
					(r) => plainToInstance(EnrollmentInfoEditResponseModel, r),
					{
						errorCode: '0F67D15D',
						errorMessage: DefaultErrorMessage.Saving,
					},
				),
			);
	};

	public getPaperwork = (
		studentId: number,
		includeExpiredPaperwork?: boolean,
	): Observable<EnrollmentInfoPaperworkModel[]> => {
		const qs = buildQueryString({
			include_expired_paperwork: includeExpiredPaperwork || undefined,
		});

		return this.httpClient
			.get<{
				paperwork: unknown[];
			}>(`/api/v1/students/${studentId}/enrollment/form/paperwork${qs}`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(EnrollmentInfoPaperworkModel, r.paperwork),
					{ errorCode: 'E95CB49D' },
				),
			);
	};

	public getUploadPaperworkOptions = (
		studentId: number,
	): Observable<string[]> => {
		return this.httpClient
			.get<{
				paperwork_types: string[];
			}>(`/api/v1/students/${studentId}/enrollment/form/paperwork/options`)
			.pipe(
				mapResponse((r) => r.paperwork_types, {
					errorCode: 'ED1C9A6E',
				}),
			);
	};

	public uploadPaperwork = (
		studentId: number,
		args: UploadPaperworkArgument,
		file: File,
	): Observable<EnrollmentInfoPaperworkModel> => {
		const bodyArgs = {};
		bodyArgs['jsonBody'] = args;
		if (file) {
			bodyArgs['file'] = file;
		}

		const formData = buildFormData(bodyArgs);
		return this.httpClient
			.post<unknown>(
				`/api/v1/students/${studentId}/enrollment/form/paperwork`,
				formData,
			)
			.pipe(
				mapResponse((r) => plainToInstance(EnrollmentInfoPaperworkModel, r), {
					errorCode: 'CB04DEC5',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public uploadTranscript = (studentId: number, file: File) => {
		const bodyArgs = {};
		bodyArgs['transcript_file'] = file;
		return this.httpClient
			.post<unknown>(
				`/api/v1/students/${studentId}/transcript/transferred`,
				buildFormData(bodyArgs),
			)
			.pipe(
				mapResponse((r) => plainToInstance(EnrollmentInfoPaperworkModel, r), {
					errorCode: '2D08F518',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public expirePaperwork = (
		studentId: number,
		fileId: string,
		type?: string,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(
				`/api/v1/students/${studentId}/enrollment/form/paperwork/expire`,
				{ file_id: fileId, expire_waived_type: type },
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'EA3310FE',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public deletePaperwork = (
		studentId: number,
		fileId: string,
		type?: string,
	): Observable<{
		success: boolean;
	}> => {
		return this.httpClient
			.post<{
				success: boolean;
			}>(`/api/v1/students/${studentId}/enrollment/form/paperwork/delete`, {
				file_id: fileId,
				delete_waived_type: type,
			})
			.pipe(
				mapResponse((r) => r, {
					errorCode: '67C61ED5',
					errorMessage: 'There was an issue deleting the paperwork',
				}),
			);
	};

	public removePaperworkExpiration = (
		uid: number,
		fileId: string,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(
				`/api/v1/students/${uid}/enrollment/form/paperwork/remove_expiration`,
				{ file_id: fileId },
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '67C61ED5',
					errorMessage: 'There was an issue removing the paperwork expiration',
				}),
			);
	};

	/* TAA Payment */
	public getPaywhirlInformation = (
		studentId: number,
	): Observable<PaywhirlInfoModel> => {
		return this.httpClient
			.get<unknown>(`/api/v1/students/${studentId}/billing/paywhirl`)
			.pipe(
				mapResponse((r) => plainToInstance(PaywhirlInfoModel, r), {
					errorCode: '49684C08',
				}),
			);
	};

	public setPaywhirlInformation = (
		studentId: number,
		customerId: number,
		ignore: boolean,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(`/api/v1/students/${studentId}/billing/paywhirl`, {
				ignore_payment_status: ignore,
				customer_id: customerId,
			})
			.pipe(
				mapResponse((r) => r, {
					errorCode: '76EC64AD',
					errorMessage: 'There was an issue updating the status',
				}),
			);
	};
}
