import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import { StateOptionModel } from '../generic/options/state-option.model';
import {
	SurveyPeriodEditMembersArgument,
	SurveyPeriodMembersModel,
	SurveyPeriodPotentialMembersModel,
} from './survey-period-member.model';
import {
	SurveyPeriodStateArgument,
	SurveyPeriodStateModel,
} from './survey-period-state.model';
import {
	SurveyPeriodTrackerArgument,
	SurveyPeriodTrackerModel,
} from './survey-period-tracker.model';
import {
	SurveyPeriodGroupOptionsModel,
	SurveyPeriodModel,
	SurveyPeriodUpsertArgument,
} from './survey-period.model';

@Injectable({
	providedIn: 'root',
})
export class SurveyPeriodsService {
	constructor(private httpClient: HttpClient) {}

	public getTracker = (
		args: PagingArgument<SurveyPeriodTrackerArgument>,
	): Observable<SurveyPeriodTrackerModel> => {
		return this.httpClient
			.post<unknown>(`/api/v1/tracker/survey_period`, args)
			.pipe(
				mapResponse((r) => plainToInstance(SurveyPeriodTrackerModel, r), {
					errorCode: 'D04451CB',
				}),
			);
	};

	public getSurveyPeriod = (id: number): Observable<SurveyPeriodModel> => {
		return this.httpClient
			.get<{
				survey_period: unknown;
			}>(`/api/v1/survey_period/${id}`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(SurveyPeriodModel, r.survey_period),
					{
						errorCode: '345D4226',
					},
				),
			);
	};

	public createSurveyPeriod = (
		args: SurveyPeriodUpsertArgument,
	): Observable<SurveyPeriodModel> => {
		return this.httpClient
			.post<{
				survey_period: unknown;
			}>(`/api/v1/survey_period`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(SurveyPeriodModel, r.survey_period),
					{
						errorCode: 'B56F780E',
						errorMessage: DefaultErrorMessage.Saving,
					},
				),
			);
	};

	public editSurveyPeriod = (
		periodId: number,
		args: SurveyPeriodUpsertArgument,
	): Observable<SurveyPeriodModel> => {
		return this.httpClient
			.post<{
				survey_period: unknown;
			}>(`/api/v1/survey_period/${periodId}`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(SurveyPeriodModel, r.survey_period),
					{
						errorCode: 'F88C2918',
						errorMessage: DefaultErrorMessage.Saving,
					},
				),
			);
	};

	// same call as editSurveyPeriod but wanted to keep args separate on front end
	public setFinalized = (periodId: number): Observable<SurveyPeriodModel> => {
		return this.httpClient
			.post<{
				survey_period: unknown;
			}>(`/api/v1/survey_period/${periodId}`, { finalized: true })
			.pipe(
				mapResponse(
					(r) => plainToInstance(SurveyPeriodModel, r.survey_period),
					{
						errorCode: 'F88C2918',
						errorMessage: DefaultErrorMessage.Saving,
					},
				),
			);
	};

	// same call as editSurveyPeriod but wanted to keep args separate on front end
	public toggleSurveyPeriodActive = (
		periodId: number,
		active: boolean,
	): Observable<SurveyPeriodModel> => {
		return this.httpClient
			.post<{
				survey_period: unknown;
			}>(`/api/v1/survey_period/${periodId}`, { active: active })
			.pipe(
				mapResponse(
					(r) => plainToInstance(SurveyPeriodModel, r.survey_period),
					{
						errorCode: '11AD7D4A',
						errorMessage: DefaultErrorMessage.Saving,
					},
				),
			);
	};

	public getStateInformation = (
		state: string,
	): Observable<SurveyPeriodStateModel> => {
		return this.httpClient
			.get<{
				state_survey_period: unknown;
			}>(`/api/v1/survey_period/state/${state}`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(SurveyPeriodStateModel, r.state_survey_period),
					{
						errorCode: '5D54D449',
					},
				),
			);
	};

	public editStateInformation = (
		state: string,
		args: SurveyPeriodStateArgument,
	): Observable<SurveyPeriodStateModel> => {
		return this.httpClient
			.post<{
				state_survey_period: unknown;
			}>(`/api/v1/survey_period/state/${state}`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(SurveyPeriodStateModel, r.state_survey_period),
					{
						errorCode: '570BE9F3',
						errorMessage: DefaultErrorMessage.Saving,
					},
				),
			);
	};

	public getStateOptions = (): Observable<StateOptionModel[]> => {
		return this.httpClient
			.get<{
				state: unknown[];
			}>(`/api/v1/survey_period/state`)
			.pipe(
				mapResponse((r) => plainToInstance(StateOptionModel, r.state), {
					errorCode: '366D5966',
				}),
			);
	};

	public getGroupOptions = (
		state: string,
	): Observable<SurveyPeriodGroupOptionsModel> => {
		return this.httpClient
			.get<unknown>(`/api/v1/survey_period/groups?state=${state}`)
			.pipe(
				mapResponse((r) => plainToInstance(SurveyPeriodGroupOptionsModel, r), {
					errorCode: '5912DF89',
				}),
			);
	};

	public getMembers = (
		surveyPeriodId: number,
	): Observable<SurveyPeriodMembersModel> => {
		return this.httpClient
			.get<{
				survey_period: unknown;
			}>(`/api/v1/survey_period/${surveyPeriodId}/members`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(SurveyPeriodMembersModel, r.survey_period),
					{
						errorCode: '7A215769',
					},
				),
			);
	};

	public editMembers = (
		surveyPeriodId: number,
		args: SurveyPeriodEditMembersArgument,
		skip_on_failure: boolean = false,
	): Observable<SurveyPeriodMembersModel> => {
		return this.httpClient
			.post<{
				survey_period: unknown;
			}>(
				`/api/v1/survey_period/${surveyPeriodId}/members?skip_on_failure=${skip_on_failure}`,
				args,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(SurveyPeriodMembersModel, r.survey_period),
					{
						errorCode: '4E54B352',
						errorMessage: DefaultErrorMessage.Saving,
					},
				),
			);
	};

	public getPotentialMembers = (
		surveyPeriodId: number,
		skip: number = 0,
		take: number = 5000,
	): Observable<SurveyPeriodPotentialMembersModel> => {
		return this.httpClient
			.get<unknown>(
				`/api/v1/survey_period/${surveyPeriodId}/potential_members?skip=${skip}&take=${take}&max_take=${take}`,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(SurveyPeriodPotentialMembersModel, r),
					{
						errorCode: '14A8FC90',
					},
				),
			);
	};
}
