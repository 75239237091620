export class RegistrationListModel {
	confirmed_present: boolean;
	email: string;
	name: string;
	pause_status: unknown;
	phone: string;
	program_id: string;
	program_title: string;
	uid: string;
}
