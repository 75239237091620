import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { buildQueryString } from 'src/lib/utilities/api/http';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { StudentRelationshipHistoryModel } from './student-relationship-history.model';
import { StudentRelationshipsOptionsTypeModel } from './student-relationships-options-type.model';
import { StudentRelationshipsArgument } from './student-relationships.arguments';
import { StudentRelationshipsModel } from './student-relationships.model';

@Injectable({
	providedIn: 'root',
})
export class StudentRelationshipsService {
	constructor(private httpClient: HttpClient) {}

	public getRelationships = (
		uid: number,
	): Observable<StudentRelationshipsModel[]> => {
		return this.httpClient
			.get<StudentRelationshipsModel[]>(`/api/v1/students/${uid}/relationships`)
			.pipe(
				mapResponse((r) => plainToInstance(StudentRelationshipsModel, r), {
					errorCode: 'A67C50DF',
				}),
			);
	};

	public getRelationshipHistory = (
		uid: number,
	): Observable<StudentRelationshipHistoryModel[]> => {
		return this.httpClient
			.get<
				StudentRelationshipHistoryModel[]
			>(`/api/v1/students/${uid}/relationships/history`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentRelationshipHistoryModel, r),
					{
						errorCode: '4BF278D9',
					},
				),
			);
	};

	public getRelationshipsOptions = (
		uid: number,
		excludeOptions: boolean = false,
	): Observable<StudentRelationshipsOptionsTypeModel[]> => {
		const qs = buildQueryString({
			exclude_options: excludeOptions ? 1 : 0,
		});
		return this.httpClient
			.get<{
				relationships: unknown[];
			}>(`/api/v1/students/${uid}/relationships/options${qs}`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							StudentRelationshipsOptionsTypeModel,
							r.relationships,
						),
					{ errorCode: '49057E78' },
				),
			);
	};

	public setRelationships = (
		uid: number,
		args: StudentRelationshipsArgument[],
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(`/api/v1/students/${uid}/relationships/set`, {
				types: args,
			})
			.pipe(
				mapResponse((r) => r, {
					errorCode: '4668C248',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public getRelationshipTypeOptions = (): Observable<
		StudentRelationshipsOptionsTypeModel[]
	> => {
		return this.httpClient
			.get<{
				relationships: unknown[];
			}>(`/api/v1/relationships/options`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							StudentRelationshipsOptionsTypeModel,
							r.relationships,
						),
					{
						errorCode: 'B34CD2D0',
					},
				),
			);
	};
}
