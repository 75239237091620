import { Type } from 'class-transformer';

export class PermissionEditFieldPermission {
	request: boolean;
	approve: boolean;
}

export function PermissionEditFieldPermission_ClassTransform_Type() {
	return PermissionEditFieldPermission;
}

export class PermissionEditFieldModel {
	type: string;
	name: string;
	validators: unknown;
	@Type(PermissionEditFieldPermission_ClassTransform_Type)
	permissions: PermissionEditFieldPermission;
	key: string;
}

export function PermissionEditFieldModel_ClassTransform_Type() {
	return PermissionEditFieldModel;
}

export class PermissionEditFieldsModel {
	@Type(PermissionEditFieldModel_ClassTransform_Type)
	add: PermissionEditFieldModel;
	@Type(PermissionEditFieldModel_ClassTransform_Type)
	remove: PermissionEditFieldModel;
	@Type(PermissionEditFieldModel_ClassTransform_Type)
	reset: PermissionEditFieldModel;
}

export function PermissionEditFieldsModel_ClassTransform_Type() {
	return PermissionEditFieldsModel;
}

export class PermissionModel {
	key: string;
	title: string;
	description: string;
	provider: string;
	provider_title: string;
}

export function PermissionModel_ClassTransform_Type() {
	return PermissionModel;
}

export class PermissionEditRolesItypesModel {
	key: string;
	title: string;
	has_permission_to_assign?: boolean;
	is_assignable?: boolean;
}

export function PermissionEditRolesItypesModel_ClassTransform_Type() {
	return PermissionEditRolesItypesModel;
}

export class PermissionEditOptionsModel {
	@Type(PermissionEditFieldsModel_ClassTransform_Type)
	options: PermissionEditFieldsModel;
	@Type(PermissionModel_ClassTransform_Type)
	permissions: PermissionModel[];
	@Type(PermissionEditRolesItypesModel_ClassTransform_Type)
	roles: PermissionEditRolesItypesModel[];
	@Type(PermissionEditRolesItypesModel_ClassTransform_Type)
	institute_itypes: PermissionEditRolesItypesModel[];
}
