import { Transform, Type } from 'class-transformer';
import {
	UserAddressModel,
	UserAddressModel_ClassTransform_Type,
} from 'src/lib/services/utility/utility-models/address.model';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class StudentSchoolSearchModel {
	accredited: boolean;
	@Type(UserAddressModel_ClassTransform_Type)
	address: UserAddressModel;
	created_by: string;
	@Transform(dateTransformer)
	created_on_timestamp: Date;
	fax_numbers: string[];
	hidden: boolean;
	id: number;
	nces_id: string;
	phone_number: string | number;
	title: string;
	type: number;
	usage_count: number;
	website: string;
	country_code: string;
}

export function StudentSchoolSearchModel_ClassTransform_Type() {
	return StudentSchoolSearchModel;
}

export class StudentSchoolSearchListModel {
	count: number;
	@Type(StudentSchoolSearchModel_ClassTransform_Type)
	results: StudentSchoolSearchModel[];
}
