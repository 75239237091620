import spacetime from 'spacetime';
// eslint-disable-next-line no-restricted-imports
import { convertDateToPhpTimestamp } from '../../../convert';
// eslint-disable-next-line no-restricted-imports
import { hasValue, isNumber, isString, isValidDate } from '../../../compare';
import { SearchOperators } from './search-operators.enum';

export interface PagingSearchFieldArgument<T = string | number> {
	value: T;
	operator: SearchOperators;
}

export interface PagingSearchFieldArrayArgument {
	value: (string | number)[];
	operator: SearchOperators;
}

export function buildSearchFieldLikeObject(
	value: string,
	includeWrap: boolean = true,
): PagingSearchFieldArgument<string> {
	if (!isString(value) || value === '') return null;

	return {
		value: includeWrap ? `%${value}%` : value,
		operator: SearchOperators.Like,
	};
}

export function buildSearchFieldInNotInObject<
	T extends string | number,
>(value: { or: boolean; values: T[] }): PagingSearchFieldArrayArgument {
	const returnValues: T[] = [];

	value?.values.forEach((s) => {
		if (s != null && s !== '') {
			returnValues.push(s);
		}
	});

	return returnValues.length > 0
		? {
				value: returnValues,
				operator: value.or ? SearchOperators.In : SearchOperators.NotIn,
			}
		: null;
}

export function buildSearchFieldDate(
	value: {
		operator: SearchOperators;
		operand: Date;
	},
	format: 'none' | 'yyyy-mm-dd' | 'yyyy-mm' | 'yyyy' | 'iso-utc',
	timezone?: string,
): PagingSearchFieldArgument {
	if (value == null) return null;

	if (
		value.operator === SearchOperators.IsNull ||
		value.operator === SearchOperators.IsNotNull
	) {
		return {
			value: null,
			operator: value.operator,
		};
	}

	if (!isValidDate(value.operand)) return null;

	const st = spacetime(value.operand, timezone);
	let val = null;
	if (format !== 'none') {
		val = st.format(format);
	} else {
		val = convertDateToPhpTimestamp(st.toNativeDate());
	}

	return {
		value: val,
		operator: value.operator,
	};
}

export function buildSearchFieldNumber(value: {
	operator: SearchOperators;
	operand: number;
}): PagingSearchFieldArgument {
	if (value == null || !isNumber(value.operand)) return null;

	return {
		value: value.operand,
		operator: value.operator,
	};
}

export function buildSearchField<T>(
	value: T,
	operator: SearchOperators,
	converter?: (v: T) => string | number,
): PagingSearchFieldArgument {
	if (!hasValue(value)) return null;
	converter =
		converter ??
		function (v) {
			return v.toString();
		};

	return {
		value: converter(value),
		operator,
	};
}
