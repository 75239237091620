import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import {
	JiraLaptopIssuesModel,
	JiraLaptopTicketModel,
} from './jira-laptop-issues.model';
import { JiraTicketModel } from './jira-ticket.model';
import { StudentLaptopRequestArgument } from './student-laptop-request.argument';
import { StudentLaptopRequestModel } from './student-laptop-request.model';
import { StudentAssetModel } from './student-physical-assets.model';

@Injectable({
	providedIn: 'root',
})
export class StudentLaptopService {
	constructor(private httpClient: HttpClient) {}

	public getStudentPhysicalAssets = (
		studentId: number,
	): Observable<StudentAssetModel[]> => {
		return this.httpClient
			.get<unknown[]>(`/api/v1/students/${studentId}/physical_assets/assigned`)
			.pipe(
				mapResponse((r) => plainToInstance(StudentAssetModel, r), {
					errorCode: '435B6D22',
				}),
			);
	};

	public getLaptopRequest = (
		studentId: number,
	): Observable<StudentLaptopRequestModel> => {
		return this.httpClient
			.get<{
				laptop_request: unknown;
			}>(`/api/v1/students/${studentId}/laptop/request`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentLaptopRequestModel, r.laptop_request),
					{
						errorCode: '807446D6',
					},
				),
			);
	};

	public waiveLaptopRequest = (
		studentId: number,
		args: Record<string, unknown>,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(`/api/v1/students/${studentId}/laptop/waive`, args)
			.pipe(mapResponse((r) => r, { errorCode: 'D2C1D02A' }));
	};

	public createLaptopJiraTicket = (
		studentId: number,
		args: StudentLaptopRequestArgument,
	) => {
		return this.httpClient
			.post<unknown>(`/api/v1/students/${studentId}/laptop_issue`, args)
			.pipe(mapResponse((r) => r, { errorCode: 'b4041f1c' }));
	};

	public getJiraIssue = (
		studentId: number,
		issueId: string,
	): Observable<JiraTicketModel> => {
		return this.httpClient
			.get<JiraTicketModel>(
				`/api/v1/students/${studentId}/laptop_issue/${issueId}`,
			)
			.pipe(
				mapResponse((r) => plainToInstance(JiraTicketModel, r), {
					errorCode: 'e3529903',
				}),
			);
	};

	public getMultipleJiraIssues = (
		studentId: number,
	): Observable<JiraLaptopTicketModel[]> => {
		return this.httpClient
			.get<JiraLaptopIssuesModel>(`/api/v1/students/${studentId}/laptop_issue`)
			.pipe(
				mapResponse((r) => plainToInstance(JiraLaptopTicketModel, r.issues), {
					errorCode: '3d68666f',
				}),
			);
	};

	public createJiraComment = (
		studentId: number,
		issueId: string,
		comment: string,
	) => {
		return this.httpClient
			.post<unknown>(
				`/api/v1/students/${studentId}/laptop_issue/${issueId}/comment`,
				{
					comment: comment,
				},
			)
			.pipe(mapResponse((r) => r, { errorCode: '0d09271a' }));
	};
}
