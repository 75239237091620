import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { StudentStateTestAttendanceStatus } from 'src/lib/constants/state-tests';
import { buildQueryString } from 'src/lib/utilities/api/http';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import {
	StudentStateTestArgument,
	StudentStateTestsOverviewArgument,
} from './student-state-test.arguments';
import {
	StudentStateTestEventModel,
	StudentStateTestModel,
} from './student-state-test.model';

@Injectable({
	providedIn: 'root',
})
export class StudentsTestsService {
	constructor(private httpClient: HttpClient) {}

	public getAvailableTestInstances = (
		studentUid: number,
		showPast?: boolean,
	): Observable<StudentStateTestEventModel[]> => {
		const query = buildQueryString({
			show_past: showPast,
		});
		return this.httpClient
			.get<{
				results: unknown[];
			}>(`api/v1/students/${studentUid}/tests/available_instances${query}`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentStateTestEventModel, r.results),
					{ errorCode: 'B124B482' },
				),
			);
	};

	// POST
	public getTestResults = (
		studentUid: number,
		args: PagingArgument,
	): Observable<StudentStateTestModel[]> => {
		return this.httpClient
			.post<{
				results: unknown[];
			}>(`/api/v1/students/${studentUid}/tests/results`, args)
			.pipe(
				mapResponse((r) => plainToInstance(StudentStateTestModel, r.results), {
					errorCode: '11E5689D',
				}),
			);
	};

	public upsertTestResult = (
		studentUid: number,
		args: StudentStateTestArgument,
	): Observable<StudentStateTestModel> => {
		return this.httpClient
			.post<{
				test_result: unknown;
			}>(`api/v1/students/${studentUid}/tests/edit_result`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentStateTestModel, r.test_result),
					{
						errorCode: '70982A65',
						errorMessage: DefaultErrorMessage.Saving,
					},
				),
			);
	};

	public editTestsResultOverview = (
		studentUid: number,
		testId: number,
		args: StudentStateTestsOverviewArgument,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(
				`/api/v1/students/${studentUid}/tests/results/${testId}/overview`,
				args,
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '67A71A67',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public registerForTest = (
		studentUid: number,
		testId: number,
		status: StudentStateTestAttendanceStatus,
	): Observable<{
		success: boolean;
	}> => {
		return this.httpClient
			.post<{
				success: boolean;
			}>(`api/v1/students/${studentUid}/tests/${testId}/attendance`, {
				status: status,
			})
			.pipe(
				mapResponse((r) => r, {
					errorCode: '90DAAFEE',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public acknowledgeTestAttendance = (
		studentId: number,
		instanceId: number,
		status: number,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(
				`/api/v1/students/${studentId}/tests/${instanceId}/attendance/acknowledge`,
				{
					status: status,
				},
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '91785775',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	/******* Result History *********/
	public getTestsResultHistory = (
		uid: number,
		testId: number,
	): Observable<StudentStateTestModel[]> => {
		return this.httpClient
			.get<{
				results: unknown[];
			}>(`/api/v1/students/${uid}/tests/results/${testId}/history`)
			.pipe(
				mapResponse((r) => plainToInstance(StudentStateTestModel, r.results), {
					errorCode: 'CF995052',
				}),
			);
	};

	public editTestsResultHistory = (
		uid: number,
		testId: number,
		resultId: number,
	): Observable<StudentStateTestModel> => {
		return this.httpClient
			.post<{
				test_result: unknown;
			}>(
				`/api/v1/students/${uid}/tests/results/${testId}/history/${resultId}`,
				{ primary: true },
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentStateTestModel, r.test_result),
					{
						errorCode: 'CF995052',
						errorMessage: DefaultErrorMessage.Saving,
					},
				),
			);
	};

	public deleteTestsResultHistory = (
		uid: number,
		testId: number,
		resultId: number,
	): Observable<StudentStateTestModel> => {
		return this.httpClient
			.delete<{
				test_result: unknown;
			}>(`/api/v1/students/${uid}/tests/results/${testId}/history/${resultId}`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentStateTestModel, r.test_result),
					{
						errorCode: 'CF995052',
						errorMessage: DefaultErrorMessage.Saving,
					},
				),
			);
	};
}
