import { Transform } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class MilestoneOptionModel {
	milestone_id: number;
	milestone_name: string;
	milestone_type: string;
}

export class MilestoneStudentModel {
	milestone_id: number;
	milestone_name: string;
	milestone_type: string;
	assignment_id: number;
	@Transform(dateTransformer)
	assigned_date: Date;
	@Transform(dateTransformer)
	completed_date: Date;
	assigned_by_uid: number;
	assigned_by_name: string;
	notes: string;
	additional_date: unknown;
	@Transform(dateTransformer)
	created: Date;
	@Transform(dateTransformer)
	updated: Date;
	proof: string;
}

export class MilestoneUpdateModelArgs {
	milestone_id: number;
	completed_date: number;
	notes?: string;
	proof?: File;
	assignment_id?: number;
}
