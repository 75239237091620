import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { buildFormData } from 'src/lib/utilities/api/http';
import { MessageArgument } from './message.argument';
import { MessageModel } from './message.model';
import { MessagesArgument } from './messages.argument';

@Injectable({
	providedIn: 'root',
})
export class MessagesService {
	constructor(private httpClient: HttpClient) {}

	public getMessages = (
		directoryId: string,
		channelId: number | string,
		args?: MessagesArgument,
	): Observable<MessageModel[]> => {
		return this.httpClient
			.post<
				MessageModel[]
			>(`/api/v1/gradchat/${directoryId}/channel/${channelId}/messages`, args)
			.pipe(map((r) => plainToInstance(MessageModel, r)));
	};

	public postMessage = (
		directoryId: string,
		channelId: number | string,
		args: MessageArgument,
	): Observable<unknown> => {
		const fileArgs = args.attachments || [];
		const formArgs = args;
		delete args.attachments;

		if (!formArgs.sms) {
			delete formArgs.sms;
		}

		for (let i = 0; i < fileArgs.length; i++) {
			formArgs[`attachment${i}`] = fileArgs[i];
		}

		return this.httpClient
			.post<{ success: boolean }>(
				`/api/v1/gradchat/${directoryId}/channel/${channelId}/message`,
				buildFormData(formArgs),
				{
					headers: new HttpHeaders({ enctype: 'multipart/form-data' }),
				},
			)
			.pipe(
				switchMap((x) => {
					if (x.success) return of(x);
					else throw x;
				}),
			);
	};

	public markRead = (
		directoryId: string,
		channelId: number | string,
		messageId: number,
	): Observable<unknown> => {
		return this.httpClient.post<unknown>(
			`/api/v1/gradchat/${directoryId}/channel/${channelId}/read`,
			{
				message_id: messageId,
			},
		);
	};
}
