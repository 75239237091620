import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { CalendarEventModel } from './calendar-event.model';
import { CalendarEventArgument } from './calendar-events.argument';

@Injectable({
	providedIn: 'root',
})
export class CalendarEventsService {
	constructor(private httpClient: HttpClient) {}

	public createCalendarEvent = (
		args: CalendarEventArgument,
	): Observable<CalendarEventModel> => {
		return this.httpClient
			.post<CalendarEventModel>(`/api/v1/organizations/calendar`, {
				calendar_event: args,
			})
			.pipe(
				mapResponse((r) => r, {
					errorCode: '7ADD7FB5',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public editCalendarEvent = (
		id: number,
		args: Partial<CalendarEventArgument>,
	): Observable<CalendarEventModel> => {
		return this.httpClient
			.post<CalendarEventModel>(`/api/v1/organizations/calendar/${id}`, {
				calendar_event: args,
			})
			.pipe(
				mapResponse((r) => r, {
					errorCode: '7A162A27',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public deleteCalendarEvent = (id: number): Observable<unknown> => {
		return this.httpClient
			.delete<unknown>(`/api/v1/organizations/calendar/${id}`)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'C31CB35C',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public getCalendarEvents = (
		instituteId: number,
		startDate: string,
		endDate: string,
	): Observable<CalendarEventModel[]> => {
		return this.httpClient
			.get<{
				calendar_events: unknown[];
			}>(
				`/api/v1/organizations/calendar?start_date=${startDate}&end_date=${endDate}&institute=${instituteId}`,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(CalendarEventModel, r.calendar_events),
					{
						errorCode: '322e772c',
					},
				),
			);
	};
}
