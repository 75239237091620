import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

// Student Approval Status

export class SalesforceStudentApprovalOptionsModel {
	is_eligible: boolean;
	ineligible_reasons: string[];
	into_institute_id: number;
	requires_approval: boolean;
	can_check_approval: boolean;
}

export function SalesforceStudentApprovalOptionsModel_ClassTransform_Type() {
	return SalesforceStudentApprovalOptionsModel;
}

export class SalesforceStudentApprovalRequestStatusModel {
	student_reference: unknown;
	approval_request: unknown;
	@Type(SalesforceStudentApprovalOptionsModel_ClassTransform_Type)
	approval_options: SalesforceStudentApprovalOptionsModel;
}

export function SalesforceStudentApprovalRequestStatusModel_ClassTransform_Type() {
	return SalesforceStudentApprovalRequestStatusModel;
}

// Approval Status
export class SalesforceApprovalRequestApproveItemApprovedBy {
	id: number;
	name: string;
}

export function SalesforceApprovalRequestApproveItemApprovedBy_ClassTransform_Type() {
	return SalesforceApprovalRequestApproveItemApprovedBy;
}

export class SalesforceApprovalRequestApproveItem {
	is_approved: boolean;
	status: number;
	type: string;
	@Type(SalesforceApprovalRequestApproveItemApprovedBy_ClassTransform_Type)
	approved_by: SalesforceApprovalRequestApproveItemApprovedBy;
	@Transform(dateTransformer)
	approved_on_timestamp: Date;
	available_approvers: unknown;
}

export function SalesforceApprovalRequestApproveItem_ClassTransform_Type() {
	return SalesforceApprovalRequestApproveItem;
}

export class SalesforceApprovalRequestInstituteItem {
	title: string;
	institute_id: number;
}

export function SalesforceApprovalRequestInstituteItem_ClassTransform_Type() {
	return SalesforceApprovalRequestInstituteItem;
}

export class SalesforceApprovalRequestStatusModel {
	id: number;
	status: number;
	approvers: SalesforceApprovalRequestApproveItem[];
	institute: SalesforceApprovalRequestInstituteItem;
	reason: string;
	created_timestamp: Date;
	completed_timestamp: Date;
}

export function SalesforceApprovalRequestStatusModel_ClassTransform_Type() {
	return SalesforceApprovalRequestStatusModel;
}

export class SalesforceApprovalRequestStatusOptionsModel {
	is_eligible: boolean;
	ineligible_reasons: [];
	institutes: [];
	suggested_institute_id: number;
}

export function SalesforceApprovalRequestStatusOptionsModel_ClassTransform_Type() {
	return SalesforceApprovalRequestStatusOptionsModel;
}

export class SalesforceApprovalRequestStatusResponseModel {
	success: boolean;
	approval_request: SalesforceApprovalRequestStatusModel;
	approval_options: SalesforceApprovalRequestStatusOptionsModel;
}

export function SalesforceApprovalRequestStatusResponseModel_ClassTransform_Type() {
	return SalesforceApprovalRequestStatusResponseModel;
}
