import { Injectable, OnDestroy } from '@angular/core';
import { AsyncSubject, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { UserActiveService } from '../../startup/user-active/user-active.service';
import { CommonWampEventFactoryService } from '../../wamp/wamp-event-factory/common/common-wamp-event-factory.service';
import { WampService } from '../../wamp/wamp.service';
import { OnlineStatus } from './online-status.enum';

@Injectable({
	providedIn: 'root',
})
export class OnlineStatusStoreService implements OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();
	private _userStateSubjects = new Map<number, BehaviorSubject<OnlineStatus>>();

	constructor(
		private wampService: WampService,
		private cwefs: CommonWampEventFactoryService,
		private userActiveService: UserActiveService,
	) {
		this.userActiveService.isAway$
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((away) => {
				const newStatus = away ? OnlineStatus.away : OnlineStatus.online;

				this.wampService
					.call$('ga.set_user_session_status', newStatus)
					.subscribe();
			});

		this.cwefs
			.userStatusChanged$()
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((e) => {
				if (this._userStateSubjects.has(e.linkId)) {
					this._userStateSubjects.get(e.linkId).next(e.status);
				}
			});
	}

	public userOnlineStatus$ = (linkId: number) => {
		return this.getSubject(linkId)
			.asObservable()
			.pipe(
				map((v) => (v === undefined ? OnlineStatus.offline : v)),
				distinctUntilChanged(),
			);
	};

	public userOnlineStatusVerified$ = (linkId: number) => {
		return this.getSubject(linkId)
			.asObservable()
			.pipe(
				filter((x) => x !== undefined),
				distinctUntilChanged(),
			);
	};

	private getSubject = (linkId: number) => {
		if (!this._userStateSubjects.has(linkId)) {
			const sub = new BehaviorSubject<OnlineStatus>(undefined);

			this.wampService
				.call$('ga.get_link_status', [linkId])
				.subscribe((result: unknown[]) => {
					if (result != null && result.length > 0) {
						for (const item of result) {
							if (item[linkId] != null) {
								sub.next(item[linkId]);
								break;
							}
						}
					}
				});

			this._userStateSubjects.set(linkId, sub);
		}

		return this._userStateSubjects.get(linkId);
	};

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
